import ReactPageBaseComponent from "../../components/BaseComponents/ReactPageBaseComponent";
import { Colors } from "../../theme/v2/variants";
import LoadingComponent from "../../components/StateComponents/LoadingComponent";
import { BaseState } from "../Actions/ViewActions/utils/BaseState";
import React, { useEffect, useRef, useState } from "react";
import { useDashboard } from "../../contexts/dashboard";
import Title from "../../components/Typography/Tittle";
import AddServicePopUp, {
  AddServicePopUpRef,
  NewServiceType,
} from "./components/AddServicePopUp";
import VerticalService from "../../services/VeritcalService";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import VerticalServiceGroup from "../../services/interfaces/VerticalServiceGroup";
import ErrorComponent from "../../components/StateComponents/ErrorComponent";
import EmptyClientAndConstructionState from "../../components/StateComponents/EmptyClientAndConstruction";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../components/v2/Button/ButtonComponent";
import Snackbar from "@mui/material/Snackbar";
import VerticalServiceModel from "../../services/interfaces/VerticalServiceModel";
import UserService, { UserResponsible } from "../../services/UserService";
import { Button, Menu, MenuItem, SwipeableDrawer, Switch } from "@mui/material";
import IconButtonComponent from "../../components/v2/Button/IconButtonComponent";
import DayServicesListPopUp from "./components/DayServicesListPopUp";
import ConfirmDeleteItemPopUp from "../../components/Dialogs/ConfirmDeleteItemPopUp";

import Tabs from "@mui/material/Tabs";
import { ReactComponent as Article } from "../../assets/article.svg";
import { ReactComponent as FilterOutlined } from "../../assets/filter-outline.svg";
import { ReactComponent as ShareOutlined } from "../../assets/share-outline.svg";
import { ReactComponent as Filter } from "../../assets/filter.svg";
import { ReactComponent as ReportIcon } from "../../assets/icon-report.svg";
import { ReactComponent as IconShare } from "../../assets/icon-share.svg";
import { ReactComponent as AlertTriangle } from "../../assets/alert-triangle.svg";

import FilterSideBar from "./components/FilterSideBar";
import AddImagesPopUp from "./components/AddImagesPopUp";
import UpdateServiceProgressPopUp from "./components/UpdateServiceProgressPopUp";
import VerticalServiceImage from "../../services/interfaces/VerticalServiceImages";
import AdvancementIndexComponent, {
  AdvancementIndexType,
} from "./components/AdvancementIndexComponent";
import { UpdateServiceProgressModel } from "./models/UpdateServiceProgressModel";
import {
  ProgressUpdateType,
  VerticalServiceProgressModel,
} from "../../services/interfaces/VerticalServiceProgressModel";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";
import DropDownComponent from "../../components/InputValues/DropDownComponent";
import { useNavbarVisibility } from "../../contexts/navbar";
import {
  Client,
  Construction,
  PURCHASING_CONTRACTING_PLAN_FORM_PATH,
} from "../../config/app-info";
import { ReactComponent as LastUpdateIcon } from "../../assets/last-update-icon.svg";
import TableItem from "./components/TableItem";
import ImportExcelFilePopUp from "./components/ImportExcelFilePopUp";
import { ReactComponent as ThreeDots } from "../../assets/three_dots.svg";
import OrderOfStepsEditServicePopUp from "./pages/OrderOfSteps/components/OrderOfStepsEditServicePopUp/OrderOfStepsEditServicePopUp";
import { ItemType } from "../../components/InputValues/VerticalGroupDropDownComponent";
import PurchasingModel from "../PurchasingContractingPlanPage/models/PurchasingModel";
import ContractingModel from "../PurchasingContractingPlanPage/models/ContractingMode";
import PurchasingContractingPlanService from "../../services/purchasingContractingPlan/PurchasingContractingPlanService";
import { PurchasingPlanEntity } from "../../services/purchasingContractingPlan/entity/PurchasingPlanEntity";
import { ContractingPlanEntity } from "../../services/purchasingContractingPlan/entity/ContractingPlanEntity";
import * as Styles from "./VerticalServicePage.styles";
import moment from "moment";
import VerticalServicePageToPdf from "./components/VerticalServicePageToPdf";
import { useAuth } from "../../contexts/auth";
import { api } from "../../services/api";

import CheckIcon from "@mui/icons-material/Check";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { ReactComponent as AddIcon } from "../../assets/add-icon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AdvancementIndexVerticalComponent from "./components/AdvancementIndexVerticalComponent";
import Footer from "../../components/v2/Footer";

import ConstructionService from "../../services/ConstructionService";

interface Props {}

const TabsEnum = {
  semanal: "semanal",
  mensal: "mensal",
  trimestral: "trimestral",
  semestral: "semestral",
  anual: "anual",
};

enum ThreeDotsIconEnum {
  IMPORT_XLSX = "import-xlsx",
  PDF = "pdf",
}

const VerticalServicePage: React.FC<Props> = ({}) => {
  const navigate = useNavigate();
  const verticalService = new VerticalService();
  const purchasingContractinService = new PurchasingContractingPlanService();
  const constructionService = new ConstructionService();
  const { user } = useAuth();

  const userService = new UserService();
  const {
    constructionId,
    constructions,
    clientId,
    clients,
    setConstructionId,
  } = useDashboard();
  const [state, setState] = useState<BaseState>(BaseState.success);
  const [tableContent, setTableContent] = useState<VerticalServiceGroup[]>([]);
  const [servicesTypes, setServicesTypes] = useState<VerticalServiceGroup[]>(
    []
  );
  const [
    servicesTypesByRegisteredServices,
    setServicesTypesByRegisteredServices,
  ] = useState<VerticalServiceGroup[]>([]);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [hoveredItem, setHoveredItem] = useState<any>(null);
  const [clickedItem, setClickedItem] = useState<any>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [responsibleUsers, setResponsibleUsers] = useState<UserResponsible[]>(
    []
  );
  const [timePeriodListState, setTimePeriodListState] = useState<any[]>([]);
  const [addServiceDialogLoading, setAddServiceDialogLoading] =
    useState<boolean>(false);
  const [dates, setDates] = useState<{ startDate: Date; endDate: Date }>();
  const [constructionDates, setConstructionDates] = useState<{
    beginningDate: Date;
    endDate: Date;
    constructionDays: number;
    constructionWeekDays: number;
  }>();
  const [isDayServicesDialogOpen, setIsDayServicesDialogOpen] =
    useState<boolean>(false);
  const [dayServicesItems, setDayServicesItems] = useState<{
    serviceGroupColor: string;
    serviceType: string;
    data: any[];
  }>({
    serviceGroupColor: "#000",
    data: [],
    serviceType: "",
  });
  const [selectedDayServicesItems, setSelectedDayServicesItems] =
    useState<string>("");
  const [openDeleteActionDialog, setOpenDeleteActionDialog] =
    useState<boolean>(false);
  const [isDeleteActionLoadingDialog, seDeleteActiontLoadingDialog] =
    useState<boolean>(false);
  const [deleteActionItem, setDeleteActionItem] = useState<any>(null);
  const [editActionItem, setEditActionItem] = useState<VerticalServiceModel>();
  const addServiceDialogRef = React.useRef<AddServicePopUpRef>();
  const [selectedTab, setSelectedTab] = React.useState("Semanal");
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = React.useState(false);
  const [locations, setLocations] = React.useState<string[]>([]);
  const [services, setServices] = React.useState<string[]>([]);

  //UploadImagePopUp
  const [isUploadImageDialogOpen, setIsUploadImageDialogOpen] =
    useState<boolean>(false);
  const [selectedVerticalServiceImages, setSelectedVerticalServiceImages] =
    useState<VerticalServiceImage[]>([]);
  const [isUploadImageDialogLoading, setIsUploadImageDialogLoading] =
    useState<boolean>(false);

  //UpdateServiceProgressPopUp
  const [
    isUpdateServiceProgressDialogOpen,
    setIsUpdateServiceProgressDialogOpen,
  ] = useState<boolean>(false);
  const [
    isUpdateServiceProgressDialogOpenLoading,
    setIsUpdateServiceProgressDialogOpenLoading,
  ] = useState<boolean>(false);

  const [selectedVerticalService, setSelectedVerticalService] =
    useState<VerticalServiceModel>();
  const [progressByConstruction, setProgressByConstruction] =
    useState<VerticalServiceProgressModel>();
  const [progressByService, setProgressByService] =
    useState<VerticalServiceProgressModel>();
  const pdfRef = useRef(null);
  const [isMobile, setIsMobile] = useState<boolean>();
  const [isPrintState, setPrintState] = useState<boolean>(false);
  const { setNavbarVisibility } = useNavbarVisibility();
  const [selectedClient, setSelectedClient] = useState<Client>();
  const [selectedConstruction, setSelectedConstruction] =
    useState<Construction>();
  const [isPrintStateFooterVisible, setPrintStateFooter] =
    useState<boolean>(true);
  const [lastUpdate, setLastUpdate] = useState<string>("");
  const [lastUpdatedBy, setLastUpdatedBy] = useState<string>("");
  const [showItemsSameDataByGroup, setShowItemsSameDataByGroup] =
    useState<boolean>(true);
  const [showImportXLSXDialog, setShowImportXLSXDikalog] =
    useState<boolean>(false);
  const [isLoadingShowImportXLSXDialog, setIsLoadingShowImportXLSXDialog] =
    useState<boolean>(false);

  // Three Dots Icon Button menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const exportImportMenuIsOpen = Boolean(anchorEl);

  const [deletedImage, setDeletedImage] = useState<VerticalServiceImage>();
  const [selectedFilter, setSelectedFilter] = useState<any>();

  // OrderOfSteps PopUp
  const [isOrderOfStepsPopUpOpen, setIsOrderOfStepsPopUpOpen] = useState(false);
  const [orderOfStepsPopUpLoading, setOrderOfStepsPopUpLoading] =
    useState(false);
  const [orderOfStepsData, setOrderOfStepsData] = useState<{
    changedServiceId: number;
    newStartDate: Date | undefined;
    oldStartDate: Date | undefined;
    newEndDate: Date | undefined;
    oldEndDate: Date | undefined;
  }>();

  const [constructionImage, setConstructionImage] = useState<string>("");
  const [selectedConstructionName, setSelectedConstructionName] = useState("");
  const [selectedClientName, setSelectedClientName] = useState("");

  const handleResize = () => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const loadPageData = async (constructionId: number) => {
    const constructionInfo = await constructionService.findById(constructionId);

    setSelectedClientName(constructionInfo?.clientId?.name);
  };

  useEffect(() => {
    if (isMobile === undefined) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    findServiceTypes(constructionId);
    findTableContent(dates?.startDate, dates?.endDate, selectedTab);
    findResponsibleUsers(constructionId);
    findLocations(constructionId);
    getServicesProgressByConstruction(constructionId);
    setSelectedConstruction(constructions.find((c) => c.id === constructionId));
    setSelectedClient(clients.find((c) => c.id === clientId));
    getConstructionImage(constructionId);
    loadPageData(constructionId);
  }, [constructionId]);

  const findTableContent = async (
    startDate?: Date,
    endDate?: Date,
    byTime: string = selectedTab,
    responsibleUsers?: string,
    locations?: string,
    services?: string,
    filtered: boolean = false,
    showItemsSamePeriod: boolean = showItemsSameDataByGroup
  ) => {
    if (
      constructionId === null ||
      constructionId === undefined ||
      constructionId === 0
    ) {
      return;
    }
    setState(BaseState.loading);
    try {
      const response = await verticalService.getTableContent(
        constructionId.toString(),
        startDate,
        endDate,
        byTime.toLowerCase(),
        responsibleUsers,
        locations,
        services,
        1,
        filtered,
        showItemsSamePeriod
      );

      const firstDate = new Date(response.firstDay);
      const lastDate = new Date(response.lastDay);
      const constructionBeginningDate = new Date(
        response.constructionBeginningDate
      );
      const constructionEndDate = new Date(response.constructionEndDate);

      if (!dates?.startDate && !dates?.endDate) {
        setDates({
          startDate: firstDate,
          endDate: lastDate,
        });
      }

      // Add 1 to count the last day
      const constructionDays =
        moment(constructionEndDate).diff(
          moment(constructionBeginningDate),
          "days"
        ) + 1;

      let daysArray: number[] = [];
      const startDateMoment = moment(constructionBeginningDate).clone();
      const endDateMoment = moment(constructionEndDate).clone();
      for (
        let current = startDateMoment;
        current <= endDateMoment;
        current.add(1, "day")
      ) {
        daysArray.push(current.dayOfYear());
      }

      const weekDaysCount = daysArray.reduce((count, date) => {
        // Check if the day is Monday to Friday
        const weekday = moment().dayOfYear(date).day();
        if (weekday >= 1 && weekday <= 5) return count + 1;
        return count;
      }, 0);

      setConstructionDates({
        beginningDate: constructionBeginningDate,
        endDate: constructionEndDate,
        constructionDays: constructionDays,
        constructionWeekDays: weekDaysCount,
      });

      setState(BaseState.success);
      setTableContent(response.content);
      setTimePeriodListState(response.timeList);
      setLastUpdatedBy(response.lastUpdatedBy);
      console.log("LAST UPADET BY: ", response.lastUpdatedBy);
      setLastUpdate(
        new Date(response.lastUpdate).toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
      );

      if (response.byTime !== undefined) {
        setSelectedTab(response.byTime);
      }
    } catch (error) {
      setState(BaseState.error);
    }
  };

  const findServiceTypes = async (constructionId?: number) => {
    try {
      const response = await verticalService.getServiceTypes(constructionId);
      const responseFilterByRegisteredServices =
        await verticalService.getServiceTypes(constructionId, false, true);
      setServicesTypes(response);
      setServicesTypesByRegisteredServices(responseFilterByRegisteredServices);
      const serviceTypes = response
        .map((service) => service.services.map((service) => service.name))
        .flat();
      serviceTypes.sort();
      setServices(serviceTypes);
    } catch (error) {}
  };

  const findLocations = async (constructionId: number) => {
    try {
      const response = await verticalService.getLocationsByConstructionId(
        constructionId
      );
      setLocations(response);
    } catch (error) {
      console.error(error);
    }
  };

  const findResponsibleUsers = async (constructionId: number) => {
    userService
      .fetchResponsibleUsers(constructionId.toString())
      .then((response) => {
        setResponsibleUsers(response);
      });
  };

  const getServicesProgressByConstruction = async (
    constructionid: number = constructionId
  ) => {
    if (isConstructionNotSelected()) {
      return;
    }

    verticalService
      .getServiceProgressByConstructionId(constructionid)
      .then((response) => {
        setProgressByConstruction(response);
      })
      .catch(() => {});
  };

  const getServiceProgressByServiceId = async (
    constructionid: number = constructionId,
    serviceId: number
  ) => {
    if (isConstructionNotSelected()) {
      return;
    }

    verticalService
      .getServiceProgressByServiceId(constructionid, serviceId)
      .then((response) => {
        setProgressByService(response);
      })
      .catch((error) => {
        console.log("error : ", error);
      });
  };

  const getConstructionImage = async (constructionId: number) => {
    try {
      const result = await api.get(
        `/api/constructions/image/${constructionId}`
      );
      setConstructionImage(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  function isConstructionNotSelected() {
    return (
      constructionId === null ||
      constructionId === undefined ||
      constructionId === 0
    );
  }

  const onMouseOver = (item: any) => {
    if (clickedItem != null && clickedItem.appUniqueId === item.appUniqueId) {
      return;
    }

    if (item != null && hoveredItem !== item.id) {
      setHoveredItem(item);
      return;
    }
  };

  const onMouseLeave = (item: any) => {
    if (clickedItem) {
      setClickedItem(null);
    }

    if (hoveredItem && hoveredItem.id === item.id) {
      setHoveredItem(null);
    }
  };

  const serviceOnClick = (item: any) => {
    setHoveredItem(null);
    setClickedItem(item);
  };

  const onAddServiceClick = () => {
    setIsDialogOpen(true);
  };

  const handleExportImportMenuClick = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportImportMenuClose = (option: ThreeDotsIconEnum) => {
    if (option === ThreeDotsIconEnum.IMPORT_XLSX) {
      setShowImportXLSXDikalog(true);
    }

    if (option === ThreeDotsIconEnum.PDF) {
      generatePDFByBackEnd();
    }

    setAnchorEl(null);
  };

  const generatePDFByBackEnd = async () => {
    if (
      constructionId === null ||
      constructionId === undefined ||
      constructionId === 0
    ) {
      return;
    }

    if (
      dates === undefined ||
      dates.startDate === undefined ||
      dates.endDate === undefined
    ) {
      return;
    }

    setState(BaseState.loading);
    if (selectedFilter === undefined) {
      const response = await verticalService.exportPDF(
        constructionId.toString(),
        dates.startDate,
        dates.endDate,
        selectedTab.toLowerCase()
      );
      sendPDFExportToUser(response);
    } else {
      const selectedValues = selectedFilter;
      const startDate = new Date(selectedValues.beginDate);
      const endDate = new Date(selectedValues.endDate);
      const responsibleUsersId: string[] = [];
      const serviceTypesIds: string[] = [];

      responsibleUsers.map((user) => {
        if (selectedValues.responsibleList.includes(user.name)) {
          responsibleUsersId.push(user.id.toString());
        }
      });
      selectedValues.serviceList.map((service: any) => {
        servicesTypes.map((serviceType) => {
          serviceType.services.map((serviceType) => {
            if (serviceType.name === service) {
              serviceTypesIds.push(serviceType.id.toString());
            }
          });
        });
      });
      const responsibleUsersAsString = responsibleUsersId.join(",");

      const response = await verticalService.exportPDF(
        constructionId.toString(),
        startDate,
        endDate,
        selectedTab.toLowerCase(),
        responsibleUsersAsString,
        selectedValues.locationList.join(","),
        serviceTypesIds.join(","),
        1,
        true
      );
      sendPDFExportToUser(response);
    }

    setState(BaseState.success);
  };

  const sendPDFExportToUser = async (pdf: string) => {
    const linkSource = `data:application/vnd.ms-excel;base64,${pdf}`;
    const downloadLink = document.createElement("a");

    const period = dates
      ? getPeriodByTime(selectedTab, dates.startDate)
      : "periodo-desconhecido";

    const fileName = `${
      "Programação de Serviços - " +
      (selectedClient?.name ?? "cliente-desconhecido") +
      "_Programação_" +
      period +
      "_" +
      (selectedConstruction?.name ?? "construcao-desconhecida")
    }.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const onCloseDialog = () => {
    if (editActionItem && dayServicesItems.data.length > 0) {
      setIsDayServicesDialogOpen(true);
    }
    setIsDialogOpen(false);
    setEditActionItem(undefined);
    (addServiceDialogRef.current as any).cleanData();
  };

  const onConfirmDialog = async (
    model: VerticalServiceModel,
    newServiceType?: NewServiceType,
    purchasingModel?: PurchasingModel,
    contractingModel?: ContractingModel
  ) => {
    if (newServiceType) {
      const serviceType = await verticalService.createCustomServiceType(
        constructionId,
        newServiceType.name,
        newServiceType.groupId
      );
      model.verticalServiceTypeId = serviceType.id;
      model.verticalServiceGroupId = serviceType.verticalServiceGroupId;
      await findServiceTypes(constructionId);
    }

    if (editActionItem) {
      editService(model);
    } else {
      addNewService(model, purchasingModel, contractingModel);
    }
  };

  function addNewService(
    model: VerticalServiceModel,
    purchasingModel?: PurchasingModel,
    contractingModel?: ContractingModel
  ) {
    if (!dates?.startDate || !dates?.endDate) {
      return;
    }

    setAddServiceDialogLoading(true);
    model.constructionid = constructionId;
    verticalService
      .addService(model)
      .then(async (response: any) => {
        if (purchasingModel) {
          purchasingContractinService
            .savePurchasingPlan(constructionId, {
              name: purchasingModel.name,
              unit: purchasingModel.unit,
              quantity: purchasingModel.quantity,
              specifications: purchasingModel.specifications,
              serviceIds: [response.id],
            } as PurchasingPlanEntity)
            .then(() => {
              console.log("purchasingModel saved");
            })
            .catch(() => {
              console.log("error on save contractingModel");
            });
        }

        if (contractingModel) {
          purchasingContractinService
            .saveContractingPlan(constructionId, {
              name: contractingModel.name,
              specifications: contractingModel.specifications,
              serviceIds: [response.id],
            } as ContractingPlanEntity)
            .then(() => {
              console.log("contractingModel saved");
            })
            .catch(() => {
              console.log("error on save contractingModel");
            });
        }

        setAddServiceDialogLoading(false);
        setOpenSnackbar(true);
        setSnackbarMessage("Serviço adicionado com sucesso");
        setIsDialogOpen(false);
        findTableContent(dates.startDate, dates.endDate);
        findServiceTypes(constructionId);
        getServicesProgressByConstruction(constructionId);

        addServiceDialogRef.current?.cleanData();
      })
      .catch(() => {
        setAddServiceDialogLoading(false);
        setOpenSnackbar(true);
        setSnackbarMessage("Erro ao adicionar serviço");
      });
  }

  const editService = (model: VerticalServiceModel) => {
    if (!dates?.startDate || !dates?.endDate) {
      return;
    }

    setAddServiceDialogLoading(true);
    model.constructionid = constructionId;

    if (
      typeof model.responsibleUserId === "object" &&
      model.responsibleUserId
    ) {
      model.responsibleUserId = (model.responsibleUserId as any).id;
    }

    if (!model.id) {
      setSnackbarMessage("Erro ao atualizar serviço");
      return;
    }

    let isBeginDateChanged = false;
    let isEndDateChanged = false;

    try {
      const begimDateAsDate = new Date(model?.beginDate!);
      const endDateAsDate = new Date(model?.endDate!);
      const editItemBeginDate = new Date(editActionItem?.beginDate!);
      const editItemEndDate = new Date(editActionItem?.endDate!);

      isBeginDateChanged =
        begimDateAsDate.getTime() !== editItemBeginDate.getTime();
      isEndDateChanged = endDateAsDate.getTime() !== editItemEndDate.getTime();

      if (isBeginDateChanged || isEndDateChanged) {
        openOrderOfStepsPopUp(
          model.id,
          editActionItem?.beginDate,
          model.beginDate,
          editActionItem?.endDate,
          model.endDate
        );
      }
    } catch (error) {
      console.log("error : ", error);
    }

    verticalService
      .updateService(
        {
          ...model,
          status: (model.status as any).value
            ? (model.status as any).value
            : model.status,
        },
        model.id.toString()
      )
      .then(() => {
        setAddServiceDialogLoading(false);
        setOpenSnackbar(true);
        setSnackbarMessage("Serviço atualizado com sucesso");
        setIsDialogOpen(false);

        if (!isBeginDateChanged && !isEndDateChanged) {
          findTableContent(dates.startDate, dates.endDate);
        }

        setEditActionItem(undefined);
        addServiceDialogRef.current?.cleanData();
        setIsDayServicesDialogOpen(true);
        // update dayServicesItems.data changing old model to new model
        const index = dayServicesItems.data.findIndex(
          (item) => item.id === model.id
        );
        dayServicesItems.data[index] = model;
        setIsDayServicesDialogOpen(true);
      })
      .catch(() => {
        setAddServiceDialogLoading(false);
        setOpenSnackbar(true);
        setSnackbarMessage("Erro ao atualizar serviço");
      });
  };

  const openOrderOfStepsPopUp = (
    changedServiceId: number,
    oldStartDate: Date | undefined,
    newStartDate: Date | undefined,
    oldEndDate: Date | undefined,
    newEndDate: Date | undefined
  ) => {
    setOrderOfStepsData({
      changedServiceId,
      newStartDate: newStartDate,
      oldStartDate: oldStartDate,
      oldEndDate: oldEndDate,
      newEndDate: newEndDate,
    });
    setIsOrderOfStepsPopUpOpen(true);
  };

  const handleTableControlNextClick = () => {
    switch (selectedTab) {
      case TabsEnum.semanal:
        nextWeek();
        break;
      case TabsEnum.mensal:
        nextMonth();
        break;
      case TabsEnum.trimestral:
        nextTrimester();
        break;
      case TabsEnum.semestral:
        nextSemester();
        break;
      case TabsEnum.anual:
        nextYear();
        break;
      default:
        break;
    }
  };

  const handleTableControlPreviousClick = () => {
    switch (selectedTab) {
      case TabsEnum.semanal:
        previousWeek();
        break;
      case TabsEnum.mensal:
        previousMonth();
        break;
      case TabsEnum.trimestral:
        previousTrimester();
        break;
      case TabsEnum.semestral:
        previousSemester();
        break;
      case TabsEnum.anual:
        previousYear();
        break;
      default:
        break;
    }
  };

  const nextWeek = () => {
    if (!dates?.startDate || !dates?.endDate) {
      return;
    }
    dates.startDate.setDate(dates.startDate.getDate() + 7);
    dates.endDate.setDate(dates.endDate.getDate() + 7);
    setDates({
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
    findTableContent(dates.startDate, dates.endDate);
  };

  const previousWeek = () => {
    if (!dates?.startDate || !dates?.endDate) {
      return;
    }
    dates.startDate.setDate(dates.startDate.getDate() - 7);
    dates.endDate.setDate(dates.endDate.getDate() - 7);
    setDates({
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
    findTableContent(dates.startDate, dates.endDate);
  };

  const nextMonth = () => {
    if (!dates?.startDate || !dates?.endDate) {
      return;
    }
    dates.startDate.setMonth(dates.startDate.getMonth() + 1);
    dates.endDate.setMonth(dates.endDate.getMonth() + 1);
    setDates({
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
    findTableContent(dates.startDate, dates.endDate);
  };

  const previousMonth = () => {
    if (!dates?.startDate || !dates?.endDate) {
      return;
    }
    dates.startDate.setMonth(dates.startDate.getMonth() - 1);
    dates.endDate.setMonth(dates.endDate.getMonth() - 1);
    setDates({
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
    findTableContent(dates.startDate, dates.endDate);
  };

  const nextTrimester = () => {
    if (!dates?.startDate || !dates?.endDate) {
      return;
    }
    dates.startDate.setMonth(dates.startDate.getMonth() + 3);
    dates.endDate.setMonth(dates.endDate.getMonth() + 3);
    setDates({
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
    findTableContent(dates.startDate, dates.endDate);
  };

  const previousTrimester = () => {
    if (!dates?.startDate || !dates?.endDate) {
      return;
    }
    dates.startDate.setMonth(dates.startDate.getMonth() - 3);
    dates.endDate.setMonth(dates.endDate.getMonth() - 3);
    setDates({
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
    findTableContent(dates.startDate, dates.endDate);
  };

  const nextSemester = () => {
    if (!dates?.startDate || !dates?.endDate) {
      return;
    }
    dates.startDate.setMonth(dates.startDate.getMonth() + 6);
    dates.endDate.setMonth(dates.endDate.getMonth() + 6);
    setDates({
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
    findTableContent(dates.startDate, dates.endDate);
  };

  const previousSemester = () => {
    if (!dates?.startDate || !dates?.endDate) {
      return;
    }
    dates.startDate.setMonth(dates.startDate.getMonth() - 6);
    dates.endDate.setMonth(dates.endDate.getMonth() - 6);
    setDates({
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
    findTableContent(dates.startDate, dates.endDate);
  };

  const nextYear = () => {
    if (!dates?.startDate || !dates?.endDate) {
      return;
    }
    dates.startDate.setFullYear(dates.startDate.getFullYear() + 1);
    dates.endDate.setFullYear(dates.endDate.getFullYear() + 1);
    setDates({
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
    findTableContent(dates.startDate, dates.endDate);
  };

  const previousYear = () => {
    if (!dates?.startDate || !dates?.endDate) {
      return;
    }
    dates.startDate.setFullYear(dates.startDate.getFullYear() - 1);
    dates.endDate.setFullYear(dates.endDate.getFullYear() - 1);
    setDates({
      startDate: dates.startDate,
      endDate: dates.endDate,
    });
    findTableContent(dates.startDate, dates.endDate);
  };

  const moreItemsOnClick = (item: any[], groupColor: string, type: string) => {
    setDayServicesItems({
      serviceGroupColor: groupColor,
      data: item,
      serviceType: type,
    });
    setIsDayServicesDialogOpen(true);
  };

  const getServiceTypeOnClick = (type: string) => {
    setSelectedDayServicesItems(type);
  };

  const editServiceOnClick = (item: any) => {
    setIsDayServicesDialogOpen(false);
    setEditActionItem(item);
    setAddServiceDialogLoading(false);
    // Important! Open dialog after 100ms to avoid flickering
    setTimeout(() => {
      setIsDialogOpen(true);
    }, 100);
  };

  const deleteServiceOnClick = (item: any) => {
    setDeleteActionItem(item);
    setIsDayServicesDialogOpen(false);
    setOpenDeleteActionDialog(true);
  };

  const closeListActionsPopUp = () => {
    setIsDayServicesDialogOpen(false);
    setDayServicesItems({
      serviceGroupColor: "#000",
      data: [],
      serviceType: "",
    });
  };

  const deleteActionialogButtonCancelOnClick = () => {
    setOpenDeleteActionDialog(false);
    setEditActionItem(undefined);
  };

  const deleteActionialogButtonConfirmOnClick = () => {
    if (!dates?.startDate || !dates?.endDate) {
      return;
    }
    seDeleteActiontLoadingDialog(true);
    verticalService
      .deleteService(deleteActionItem.id)
      .then(() => {
        seDeleteActiontLoadingDialog(false);
        setOpenDeleteActionDialog(false);
        setOpenSnackbar(true);
        setSnackbarMessage("Serviço excluído com sucesso");
        setIsDayServicesDialogOpen(true);
        dayServicesItems.data.splice(
          dayServicesItems.data.indexOf(deleteActionItem),
          1
        );
        findTableContent(dates.startDate, dates.endDate);
        getServicesProgressByConstruction();
      })
      .catch(() => {
        seDeleteActiontLoadingDialog(false);
        setOpenDeleteActionDialog(false);
        setOpenSnackbar(true);
        setIsDayServicesDialogOpen(true);
        setSnackbarMessage("Erro ao excluir serviço");
      });
  };

  const serviceClickEditActionCallback = (item: any) => {
    item.responsibleUserName = item.responsibleUserId.name;
    editServiceOnClick(item);
  };

  const serviceClickDeleteActionCallback = (item: any) => {
    deleteServiceOnClick(item);
  };

  const handleOnTabChange = (newValue: string) => {
    setSelectedTab(newValue);
    if (!dates?.startDate || !dates?.endDate) {
      return;
    }

    findTableContent(dates.startDate, dates.endDate, newValue);
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getPeriodBySelectedTab = (date: Date, endDate?: Date) => {
    let dateFormated = "";
    switch (selectedTab) {
      case TabsEnum.semanal:
        dateFormated = capitalizeFirstLetter(
          date
            .toLocaleDateString("pt-BR", {
              weekday: "short",
              day: "2-digit",
              month: "2-digit",
            })
            .replace(".,", "")
        );
        break;
      case TabsEnum.mensal:
        const startDate = capitalizeFirstLetter(
          date.toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
          })
        );
        const endDateFormatted = capitalizeFirstLetter(
          endDate?.toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
          }) || ""
        );
        dateFormated = `${startDate} à ${endDateFormatted}`;
        break;
      case TabsEnum.trimestral:
        const startDateTrimester = capitalizeFirstLetter(
          date.toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
          })
        );
        const endDateTrimester = capitalizeFirstLetter(
          endDate?.toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
          }) || ""
        );
        dateFormated = `${startDateTrimester} à ${endDateTrimester}`;
        break;
      case TabsEnum.semestral:
        dateFormated = capitalizeFirstLetter(
          date.toLocaleDateString("pt-BR", {
            month: "long",
          })
        );
        break;
      case TabsEnum.anual:
        dateFormated = capitalizeFirstLetter(
          date.toLocaleDateString("pt-BR", {
            month: "short",
          })
        );
        dateFormated +=
          "/" +
          date.toLocaleDateString("pt-BR", {
            year: "numeric",
          });
        dateFormated = dateFormated.replace(".", "");
        break;
      default:
        break;
    }

    return dateFormated;
  };

  const getPeriodByTime = (
    selectedTimePeriod: string,
    date: Date,
    endDate?: Date
  ) => {
    let dateFormated = "";

    const capitalizeFirstLetter = (str: string) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    switch (selectedTimePeriod) {
      case TabsEnum.semanal:
        const weekStart = new Date(date);
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekStart.getDate() + 4);

        dateFormated = `${weekStart.toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })} à ${weekEnd.toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })}`;
        break;

      case TabsEnum.mensal:
        dateFormated = capitalizeFirstLetter(
          date.toLocaleDateString("pt-BR", { month: "long" })
        );
        break;

      case TabsEnum.trimestral:
        const month = date.getMonth();
        const quarterStart = new Date(date.getFullYear(), month, 1);
        const quarterEnd = new Date(date.getFullYear(), month + 2, 1);
        quarterEnd.setMonth(quarterEnd.getMonth() + 1);
        quarterEnd.setDate(0);

        dateFormated = `${capitalizeFirstLetter(
          quarterStart.toLocaleDateString("pt-BR", { month: "long" })
        )} à ${capitalizeFirstLetter(
          quarterEnd.toLocaleDateString("pt-BR", { month: "long" })
        )}`;
        break;

      case TabsEnum.semestral:
        const currentMonth = date.getMonth();
        const semesterStart = currentMonth < 6 ? "Janeiro" : "Julho";
        const semesterEnd = currentMonth < 6 ? "Junho" : "Dezembro";

        dateFormated = `${semesterStart} à ${semesterEnd}`;
        break;

      case TabsEnum.anual:
        dateFormated = date.toLocaleDateString("pt-BR", { year: "numeric" });
        break;

      default:
        break;
    }

    return dateFormated;
  };

  const getPeriodBySelectedTabForPrintedState = (
    dateAsString: string,
    endDateAsString: string
  ) => {
    let dateFormated = "";

    const date = new Date(dateAsString);
    const endDate = new Date(endDateAsString);

    if (!date || !endDate) {
      return "";
    }

    switch (selectedTab) {
      case TabsEnum.semanal:
        const firstDay = date
          .toLocaleDateString("pt-BR", {
            day: "2-digit",
          })
          .replace(".,", "");
        const secoundDay = endDate?.toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
        });
        dateFormated = `${firstDay} à ${secoundDay}`;
        break;
      case TabsEnum.mensal:
        const startDate = date.toLocaleDateString("pt-BR", {
          month: "long",
        });
        dateFormated = startDate;
        break;
      case TabsEnum.trimestral:
        const startDateTrimester = date.toLocaleDateString("pt-BR", {
          month: "short",
        });
        const endDateTrimester = endDate?.toLocaleDateString("pt-BR", {
          month: "short",
        });
        dateFormated =
          `Trimestral - ${startDateTrimester} à ${endDateTrimester}`.replaceAll(
            ".",
            ""
          );
        break;
      case TabsEnum.semestral:
        const startDateFormated = date.toLocaleDateString("pt-BR", {
          month: "short",
        });
        const endDateFormated = endDate?.toLocaleDateString("pt-BR", {
          month: "short",
        });
        dateFormated =
          `Semestral - ${startDateFormated} à ${endDateFormated}`.replaceAll(
            ".",
            ""
          );
        break;
      case TabsEnum.anual:
        dateFormated +=
          "Anual " +
          date.toLocaleDateString("pt-BR", {
            year: "numeric",
          });
        dateFormated = dateFormated.replace(".", "");
        break;
      default:
        break;
    }

    return dateFormated;
  };

  const filterOnClick = () => {
    setIsFilterDrawerOpen(true);
  };

  const onCloseFilterDrawer = () => {
    setIsFilterDrawerOpen(false);
  };

  const onApplyFilter = (selectedValues: any) => {
    setSelectedFilter(selectedValues);
    const startDate = new Date(selectedValues.beginDate);
    const endDate = new Date(selectedValues.endDate);
    const responsibleUsersId: string[] = [];
    const serviceTypesIds: string[] = [];

    responsibleUsers.map((user) => {
      if (selectedValues.responsibleList.includes(user.name)) {
        responsibleUsersId.push(user.id.toString());
      }
    });
    selectedValues.serviceList.map((service: any) => {
      servicesTypes.map((serviceType) => {
        serviceType.services.map((serviceType) => {
          if (serviceType.name === service) {
            serviceTypesIds.push(serviceType.id.toString());
          }
        });
      });
    });
    const responsibleUsersAsString = responsibleUsersId.join(",");

    findTableContent(
      startDate,
      endDate,
      selectedTab,
      responsibleUsersAsString,
      selectedValues.locationList.join(","),
      serviceTypesIds.join(","),
      true
    );
  };

  const openUploadImageDialog = (
    verticalServiceModel?: VerticalServiceModel
  ) => {
    setDeleteActionItem(verticalServiceModel);
    setSelectedVerticalService(verticalServiceModel);
    setIsDayServicesDialogOpen(false);
    setIsUploadImageDialogOpen(true);
    getImagesByVerticalServiceId(verticalServiceModel);
  };

  const closeUploadImageDialog = () => {
    setIsUploadImageDialogOpen(false);
  };

  const openUpdateServiceProgressDialog = (model?: VerticalServiceModel) => {
    if (!model?.id) {
      return;
    }
    getServiceProgressByServiceId(constructionId, model.id);
    setSelectedVerticalService(model);
    setIsUpdateServiceProgressDialogOpen(true);
    setIsDayServicesDialogOpen(false);
  };

  const addPurchasingContractingPlanOnClick = (
    model?: VerticalServiceModel
  ) => {
    if (!model?.id) {
      return;
    }

    navigate(
      PURCHASING_CONTRACTING_PLAN_FORM_PATH + `?verticalServiceId=${model.id}`
    );
  };

  const closeUpdateServiceProgressDialog = () => {
    setIsUpdateServiceProgressDialogOpen(false);
    setSelectedVerticalService(undefined);
  };

  const updateServiceProgressConfirmOnClick = (
    model: UpdateServiceProgressModel
  ) => {
    if (selectedVerticalService?.id === undefined) {
      return;
    }
    if (!dates?.startDate || !dates?.endDate) {
      return;
    }
    model.constructionId = constructionId;
    setAddServiceDialogLoading(true);
    verticalService
      .createNewerviceProgress(model, selectedVerticalService?.id)
      .then((response) => {
        setOpenSnackbar(true);
        setSnackbarMessage("Serviço atualizado com sucesso");
        setIsUpdateServiceProgressDialogOpen(false);
        setSelectedVerticalService(undefined);
        findTableContent(dates.startDate, dates.endDate);
        getServicesProgressByConstruction(constructionId);
        setAddServiceDialogLoading(false);
      })
      .catch(() => {
        setOpenSnackbar(true);
        setSelectedVerticalService(undefined);
        setAddServiceDialogLoading(false);
        setSnackbarMessage("Erro ao atualizar serviço");
      });
  };

  const updatePastServiceProgressConfirmOnClick = (
    serviceId: number,
    pastProgressId: number,
    newValue: number,
    updateType: ProgressUpdateType
  ) => {
    verticalService
      .updateServiceProgress(serviceId, pastProgressId, newValue, updateType)
      .then(() => {
        setOpenSnackbar(true);
        setSnackbarMessage("Progresso atualizado com sucesso");
        getServiceProgressByServiceId(constructionId, serviceId);
      })
      .catch(() => {
        setOpenSnackbar(true);
        setSnackbarMessage("Erro ao atualizar serviço");
      });
  };

  const uploadImagesConfirmOnClick = (
    images: any[],
    serviceModel?: VerticalServiceModel
  ) => {
    if (serviceModel?.id) {
      setIsUploadImageDialogLoading(true);
      verticalService
        .uploadImages(images, serviceModel.id)
        .then(() => {
          setIsUploadImageDialogLoading(false);
          setSnackbarMessage("Imagens adicionadas com sucesso");
          setOpenSnackbar(true);
          getImagesByVerticalServiceId(serviceModel);
        })
        .catch(() => {
          setIsUploadImageDialogLoading(false);
          setSnackbarMessage("Erro ao fazer upload de imagens");
          setOpenSnackbar(true);
        });
    } else {
      setSnackbarMessage("Erro ao fazer upload de imagens");
      setOpenSnackbar(true);
    }
  };

  const getImagesByVerticalServiceId = async (
    verticalServiceModel?: VerticalServiceModel
  ) => {
    if (verticalServiceModel?.id) {
      verticalService.getImages(verticalServiceModel.id).then((images) => {
        setSelectedVerticalServiceImages(images);
      });
    }
  };

  const shareOnClick = async () => {
    setPrintState(true);
    setNavbarVisibility(false);
  };

  const dowloadExcelOnClick = async () => {
    const response = await verticalService.getExcelReport(constructionId);
    downloadExcelXLS(response);
  };

  function downloadExcelXLS(attachment: string) {
    const linkSource = `data:application/vnd.ms-excel;base64,${attachment}`;
    const downloadLink = document.createElement("a");
    const fileName = `${"report - " + new Date()}.xlsx`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const printScreen = () => {
    const input = pdfRef.current;
    if (!input) {
      return;
    }

    setPrintStateFooter(false);

    setTimeout(() => {
      const divProperties = input as any;
      const totalHeight = divProperties.scrollHeight;

      html2canvas(input, {
        allowTaint: true,
        useCORS: true,
        logging: true,
        height: totalHeight,
        windowHeight: totalHeight,
      }).then((canvas) => {
        var image = canvas.toDataURL("image/png", 1.0); // here is the most important part because if you dont replace you will get a DOM 18 exception.
        var a = document.createElement("a");
        a.href = image;
        a.download = "report.png";
        document.body.appendChild(a);
        a.click();
        setPrintStateFooter(true);
      });
    }, 1000);
  };

  const goToReport = () => {
    navigate(`/vertical-servicos-relatorio`);
  };

  const onDeleteCustomServiceType = (serviceTypeId: string) => {
    verticalService.deleteCustomServiceType(constructionId, serviceTypeId);
  };

  const setDefaultStateOnClick = () => {
    setPrintState(false);
    setNavbarVisibility(true);
  };

  const sortServicesByBeginDate = (services: any[]) => {
    return services;
  };

  const onChangeGroupByTime = (value: boolean) => {
    setShowItemsSameDataByGroup(value);

    if (!dates?.startDate || !dates?.endDate) {
      return;
    }
    findTableContent(
      dates.startDate,
      dates.endDate,
      selectedTab,
      undefined,
      undefined,
      undefined,
      false,
      value
    );
  };

  const importXLSXConfirmOnClick = (file: File) => {
    if (!dates?.startDate || !dates?.endDate) {
      return;
    }

    setIsLoadingShowImportXLSXDialog(true);
    verticalService
      .uploadExcelFile(constructionId, file)
      .then(() => {
        setIsLoadingShowImportXLSXDialog(false);
        setShowImportXLSXDikalog(false);
        setOpenSnackbar(true);
        setSnackbarMessage("Planilha importada com sucesso");
        findTableContent(dates.startDate, dates.endDate);
      })
      .catch(() => {
        setIsLoadingShowImportXLSXDialog(false);
        setShowImportXLSXDikalog(false);
        setOpenSnackbar(true);
        setSnackbarMessage("Erro ao importar planilha");
      });
  };

  const deleteImageOnClick = (image: VerticalServiceImage) => {
    setIsUploadImageDialogOpen(false);
    setDeletedImage(image);
  };

  const confirgDeleteImageOnClick = () => {
    const serviceId = (deletedImage as any)?.verticalService;
    const imageId = deletedImage?.id;
    if (serviceId && imageId) {
      setIsUploadImageDialogLoading(true);
      verticalService
        .deleteImage(serviceId, imageId)
        .then(() => {
          setIsUploadImageDialogLoading(true);
          getImagesByVerticalServiceId(selectedVerticalService);
          setDeletedImage(undefined);
          setIsUploadImageDialogOpen(true);
          setSnackbarMessage("Imagem excluída com sucesso");
          setOpenSnackbar(true);
          setIsUploadImageDialogLoading(false);
        })
        .catch(() => {
          setSnackbarMessage("Erro ao excluir imagem");
          setOpenSnackbar(true);
          setIsUploadImageDialogLoading(false);
        });
    } else {
      setSnackbarMessage("Erro ao excluir imagem");
      setOpenSnackbar(true);
    }
  };

  const cancelDeleteImageOnClick = () => {
    setDeletedImage(undefined);
    setIsUploadImageDialogLoading(false);
    setIsUploadImageDialogOpen(true);
  };

  const onOrderOfStepsPopUpClose = () => {
    setIsOrderOfStepsPopUpOpen(false);
    findTableContent(dates?.startDate, dates?.endDate);
  };

  const onOrderOfStepsPopUpConfirm = (selectedTypes: ItemType[]) => {
    if (
      orderOfStepsData?.newStartDate === undefined ||
      orderOfStepsData?.oldStartDate === undefined ||
      orderOfStepsData?.oldEndDate === undefined ||
      orderOfStepsData?.newEndDate === undefined ||
      orderOfStepsData?.changedServiceId === undefined
    ) {
      return;
    }

    verticalService
      .updateServiceDatesByServiceTypes(
        constructionId,
        orderOfStepsData.changedServiceId,
        selectedTypes.map((type) => type.id),
        orderOfStepsData.oldStartDate,
        orderOfStepsData.newStartDate,
        orderOfStepsData.oldEndDate,
        orderOfStepsData.newEndDate
      )
      .then(() => {
        setOpenSnackbar(true);
        setSnackbarMessage("Datas dos serviços atualizadas com sucesso");
        setIsOrderOfStepsPopUpOpen(false);
        findTableContent(dates?.startDate, dates?.endDate);
      })
      .catch(() => {
        setOpenSnackbar(true);
        setSnackbarMessage("Erro ao atualizar datas dos serviços");
      });
  };

  function getConstructionInitialLetters(construction: string): string {
    const array = construction.split(" ");
    let firstLetter = array[0]?.charAt(0);
    let lastLetter = array.length > 1 ? array[array.length - 1]?.charAt(0) : "";

    return `${firstLetter}${lastLetter}`;
  }

  useEffect(() => {
    // const storedConstructionId = localStorage.getItem("selectedConstructionId");
    // const storedConstructionName = localStorage.getItem(
    //   "selectedConstructionName"
    // );

    if (constructionId) {
      // const constructionId = Number(constructionId);
      // setConstructionId(constructionId);
      const constructionName = constructions.find(
        (construction) => construction.id === constructionId
      );
      setSelectedConstructionName(constructionName?.name || "");
    }
  }, [constructionId]);

  return (
    <ReactPageBaseComponent>
      <Styles.PageContainer isNavBarHide={isPrintState}>
        {isConstructionNotSelected() ? (
          <Styles.CenterContainer>
            <EmptyClientAndConstructionState pageDescription="a vertical de serviços" />{" "}
          </Styles.CenterContainer>
        ) : null}

        {state === BaseState.loading && !isConstructionNotSelected() ? (
          <Styles.CenterContainer>
            <LoadingComponent />
          </Styles.CenterContainer>
        ) : null}

        {state === BaseState.error ? (
          <Styles.CenterContainer>
            <ErrorComponent />
          </Styles.CenterContainer>
        ) : null}

        {state === BaseState.success && !isConstructionNotSelected() ? (
          <Styles.ContentContainer ref={pdfRef}>
            <Styles.HeaderContainer>
              <Styles.AboutContainer>
                {constructionImage ? (
                  <Styles.ConstructionImage src={constructionImage} />
                ) : (
                  <Styles.ConstructionImagePlaceholder>
                    {getConstructionInitialLetters(selectedConstructionName)}
                  </Styles.ConstructionImagePlaceholder>
                )}
                <Styles.TitleContainer>
                  <Styles.PageTitle>Vertical de Serviços</Styles.PageTitle>
                  <Styles.ConstructionNameTitle>
                    {selectedConstructionName}
                  </Styles.ConstructionNameTitle>
                </Styles.TitleContainer>
              </Styles.AboutContainer>
              {!isPrintState && (
                <Styles.TimeContainer>
                  <Styles.GroupByTimeContainer>
                    <Styles.GroupByTimeText>
                      Agrupar serviços que se repetem no mesmo período
                    </Styles.GroupByTimeText>
                    <Styles.SwitchTime
                      checked={showItemsSameDataByGroup}
                      onChange={(event, checked) =>
                        onChangeGroupByTime(checked)
                      }
                    />
                  </Styles.GroupByTimeContainer>
                  <Styles.LastUpdateContainer>
                    <CheckIcon style={{ color: Colors.neutralColorWhite }} />
                    <Styles.LastUpdateText>
                      {lastUpdatedBy
                        ? `Última atualização em ${lastUpdate} por ${lastUpdatedBy}`
                        : `Última atualização em ${lastUpdate}`}
                    </Styles.LastUpdateText>
                  </Styles.LastUpdateContainer>
                </Styles.TimeContainer>
              )}
              {isPrintState && (
                <Styles.PrintStatePageDescriptionContainer>
                  <Styles.PrintStatePageDescriptionText>
                    {`Empresa: ${selectedClientName}`}
                  </Styles.PrintStatePageDescriptionText>
                  <Styles.PrintStatePageDescriptionText>
                    {`Obra: ${selectedConstructionName}`}
                  </Styles.PrintStatePageDescriptionText>

                  <Styles.PrintStatePageDescriptionText>
                    {`Período: ${getPeriodBySelectedTabForPrintedState(
                      timePeriodListState[0].startDateAsDate,
                      timePeriodListState[timePeriodListState.length - 1]
                        .endDateAsDate
                    )}`}
                  </Styles.PrintStatePageDescriptionText>
                </Styles.PrintStatePageDescriptionContainer>
              )}
            </Styles.HeaderContainer>

            <Styles.WhiteContainer>
              <Styles.DescriptionContainer>
                <Styles.PageDescription>
                  Insira novo serviço ou clique na tabela para atualizar um
                  serviço vinculado
                </Styles.PageDescription>
                {tableContent.length === 0 ? (
                  <Styles.TableControllerComponent>
                    {isMobile && (
                      <Styles.TimePeriodFilterDropDownContainer>
                        <DropDownComponent
                          onSelectedValueChanged={(key, value) =>
                            handleOnTabChange(value)
                          }
                          key="mobileTabsEnum"
                          values={Object.values(TabsEnum)}
                          defaultValue={selectedTab.toLowerCase()}
                          selectedValue={selectedTab.toLowerCase()}
                          label={"Visualizar por"}
                        />
                      </Styles.TimePeriodFilterDropDownContainer>
                    )}
                    <IconButtonComponent
                      onClick={handleTableControlPreviousClick}
                    >
                      <ArrowLeftIcon style={{ color: Colors.balticSea }} />
                    </IconButtonComponent>
                    <IconButtonComponent onClick={handleTableControlNextClick}>
                      <ArrowRightIcon style={{ color: Colors.balticSea }} />
                    </IconButtonComponent>
                    <Styles.IconButtonActionMenu
                      onClick={(e) => handleExportImportMenuClick(e)}
                      aria-controls={
                        exportImportMenuIsOpen ? "basic-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={
                        exportImportMenuIsOpen ? "true" : undefined
                      }
                    >
                      <ThreeDots style={{ color: Colors.balticSea }} />
                    </Styles.IconButtonActionMenu>
                    <Styles.StyledMenu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={exportImportMenuIsOpen}
                      onClose={handleExportImportMenuClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {!isMobile && (
                        <Styles.StyledMenuItem
                          onClick={() =>
                            handleExportImportMenuClose(ThreeDotsIconEnum.PDF)
                          }
                        >
                          <AddIcon /> Gerar programação
                        </Styles.StyledMenuItem>
                      )}
                      <Styles.StyledMenuItem
                        onClick={() =>
                          handleExportImportMenuClose(
                            ThreeDotsIconEnum.IMPORT_XLSX
                          )
                        }
                      >
                        <DownloadIcon /> Importar planilha
                      </Styles.StyledMenuItem>
                    </Styles.StyledMenu>
                  </Styles.TableControllerComponent>
                ) : (
                  <Styles.ButtonEndAlignWrapper>
                    <ButtonComponent
                      state={ButtonState.DEFAULT_ENABLED}
                      startIcon={<AddOutlinedIcon />}
                      text={isMobile ? "Novo" : "Vincular novo serviço"}
                      onClick={() => onAddServiceClick()}
                      padding={{ top: 8, bottom: 8, left: 20, right: 20 }}
                    />
                  </Styles.ButtonEndAlignWrapper>
                )}
              </Styles.DescriptionContainer>

              {tableContent.length > 0 ? (
                <Styles.PhysicalContainer>
                  <Styles.AdvancementContainer>
                    <AdvancementIndexVerticalComponent
                      title="Índice de avanços físicos"
                      value={progressByConstruction?.physicalProgress || 0}
                      type={AdvancementIndexType.PHYSICAL}
                      budgetUsed={
                        progressByConstruction?.currentFinancialCoast || 0
                      }
                      showMaterialUserDescription={false}
                      showBudgetDescription={false}
                    />
                    <Styles.PeriodContainer>
                      {moment(constructionDates?.beginningDate).isValid() &&
                        moment(constructionDates?.endDate).isValid() && (
                          <>
                            Período da obra:{" "}
                            <Styles.PeriodBold>
                              {moment(constructionDates?.beginningDate).format(
                                "DD/MM/YYYY"
                              )}{" "}
                              -{" "}
                              {moment(constructionDates?.endDate).format(
                                "DD/MM/YYYY"
                              )}{" "}
                            </Styles.PeriodBold>{" "}
                            ({constructionDates?.constructionDays} total -{" "}
                            {constructionDates?.constructionWeekDays} dias
                            úteis)
                          </>
                        )}
                    </Styles.PeriodContainer>
                  </Styles.AdvancementContainer>
                  <Styles.MarginRightContainer>
                    <Styles.TableControllerComponent>
                      {isMobile && (
                        <Styles.TimePeriodFilterDropDownContainer>
                          <DropDownComponent
                            onSelectedValueChanged={(key, value) =>
                              handleOnTabChange(value)
                            }
                            key="mobileTabsEnum"
                            values={Object.values(TabsEnum)}
                            defaultValue={selectedTab.toLowerCase()}
                            selectedValue={selectedTab.toLowerCase()}
                            label={"Visualizar por"}
                          />
                        </Styles.TimePeriodFilterDropDownContainer>
                      )}
                      <IconButtonComponent
                        onClick={handleTableControlPreviousClick}
                      >
                        <ArrowLeftIcon style={{ color: Colors.balticSea }} />
                      </IconButtonComponent>
                      <IconButtonComponent
                        onClick={handleTableControlNextClick}
                      >
                        <ArrowRightIcon style={{ color: Colors.balticSea }} />
                      </IconButtonComponent>
                      <Styles.IconButtonActionMenu
                        onClick={(e) => handleExportImportMenuClick(e)}
                        aria-controls={
                          exportImportMenuIsOpen ? "basic-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={
                          exportImportMenuIsOpen ? "true" : undefined
                        }
                      >
                        <ThreeDots style={{ color: Colors.balticSea }} />
                      </Styles.IconButtonActionMenu>
                      <Styles.StyledMenu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={exportImportMenuIsOpen}
                        onClose={handleExportImportMenuClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {!isMobile && (
                          <Styles.StyledMenuItem
                            onClick={() =>
                              handleExportImportMenuClose(ThreeDotsIconEnum.PDF)
                            }
                          >
                            <AddIcon /> Gerar programação
                          </Styles.StyledMenuItem>
                        )}
                        <Styles.StyledMenuItem
                          onClick={() =>
                            handleExportImportMenuClose(
                              ThreeDotsIconEnum.IMPORT_XLSX
                            )
                          }
                        >
                          <DownloadIcon /> Importar planilha
                        </Styles.StyledMenuItem>
                      </Styles.StyledMenu>
                    </Styles.TableControllerComponent>
                  </Styles.MarginRightContainer>
                </Styles.PhysicalContainer>
              ) : null}
            </Styles.WhiteContainer>

            <Styles.TableActionContainer>
              {!isMobile && (
                <Styles.TableHeader>
                  <Styles.TabContainer>
                    <Tabs
                      value={selectedTab}
                      onChange={(event, value) => handleOnTabChange(value)}
                      style={{ width: "100%", minHeight: 0 }}
                      sx={{
                        "& .MuiTabs-indicator": {
                          display: "none",
                        },
                      }}
                    >
                      <Styles.CustomTab
                        value={TabsEnum.semanal}
                        label="Semanal"
                      />
                      <Styles.CustomTab
                        value={TabsEnum.mensal}
                        label="Mensal"
                      />
                      <Styles.CustomTab
                        value={TabsEnum.trimestral}
                        label="Trimestral"
                      />
                      <Styles.CustomTab
                        value={TabsEnum.semestral}
                        label="Semestral"
                      />
                      <Styles.CustomTab value={TabsEnum.anual} label="Anual" />
                    </Tabs>
                  </Styles.TabContainer>
                  <Styles.ButtonActionsContainer>
                    {!isMobile && (
                      <Styles.ExportPdfAndFilterContainer>
                        <ButtonComponent
                          type={ButtonType.OUTLINE_SELECTED}
                          text={"Vertical de Serviços"}
                          startIcon={<Article />}
                          onClick={() => {}}
                          padding={{ top: 10, bottom: 10, left: 14, right: 20 }}
                        />
                        <ButtonComponent
                          type={ButtonType.OUTLINE}
                          startIcon={<Article />}
                          onClick={() => goToReport()}
                          text={isMobile ? "" : "Relatório"}
                          padding={{ top: 10, bottom: 10, left: 14, right: 20 }}
                        />
                        <ButtonComponent
                          type={ButtonType.OUTLINE}
                          startIcon={<ShareOutlined />}
                          onClick={() => shareOnClick()}
                          text={isMobile ? "" : "Exportar PDF"}
                          padding={{ top: 10, bottom: 10, left: 14, right: 20 }}
                        />
                        <ButtonComponent
                          type={ButtonType.OUTLINE}
                          startIcon={<FilterOutlined />}
                          onClick={() => filterOnClick()}
                          text={isMobile ? "" : "Filtrar"}
                          padding={{ top: 10, bottom: 10, left: 14, right: 20 }}
                        />
                      </Styles.ExportPdfAndFilterContainer>
                    )}

                    {isMobile && (
                      <Styles.ExportPdfAndFilterContainer>
                        <IconButtonComponent onClick={() => shareOnClick()}>
                          <IconShare />
                        </IconButtonComponent>
                        <IconButtonComponent onClick={() => goToReport()}>
                          <ReportIcon />
                        </IconButtonComponent>
                        <IconButtonComponent onClick={() => filterOnClick()}>
                          <Filter />
                        </IconButtonComponent>
                      </Styles.ExportPdfAndFilterContainer>
                    )}
                  </Styles.ButtonActionsContainer>
                </Styles.TableHeader>
              )}
            </Styles.TableActionContainer>

            <Styles.TableContainer>
              <Styles.HorizontalListPrimaryContainer>
                <Styles.VerticalListRoot
                  columnQuantity={timePeriodListState.length}
                  tableLength={tableContent.length}
                >
                  {tableContent.length === 0 ? (
                    isMobile ? (
                      <div />
                    ) : (
                      <div style={{ width: "100px" }} />
                    )
                  ) : (
                    <Styles.EmptyGridItem isPrintState={isPrintState} />
                  )}

                  {timePeriodListState.map((item) => {
                    const startDate = new Date(item.startDateAsDate);
                    const endDate = new Date(item.endDateAsDate);
                    const today = new Date();

                    // Verifica se a data de hoje está dentro do intervalo
                    const isTodayInRange =
                      today >= startDate && today <= endDate;

                    return (
                      <Styles.ItemVerticalListHeader
                        key={item.space}
                        style={{
                          minWidth: isMobile ? "90px" : "auto",
                          maxWidth: "100%",
                          borderTopLeftRadius: isTodayInRange ? "16px" : "0px",
                          borderTopRightRadius: isTodayInRange ? "16px" : "0px",
                          backgroundColor: isTodayInRange
                            ? Colors.neutralColorSurface
                            : Colors.neutralColorWhite,
                          fontWeight: isTodayInRange ? 700 : 400,
                          fontSize: timePeriodListState.length > 10 ? "12px" : "16px",
                          borderTop: isTodayInRange
                            ? `1px solid ${Colors.lightMercury}`
                            : "none",
                          borderLeft: isTodayInRange
                            ? `1px solid ${Colors.lightMercury}`
                            : "none",
                          borderRight: isTodayInRange
                            ? `1px solid ${Colors.lightMercury}`
                            : "none",
                        }}
                      >
                        {getPeriodBySelectedTab(
                          new Date(item.startDateAsDate),
                          new Date(item.endDateAsDate)
                        )}
                      </Styles.ItemVerticalListHeader>
                    );
                  })}
                </Styles.VerticalListRoot>

                <Styles.HorizontalListContainer
                  gridTemplate={`7fr`}
                  tableLength={tableContent.length}
                >
                  {tableContent.map((serviceType) => {
                    return (
                      <React.Fragment key={serviceType.id}>
                        <Styles.ItemHeadTypeIndicator>
                          {serviceType.name}
                        </Styles.ItemHeadTypeIndicator>
                        <Styles.FullItemContainer
                          columnQuantity={timePeriodListState.length}
                        >
                          {timePeriodListState.map((value, index) => {
                            return (
                              <Styles.ItemHeaderGridContainerEmpty
                                key={"timePeriodList - " + index}
                                isPrintState={isPrintState}
                              />
                            );
                          })}
                        </Styles.FullItemContainer>

                        {serviceType.services.map((item) => {
                          return (
                            <React.Fragment key={item.uniqueApplicationId}>
                              <Styles.ItemHeadServiceIndicator>
                                {item.name}
                              </Styles.ItemHeadServiceIndicator>

                              {
                                <Styles.FullItemEmptyContainerCustomGrid
                                  gridTemplate={(item as any).gridTemplate}
                                >
                                  {(item as any).gridItems.map(
                                    (gridItem: any, index: any) => {
                                      return (
                                        <TableItem
                                          gridItem={gridItem}
                                          index={index}
                                          serviceType={serviceType}
                                          item={item}
                                          onMouseOver={onMouseOver}
                                          onMouseLeave={onMouseLeave}
                                          serviceOnClick={serviceOnClick}
                                          hoveredItem={hoveredItem}
                                          clickedItem={clickedItem}
                                          moreItemsOnClick={moreItemsOnClick}
                                          getServiceTypeOnClick={
                                            getServiceTypeOnClick
                                          }
                                          showOnlyOneService={
                                            showItemsSameDataByGroup
                                          }
                                          serviceClickEditActionCallback={
                                            serviceClickEditActionCallback
                                          }
                                          serviceClickDeleteActionCallback={
                                            serviceClickDeleteActionCallback
                                          }
                                          openUploadImageDialog={
                                            openUploadImageDialog
                                          }
                                          openUpdateServiceProgressDialog={
                                            openUpdateServiceProgressDialog
                                          }
                                          addPurchasingContractingPlanOnClick={
                                            addPurchasingContractingPlanOnClick
                                          }
                                          gridLength={
                                            timePeriodListState.length
                                          }
                                        />
                                      );
                                    }
                                  )}
                                </Styles.FullItemEmptyContainerCustomGrid>
                              }
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                </Styles.HorizontalListContainer>
              </Styles.HorizontalListPrimaryContainer>
            </Styles.TableContainer>
            {tableContent.length === 0 ? (
              <Styles.QuadroAcompanhamento>
                <AlertTriangle />
                <Styles.Texto>
                  Você ainda não possui serviços cadastrados na data
                  selecionada. <br></br>Para adicionar um novo serviço, clique
                  no botão abaixo.
                </Styles.Texto>
                <ButtonComponent
                  state={ButtonState.DEFAULT_ENABLED}
                  text={isMobile ? "Novo" : "Vincular novo serviço"}
                  onClick={() => onAddServiceClick()}
                  padding={{ top: 8, bottom: 8, left: 20, right: 20 }}
                />
              </Styles.QuadroAcompanhamento>
            ) : null}

            {isPrintState && isPrintStateFooterVisible && (
              <Styles.PrintStateButtonContainer>
                <ButtonComponent
                  state={ButtonState.DEFAULT_ENABLED}
                  type={ButtonType.OUTLINE}
                  text="Sair"
                  onClick={() => setDefaultStateOnClick()}
                />
                <VerticalServicePageToPdf
                  tableContent={tableContent}
                  selectedTab={selectedTab}
                  timePeriodListState={timePeriodListState}
                  constructionDates={constructionDates}
                  showItemsSameDataByGroup={false}
                  progressByConstruction={progressByConstruction}
                />
              </Styles.PrintStateButtonContainer>
            )}
          </Styles.ContentContainer>
        ) : null}

        <Footer />
      </Styles.PageContainer>
      <AddServicePopUp
        serviceTypes={servicesTypes}
        isOpen={isDialogOpen}
        onClose={onCloseDialog}
        onConfirm={onConfirmDialog}
        userResponsibles={responsibleUsers}
        isLoadingDialog={addServiceDialogLoading}
        model={editActionItem}
        ref={addServiceDialogRef}
        onDeleteCustomService={onDeleteCustomServiceType}
      />
      <Snackbar
        message={snackbarMessage}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      />
      <DayServicesListPopUp
        isOpen={isDayServicesDialogOpen && dayServicesItems.data.length > 0}
        groupColor={dayServicesItems.serviceGroupColor}
        data={dayServicesItems.data}
        serviceType={dayServicesItems.serviceType}
        closeOnClick={() => closeListActionsPopUp()}
        editServiceOnClick={(item) => editServiceOnClick?.(item)}
        deleteServiceOnClick={(item) => deleteServiceOnClick?.(item)}
        uploadImageOnClick={(item) => openUploadImageDialog(item)}
        updateServiceProgressOnClick={(item) =>
          openUpdateServiceProgressDialog(item)
        }
      />
      <ConfirmDeleteItemPopUp
        title="Excluir serviço"
        description="Deseja realmente excluir este serviço?"
        openDeleteActionDialog={openDeleteActionDialog}
        isLoadingDialog={isDeleteActionLoadingDialog}
        dialogButtonCancelOnClick={deleteActionialogButtonCancelOnClick}
        dialogButtonConfirmOnClick={deleteActionialogButtonConfirmOnClick}
      />

      <ConfirmDeleteItemPopUp
        title="Excluir imagem"
        description="Deseja realmente excluir esta imagem?"
        openDeleteActionDialog={deletedImage !== undefined}
        isLoadingDialog={isDeleteActionLoadingDialog}
        dialogButtonCancelOnClick={() => cancelDeleteImageOnClick()}
        dialogButtonConfirmOnClick={confirgDeleteImageOnClick}
      />

      {isFilterDrawerOpen && (
        <React.Fragment>
          <SwipeableDrawer
            anchor={"right"}
            open={isFilterDrawerOpen}
            onClose={() => onCloseFilterDrawer()}
            onOpen={() => {}}
          >
            <FilterSideBar
              responsibleList={responsibleUsers.map((user) => user.name)}
              locationList={locations}
              serviceList={services}
              beginDate={dates?.startDate || new Date()}
              endDate={dates?.endDate || new Date()}
              onCloseFilterSideBar={() => onCloseFilterDrawer()}
              onApplyFilter={(filter) => onApplyFilter(filter)}
            />
          </SwipeableDrawer>
        </React.Fragment>
      )}

      <AddImagesPopUp
        isLoading={isUploadImageDialogLoading}
        isOpen={isUploadImageDialogOpen}
        images={selectedVerticalServiceImages}
        verticalServiceModel={selectedVerticalService}
        cancelOnClick={() => closeUploadImageDialog()}
        confirmOnClick={(images) =>
          uploadImagesConfirmOnClick(images, selectedVerticalService)
        }
        deleteImageOnClick={(image) => deleteImageOnClick(image)}
      />
      <UpdateServiceProgressPopUp
        isOpen={isUpdateServiceProgressDialogOpen}
        isLoading={isUpdateServiceProgressDialogOpenLoading}
        verticalServiceModel={selectedVerticalService}
        currentProgress={progressByService}
        cancelOnClick={() => closeUpdateServiceProgressDialog()}
        confirmOnClick={(model) => updateServiceProgressConfirmOnClick(model)}
        confirmUpdatePastProgress={(
          serviceId,
          progressUpdateId,
          newValue,
          updateType
        ) =>
          updatePastServiceProgressConfirmOnClick(
            serviceId,
            progressUpdateId,
            newValue,
            updateType
          )
        }
        serviceType={selectedDayServicesItems}
      />
      <ImportExcelFilePopUp
        isOpen={showImportXLSXDialog}
        isLoading={isLoadingShowImportXLSXDialog}
        cancelOnClick={() => setShowImportXLSXDikalog(false)}
        confirmOnClick={(file) => importXLSXConfirmOnClick(file)}
        dowloadXLSXTemplate={() => dowloadExcelOnClick()}
      />
      <OrderOfStepsEditServicePopUp
        verticalServiceGroups={servicesTypesByRegisteredServices}
        isOpen={isOrderOfStepsPopUpOpen}
        isLoading={orderOfStepsPopUpLoading}
        onClose={() => onOrderOfStepsPopUpClose()}
        onConfirm={(selectedTypes) => onOrderOfStepsPopUpConfirm(selectedTypes)}
        cancelOnClick={() => onOrderOfStepsPopUpClose()}
      />
    </ReactPageBaseComponent>
  );
};

export default VerticalServicePage;
