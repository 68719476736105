import styled from "styled-components";
import ReactBaseComponent from "../../../components/BaseComponents/ReactBaseComponent";
import { Colors } from "../../../theme/v2/variants";
import { Button } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import VerticalServiceModel from "../../../services/interfaces/VerticalServiceModel";

import * as Styles from "../VerticalServicePage.styles";

import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

interface MouseHoverComponentProps {
  model?: VerticalServiceModel;
  editServiceOnClick?: (model?: VerticalServiceModel) => void;
  deleteServiceOnClick?: (model?: VerticalServiceModel) => void;
  uploadImageOnClick?: (model?: VerticalServiceModel) => void;
  addPurchasingContractingPlanOnClick?: (model?: VerticalServiceModel) => void;
  updateServiceProgressOnClick?: (model?: VerticalServiceModel) => void;
}

const MouseHoverActionsComponent: React.FC<MouseHoverComponentProps> = ({
  model,
  editServiceOnClick,
  deleteServiceOnClick,
  uploadImageOnClick,
  updateServiceProgressOnClick,
  addPurchasingContractingPlanOnClick,
}) => {
  return (
    <ReactBaseComponent>
      <Container>
        <Styles.HoverButton
          variant="text"
          onClick={() => editServiceOnClick?.(model)}
          endIcon={
            <ArrowForwardIosOutlinedIcon
              style={{ color: Colors.neutralGrayLight, fontSize: 14 }}
            />
          }
        >
          <Styles.TextButton>
            <EditOutlinedIcon
              style={{ color: Colors.neutralGrayLight, fontSize: 24 }}
            />
            Editar serviço
          </Styles.TextButton>
        </Styles.HoverButton>
        <Styles.HoverButton
          variant="text"
          onClick={() => updateServiceProgressOnClick?.(model)}
          endIcon={
            <ArrowForwardIosOutlinedIcon
              style={{
                color: Colors.neutralGrayLight,
                fontSize: 14,
              }}
            />
          }
        >
          <Styles.TextButton>
            <CheckOutlinedIcon
              style={{ color: Colors.neutralGrayLight, fontSize: 24 }}
            />
            Avançar serviço
          </Styles.TextButton>
        </Styles.HoverButton>
        <Styles.HoverButton
          variant="text"
          onClick={() => uploadImageOnClick?.(model)}
          endIcon={
            <ArrowForwardIosOutlinedIcon
              style={{ color: Colors.neutralGrayLight, fontSize: 14 }}
            />
          }
        >
          <Styles.TextButton>
            <AddOutlinedIcon
              style={{ color: Colors.neutralGrayLight, fontSize: 24 }}
            />
            Adicionar imagens
          </Styles.TextButton>
        </Styles.HoverButton>

        <Styles.HoverButton
          variant="text"
          onClick={() => addPurchasingContractingPlanOnClick?.(model)}
          endIcon={
            <ArrowForwardIosOutlinedIcon
              style={{ color: Colors.neutralGrayLight, fontSize: 14 }}
            />
          }
        >
          <Styles.TextButton>
            <ShoppingCartOutlinedIcon
              style={{ color: Colors.neutralGrayLight }}
            />
            Compra/Contratação
          </Styles.TextButton>
        </Styles.HoverButton>

        {/* <Styles.HoverButton
          variant="text"
          style={{ color: Colors.neutralGrayLight }}
          onClick={() => deleteServiceOnClick?.(model)}
          startIcon={<Delete style={{ color: Colors.neutralGrayLight }} />}
        >
          Excluir serviço
        </Styles.HoverButton> */}
      </Container>
    </ReactBaseComponent>
  );
};

const Container = styled.div`
  width: 261px;
  background-color: ${Colors.neutralColorWhite};
  margin-bottom: 0px;
  position: absolute;
  border-radius: 16px;
  border: 1px solid rgba(142, 142, 142, 0.25);
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.25);
  margin-top: 16px;
  z-index: 9999;
`;

export default MouseHoverActionsComponent;
