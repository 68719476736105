import React, { useRef, useState } from "react";
import styled from "styled-components";
import { jsPDF } from "jspdf";
import { Line } from "react-chartjs-2";
import { LinearProgress } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Colors } from "../../../theme/variants";

import {
  baseData,
  PHYSICAL_PROGRESS,
  FINANCIAL_PROGRESS,
  SERVICE_EVOLUTION,
} from "../pages/ServiceReportPage";

import indiceIcon from "../../../assets/images/indice-icon.png";
import curvedEvolutionIcon from "../../../assets/images/curve-evolution-section.png";

import { VerticalServiceReport } from "../../../services/interfaces/VerticalServiceReport";
import { DateFilterType } from "../../Actions/ViewActions/DateFilter";
import moment from "moment";

const pdfA4Metrics = {
  pagePadding: 15,
  maxWidth: 440,
};

type ServiceReportPdfProps = {
  chartData: typeof baseData;
  reportData: VerticalServiceReport | undefined;
  filterItems: { name: string; checked: boolean }[];
  reportDates?: DateFilterType;
};

const ServiceReportExportToPdf = ({
  chartData,
  filterItems,
  reportData,
  reportDates
}: ServiceReportPdfProps) => {
  const [generating, setGenerating] = useState(false);
  const pdfRefPage1 = useRef(null);
  const pdfRefPage2 = useRef(null);
  const pdfRefPage3 = useRef(null);

  const reportDatesFormatted = reportDates ?`- (período: ${moment(reportDates?.startDate).format('DD/MM/YY')} a ${moment(reportDates?.deadlineDate).format('DD/MM/YY')})` : null;

  const parsedChartData = {
    ...chartData,
    datasets: [
      ...chartData.datasets.map((dataset) => ({
        ...dataset,
        pointRadius: 3,
        borderWidth: 1,
      })),
    ],
  };

  const isPhysicalProgressAndFinancialProgressNotChecked =
    !filterItems.find((item) => item.name === PHYSICAL_PROGRESS)?.checked &&
    !filterItems.find((item) => item.name === FINANCIAL_PROGRESS)?.checked;

  const shareOnClick = () => {
    setGenerating(true);

    const inputPage1 = pdfRefPage1.current;
    const inputPage2 = pdfRefPage2.current;
    const inputPage3 = pdfRefPage3.current;

    if (!inputPage1) return;
    if (!inputPage2) return;
    if (!inputPage3) return;

    const reportFormatedDates = reportDates ? `_${moment(reportDates?.startDate).format('DD-MM-YY')}_ate_${moment(reportDates?.deadlineDate).format('DD-MM-YY')}` : ''
    const reportFileName = `relatorio-de-servicos_${reportData?.construction.name}${reportFormatedDates}.pdf`;

    const doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: "a4",
      putOnlyUsedFonts: true,
    });

    const addImagesRecursively = (
      doc: any,
      imageContent: any,
      remainingPageHeight: number,
      i = 0
    ) => {
      if (i === imageContent.children.length) {
        setGenerating(false);
        doc.save(reportFileName);
      } else if (imageContent.children[i].clientHeight <= remainingPageHeight) {
        remainingPageHeight =
          remainingPageHeight - imageContent.children[i].clientHeight;

        doc.html(imageContent.children[i] as any, {
          y:
            doc.internal.pageSize.height * (doc.internal.pages.length - 2) + 15,
          callback: function (updatedDoc: any) {
            addImagesRecursively(
              updatedDoc,
              imageContent,
              remainingPageHeight,
              i + 1
            );
          },
        });
      } else {
        if (i > 0) doc.addPage("a4", "portrait");
        remainingPageHeight =
          doc.internal.pageSize.height - imageContent.children[i].clientHeight;

        doc.html(imageContent.children[i] as any, {
          y:
            doc.internal.pageSize.height * (doc.internal.pages.length - 2) + 15,
          callback: function (updatedDoc: any) {
            addImagesRecursively(
              updatedDoc,
              imageContent,
              remainingPageHeight,
              i + 1
            );
          },
        });
      }
    };

    doc.html(inputPage1, {
      margin: [15, 0, 15, 0],
      autoPaging: "text",
      callback: function (doc) {
        if (!isPhysicalProgressAndFinancialProgressNotChecked) {
          doc.addPage("a4", "portrait");
        }
        doc.html(inputPage2, {
          autoPaging: "text",
          y:
            doc.internal.pageSize.height * (doc.internal.pages.length - 2) + 15,
          callback: function (doc) {
            let pageHeight = doc.internal.pageSize.height;
            doc.addPage("a4", "portrait");
            const imageContent = inputPage3 as HTMLElement;
            addImagesRecursively(doc, imageContent, pageHeight);
          },
        });
      },
    });
  };

  const renderLinearProgress = (
    groupName: string,
    progressAsPercent: number
  ) => (
    <FlexContainerAlignCenter>
      <GroupName>{groupName.replace("/", " / ")}</GroupName>
      <LinearProgress
        sx={{
          backgroundColor: "#DDD",
          "& .MuiLinearProgress-bar": {
            backgroundColor: "#EBA742",
          },
          height: "6px",
          borderRadius: "3px",
          marginTop: "1px",
          width: `${
            pdfA4Metrics.maxWidth - pdfA4Metrics.pagePadding * 2 - 85
          }px`,
        }}
        variant="determinate"
        value={
          progressAsPercent === null
            ? 0
            : progressAsPercent > 100
            ? 100
            : Number.parseFloat(progressAsPercent.toFixed(2))
        }
      />
      <GroupPercentage>
        {progressAsPercent
          ? Number.parseFloat(progressAsPercent.toFixed(2))
          : 0}
        %
      </GroupPercentage>
    </FlexContainerAlignCenter>
  );

  const pdfModel = (
    <Container>
      <Page ref={pdfRefPage1}>
        <PageTitle>Relatório de serviços {reportDatesFormatted}</PageTitle>
        <FlexContainer>
          {!reportData?.construction.imageUrl ? (
            <NoLogo>
              <span>Logo</span>
            </NoLogo>
          ) : (
            <Logo
              src={reportData?.construction.imageUrl}
              crossOrigin="anonymous"
              alt="Logo da empresa"
            />
          )}
          <PageHeaderInfo>
            <FlexContainerColumn>
              <ConstructionName>
                {reportData?.construction.name}
              </ConstructionName>
              <ResponsibleName>
                Responsável: {reportData?.responsibleNames}
              </ResponsibleName>
            </FlexContainerColumn>
          </PageHeaderInfo>
        </FlexContainer>
        <div>
          {!isPhysicalProgressAndFinancialProgressNotChecked && (
            <FlexContainerAlignCenter>
              <SectionIcon src={indiceIcon} />
              <SectionTitle>Índice de avanços</SectionTitle>
            </FlexContainerAlignCenter>
          )}
          {filterItems.find((item) => item.name === PHYSICAL_PROGRESS)
            ?.checked === true && (
            <div>
              <SectionSubTitle>Avanços físicos</SectionSubTitle>
              {reportData?.physicalProgress.map((indice) =>
                renderLinearProgress(indice.groupName, indice.progressAsPercent)
              )}
            </div>
          )}
        </div>
      </Page>
      <Page ref={pdfRefPage2}>
        {filterItems.find((item) => item.name === SERVICE_EVOLUTION)
          ?.checked === true && (
          <div>
            <FlexContainerAlignCenter>
              <SectionIcon src={curvedEvolutionIcon} />
              <SectionTitle>Evolução dos serviços</SectionTitle>
            </FlexContainerAlignCenter>
            <GraphContainer>
              <div>
                <Line
                  options={{
                    plugins: {
                      title: { display: false },
                      legend: { display: false },
                    },
                  }}
                  plugins={[
                    {
                      id: "whiteBackground",
                      beforeDraw: function (chartInstance) {
                        let ctx = chartInstance.ctx;
                        ctx.fillStyle = "white";
                        ctx.fillRect(
                          0,
                          0,
                          chartInstance.width,
                          chartInstance.height
                        );
                      },
                    },
                  ]}
                  data={parsedChartData}
                />
                <FlexContainerJustifyCenter>
                  <GraphLabelContainer>
                    <GraphLabelIconPrimary />
                    <GraphLabelText>{"Físico Real Acum"}</GraphLabelText>
                  </GraphLabelContainer>
                  <GraphLabelContainer>
                    <GraphLabelIconSecondary />
                    <GraphLabelText>{"Físico Planejado Acum"}</GraphLabelText>
                  </GraphLabelContainer>
                </FlexContainerJustifyCenter>
              </div>
            </GraphContainer>
          </div>
        )}
      </Page>
      <Page ref={pdfRefPage3}>
        {reportData?.images.map((serviceGroup) => {
          if (serviceGroup.imageUrl.length > 0) {
            // Group images by groups of 3
            // const images = serviceGroup.imageUrl;
            // images.reduce((acc, image, index) => {
            //   if (index % 3 === 0) {
            //     acc.push([image]);
            //   } else {
            //     acc[acc.length - 1].push(image);
            //   }
            //   return acc;
            // }, [] as Array<Array<string>>)

            return (
              serviceGroup.imageUrl.length > 0 && (
                <Page>
                  <SectionTitle>{serviceGroup.name}</SectionTitle>
                  <PicturesContainer>
                    {serviceGroup.imageUrl.map((image, i) => (
                      <>
                        {i > 0 && i % 9 === 0 && <PicturesPageGap />}
                        <Picture
                          crossOrigin="anonymous"
                          src={image}
                          alt="Imagem do serviço"
                        />
                      </>
                    ))}
                  </PicturesContainer>
                </Page>
              )
            );
          }
        })}
      </Page>
    </Container>
  );

  return (
    <>
      {pdfModel}
      <LoadingButton
        variant="outlined"
        loadingPosition={"start"}
        loading={generating}
        disabled={generating}
        style={{
          color: "#fff",
          backgroundColor: Colors.primaryColor,
          height: "40px",
          borderRadius: "20px",
        }}
        startIcon={null}
        onClick={() => shareOnClick()}
      >
        Exportar PDF
      </LoadingButton>
    </>
  );
};

const Container = styled.div`
  position: absolute;
  left: -9999px;
  top: -9999px;
  //position: relative;
  //left: -599px;
  //top: 0;
`;

const Page = styled.div`
  padding: 0 ${pdfA4Metrics.pagePadding}px;
`;

const PageTitle = styled.h1`
  font-size: 10px;
`;

const PageHeaderInfo = styled.div`
  padding: 1px 5px;
`;

const ConstructionName = styled.span`
  font-size: 8px;
  font-weight: bold;
`;

const ResponsibleName = styled.span`
  font-size: 6px;
  color: ${Colors.textColorGray};
`;

const FlexContainer = styled.div`
  display: flex;
`;

const FlexContainerAlignCenter = styled(FlexContainer)`
  align-items: center;
`;

const FlexContainerJustifyCenter = styled(FlexContainer)`
  justify-content: center;
`;

const FlexContainerColumn = styled(FlexContainer)`
  flex-direction: column;
`;

const NoLogo = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 6px;
  border: 0.1px solid ${Colors.neutralColorGray};
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 6px;
  border: 0.1px solid ${Colors.neutralColorGray};
`;

const SectionIcon = styled.img`
  width: 8px;
  height: 10px;
  margin-right: 2px;
  padding-top: 2px;
`;

const SectionTitle = styled.h1`
  font-size: 8px;
`;

const SectionSubTitle = styled.p`
  font-size: 6px;
  font-weight: bold;
`;

const GroupName = styled.p`
  font-size: 6px;
  width: 65px;
  word-wrap: break-word;
  padding-right: 5px;
`;

const GroupPercentage = styled.p`
  font-size: 6px;
  width: 30px;
  padding-left: 10px;
`;

const GraphContainer = styled.div`
  overflow-x: hidden;
  width: ${pdfA4Metrics.maxWidth - pdfA4Metrics.pagePadding * 2 - 20}px;
`;

const GraphLabelContainer = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
`;

const GraphLabelText = styled.p`
  word-wrap: break-word;
  margin-left: 4px;
  font-size: 8px;
`;

const GraphLabelIcon = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-right: 4px;
  padding-top: 3px;
`;

const GraphLabelIconPrimary = styled(GraphLabelIcon)`
  background-color: ${Colors.primaryColorLight};
`;

const GraphLabelIconSecondary = styled(GraphLabelIcon)`
  background-color: ${Colors.secondaryColorLight};
`;

const PicturesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: ${pdfA4Metrics.maxWidth}px;
`;

const PicturesPageGap = styled.div`
  width: ${pdfA4Metrics.maxWidth}px;
  height: 26px;
`;

const Picture = styled.img`
  width: 120px;
  height: 180px;
`;

export { ServiceReportExportToPdf };
