import styled from "styled-components";
import ReactBaseComponent from "../../../components/BaseComponents/ReactBaseComponent";
import { Colors } from "../../../theme/v2/variants";
import PopUpBaseComponent from "../../../components/PopUps/PopUpBaseComponent";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import VerticalServiceImage from "../../../services/interfaces/VerticalServiceImages";
import React from "react";
import LoadingComponent from "../../../components/StateComponents/LoadingComponent";
import Viewer from "react-viewer";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../../components/v2/Button/ButtonComponent";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

interface AddImagesPopUpProps {
  verticalServiceModel: any;
  images: VerticalServiceImage[];
  isOpen: boolean;
  isLoading: boolean;
  cancelOnClick: () => void;
  confirmOnClick: (images: any[]) => void;
  deleteImageOnClick: (image: VerticalServiceImage) => void;
}

const AddImagesPopUp: React.FC<AddImagesPopUpProps> = ({
  isOpen,
  isLoading,
  cancelOnClick,
  confirmOnClick,
  verticalServiceModel,
  images,
  deleteImageOnClick,
}) => {
  const [selectedImage, setSelectedImage] =
    React.useState<VerticalServiceImage>();

  const [isMobile, setIsMobile] = useState<boolean>();

  const handleResize = () => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    if (isMobile === undefined) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
  });

  const onDrop = (acceptedFiles: any, fileRejections: any, event: any) => {
    confirmOnClick(acceptedFiles);
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    maxFiles: 5,
  });

  function bytesToMb(bytes: number): string {
    return (bytes / (1024 * 1024)).toFixed(2);
  }

  const confirmOnClickCallback = () => {};

  const selectImage = (image: VerticalServiceImage) => {
    setSelectedImage(image);
  };

  const onPressDeleteIcon = (image: VerticalServiceImage) => {
    deleteImageOnClick(image);
  };

  return (
    <ReactBaseComponent>
      {!selectedImage ? (
        <PopUpBaseComponent
          isOpen={isOpen}
          cancelOnClick={() => {
            cancelOnClick();
          }}
          confirmOnClick={() => cancelOnClick()}
          closeOnClick={() => {
            cancelOnClick();
          }}
          titte="Subir imagem"
          isButtonContainerEnabled={true}
          width={683}
          enableConfirmButton={true}
        >
          <ContentContainer>
            {!isLoading ? (
              <>
                <DropArea {...getRootProps()}>
                  <DropAreaTitulo>
                    Arraste imagen aqui <br></br> ou
                  </DropAreaTitulo>
                  <InputFile type="file" id="fileInput" {...getInputProps()} />
                  <ButtonComponent
                    state={ButtonState.DEFAULT_ENABLED}
                    padding={{ left: 20, right: 20, top: 12, bottom: 12 }}
                    text={"Buscar Arquivo"}
                    startIcon={<SearchOutlinedIcon />}
                    onClick={() => {}}
                  />
                </DropArea>
              </>
            ) : null}
            {isLoading ? (
              <LoadingContainer>
                <LoadingComponent size={140} />
              </LoadingContainer>
            ) : null}
            <ImageList>
              {images.map((file) => (
                <FileItem key={file.id}>
                  <InsertDriveFileOutlinedIcon fontSize="small" />
                  <FileItemText onClick={() => selectImage(file)}>
                    {file.imageName}
                  </FileItemText>
                  <DeleteOutlineRoundedIcon
                    fontSize="small"
                    onClick={() => {
                      onPressDeleteIcon(file);
                    }}
                  />
                </FileItem>
              ))}
            </ImageList>
          </ContentContainer>
        </PopUpBaseComponent>
      ) : null}
      {selectedImage !== undefined ? (
        <Viewer
          disableKeyboardSupport={true}
          noImgDetails={true}
          noNavbar={true}
          noFooter={true}
          onClose={() => setSelectedImage(undefined)}
          visible={selectedImage !== undefined}
          images={[
            { src: selectedImage.imageUrl, alt: selectedImage.imageName },
          ]}
        />
      ) : null}
    </ReactBaseComponent>
  );
};

const FileItem = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  border: 1px solid #ecedf3;
  padding: 6px 8px;
  color: ${Colors.neutralGrayDark};
  border-radius: 8px;

  &:hover {
    background-color: ${Colors.greenLight};
    color: #FFF;
    box-shadow: 0px 7px 10px -7px rgba(0, 0, 0, 0.66);
    border: 1px solid ${Colors.jungleGreen};
  },
`;

const FileItemText = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  margin-left: 16px;
  margin-right: 16px;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  width: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 100%;
    max-height: 400px;
    width: 90%;
  }
`;

const DropArea = styled.div`
  background-color: ${Colors.alabster};
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px dashed ${Colors.mercury};
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 16px;
`;

const LoadingContainer = styled.div`
  background-color: ${Colors.alabster};
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px dashed ${Colors.mercury};
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 16px;
`;

const DropAreaTitulo = styled.div`
  font-family: "Noto Sans";
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${Colors.textColorBlack};
`;

const DropAreaSubTitulo = styled.div`
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${Colors.textColorGray};
`;

const InputFile = styled.input`
  height: 40px;
  background-color: ${Colors.primaryColor};

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 170px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 170px;
  }
`;

const ImageList = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 16px;
  align-content: center;
  justify-content: start;
`;

const ImageTagContainer = styled.div`
  display: grid;
  height: 36px;
  grid-template-columns: max-content auto min-content;
  padding-left: 8px;
  padding-right: 16px;
  align-items: center;
  border: 1px solid var(--color-neutral-light-gray, #ddd);
  border-radius: 36px;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 250px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 80px;
    max-width: 230px;
  }
`;

const ImageName = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;

  font-weight: 400;
  line-height: normal;
  color: ${Colors.textColorBlack};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 2px;
  margin-left: 4px;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 120px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 80px;
  }
`;

const ImageSize = styled.div`
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${Colors.textColorBlack};
`;

export default AddImagesPopUp;
