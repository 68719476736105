import { api } from "./api";
import VerticalServiceGroup from "./interfaces/VerticalServiceGroup";
import VerticalServiceModel from "./interfaces/VerticalServiceModel";
import uuid from "react-uuid";
import { VerticalServiceStatus } from "./utils/VerticalServiceStatus";
import VerticalServiceImageDto from "./interfaces/VerticalServiceImages";
import { UpdateServiceProgressModel } from "../pages/VerticalService/models/UpdateServiceProgressModel";
import {
  ProgressUpdateType,
  VerticalServiceProgressModel,
} from "./interfaces/VerticalServiceProgressModel";
import VerticalServiceType from "./interfaces/VerticalServiceType";
import { VerticalServiceReport } from "./interfaces/VerticalServiceReport";
import { VerticalServiceOrderOfStepEntity } from "./interfaces/VerticalServiceOrderOfStepEntity";

export default class VerticalService {
  async list() {
    const result = await api.get(`api/criteria/to-list`);
    return result.data;
  }

  async getTableContent(
    constructionId: string,
    beginDate?: Date,
    endDate?: Date,
    byTime?: string,
    responsibleUsers?: string,
    locations?: string,
    services?: string,
    semester = 1,
    filtered: boolean = false,
    showItemsSameData: boolean = false
  ): Promise<{
    content: VerticalServiceGroup[];
    timeList: any[];
    byTime: string;
    firstDay: string;
    lastDay: string;
    lastUpdate: string;
    lastUpdatedBy: string;
    constructionBeginningDate: string;
    constructionEndDate: string;
  }> {
    const result = await api.get(`/api/vertical-service/table-content`, {
      params: {
        constructionId: constructionId,
        beginDate: beginDate,
        endDate: endDate,
        byTime,
        semester,
        responsibleIds: responsibleUsers,
        locations: locations,
        serviceTypes: services,
        filtered,
        showItemsSameData,
      },
    });
    const content = result.data.content;

    content.forEach((group: VerticalServiceGroup) => {
      group.services.forEach((service: VerticalServiceModel) => {
        (service as any).gridItems.forEach((gridItem: any) => {
          if (gridItem.isEmpty === false) {
            gridItem.subItems.forEach((subItem: any) => {
              subItem.uniqueApplicationId = uuid();
              subItem.status = VerticalServiceStatus.filter(
                (status) => status.value === subItem.status
              )[0];
            });
          }
        });
      });
    });
    return {
      content: content,
      timeList: result.data.timePeriodList,
      byTime: result.data.byTime,
      firstDay: result.data.firstDay,
      lastDay: result.data.lastDay,
      lastUpdate: result.data.lastUpdate,
      lastUpdatedBy: result.data.lastUpdatedBy,
      constructionEndDate: result.data.constructionEndDate,
      constructionBeginningDate: result.data.constructionBeginningDate,
    };
  }

  async getServiceTypes(
    constructionId?: number,
    orderOfStepsNotRegistered = false,
    filterByRegisteredServices = false
  ): Promise<VerticalServiceGroup[]> {
    const result = await api.get(`/api/vertical-service/service-list`, {
      params: {
        constructionId,
        orderOfStepsNotRegistered,
        filterByRegisteredServices,
      },
    });
    return result.data;
  }

  async addService(service: VerticalServiceModel): Promise<{ id: number }> {
    const result = await api.post(`/api/vertical-service`, service);
    return result.data;
  }

  async updateService(
    service: VerticalServiceModel,
    id: string
  ): Promise<boolean> {
    const result = await api.put(`/api/vertical-service/${id}`, service);
    return result.data;
  }

  async deleteService(id: string): Promise<boolean> {
    const result = await api.delete(`/api/vertical-service/${id}`);
    return result.data;
  }

  async getLocations(): Promise<string[]> {
    const result = await api.get(`/api/vertical-service/locations`);
    return result.data;
  }

  async getLocationsByConstructionId(
    constructionId: number
  ): Promise<string[]> {
    const result = await api.get(
      `/api/vertical-service/${constructionId}/locations`
    );
    return result.data;
  }

  async uploadImages(
    images: File[],
    verticalServiceId: number
  ): Promise<boolean> {
    const formData = new FormData();

    if (!images) {
      return false;
    }

    images.forEach((image) => {
      formData.append(image.name, image);
    });

    const params: any = {};

    try {
      const response = await api.post(
        `/api/vertical-service/${verticalServiceId}/upload-image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error: any) {
      throw error;
    }
  }

  async getImages(
    verticalServiceId: number
  ): Promise<VerticalServiceImageDto[]> {
    const result = await api.get(
      `/api/vertical-service/${verticalServiceId}/images`
    );
    return result.data as VerticalServiceImageDto[];
  }

  async createNewerviceProgress(
    updateServiceProgressModel: UpdateServiceProgressModel,
    verticalServiceId: number
  ) {
    const result = await api.post(
      `api/vertical-service/${verticalServiceId}/update-service-progress`,
      updateServiceProgressModel
    );
    return result.data as VerticalServiceProgressModel;
  }

  async updateServiceProgress(
    verticalServiceId: number,
    updateServiceProgressId: number,
    newProgress: number,
    updateType: ProgressUpdateType
  ) {
    await api.put(
      `api/vertical-service/${verticalServiceId}/service-progress-update/${updateServiceProgressId}`,
      {
        value: newProgress,
        updateType: updateType
      }
    );
  }

  async getServiceProgressByConstructionId(
    constructionId: number
  ): Promise<VerticalServiceProgressModel> {
    const result = await api.get(
      `api/vertical-service/${constructionId}/service-progress`
    );

    return result.data as VerticalServiceProgressModel;
  }

  async getServiceProgressByServiceId(
    constructionId: number,
    serviceId: number
  ): Promise<VerticalServiceProgressModel> {
    const result = await api.get(
      `api/vertical-service/${constructionId}/service-progress/${serviceId}`
    );

    return result.data as VerticalServiceProgressModel;
  }

  async getReportDataByConstructionId(
    constructionId: string,
    beginDate: Date | unknown,
    endDate: Date | unknown
  ): Promise<VerticalServiceReport> {
    const result = await api.get(
      `api/vertical-service/${constructionId}/report`,
      { params: { beginDate, endDate } }
    );

    return result.data;
  }

  async createCustomServiceType(
    constructionId: number,
    name: string,
    serviceGroupId: number
  ): Promise<VerticalServiceType> {
    const result = await api.post(
      `api/vertical-service/${constructionId}/custom-service-type`,
      { name, serviceGroupId }
    );

    return result.data;
  }

  async deleteCustomServiceType(constructionId: number, serviceTypeId: string) {
    const result = await api.delete(
      `api/vertical-service/${constructionId}/custom-service-type/${serviceTypeId}`
    );

    return result.data;
  }

  async getExcelReport(constructionId: number) {
    const result = await api.get(
      `api/vertical-service/${constructionId}/export-excel`
    );

    return result.data;
  }

  async uploadExcelFile(constructionId: number, file: File) {
    const formData = new FormData();
    formData.append("file", file);

    const result = await api.post(
      `api/vertical-service/${constructionId}/import-xlsx`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return result.data;
  }

  async exportPDF(
    constructionId: string,
    beginDate?: Date,
    endDate?: Date,
    byTime?: string,
    responsibleUsers?: string,
    locations?: string,
    services?: string,
    semester = 1,
    filtered: boolean = false
  ): Promise<any> {
    const result = await api.get(
      `api/vertical-service/${constructionId}/export-pdf`,
      {
        params: {
          constructionId: constructionId,
          beginDate: beginDate,
          endDate: endDate,
          byTime,
          semester,
          responsibleIds: responsibleUsers,
          locations: locations,
          serviceTypes: services,
          filtered,
        },
      }
    );

    return result.data;
  }

  async deleteImage(serviceId: number, imageId: number) {
    const result = await api.delete(
      `api/vertical-service/${serviceId}/image/${imageId}`
    );

    return result.data;
  }

  async createOrderOfSteps(
    constructionId: number,
    name: string,
    steps: { groupId: number; position: number }[]
  ) {
    const result = await api.post(
      `api/vertical-service/${constructionId}/order-of-steps`,
      { name, steps }
    );

    return result.data;
  }

  async updateOrderOfSteps(
    constructionId: number,
    orderOfStepsId: number,
    name: string,
    steps: { groupId: number; position: number }[]
  ) {
    const result = await api.put(
      `api/vertical-service/${constructionId}/order-of-steps/${orderOfStepsId}`,
      { name, steps }
    );

    return result.data;
  }

  async getOrderOfSteps(
    constructionId: number
  ): Promise<VerticalServiceOrderOfStepEntity[]> {
    const result = await api.get(
      `api/vertical-service/${constructionId}/order-of-steps`
    );

    return result.data;
  }

  async deleteOrderOfSteps(constructionId: number, orderOfStepsId: number) {
    const result = await api.delete(
      `api/vertical-service/${constructionId}/order-of-steps/${orderOfStepsId}`
    );

    return result.data;
  }

  async updateServiceDatesByServiceTypes(
    constructionId: number,
    chagedServiceId: number,
    serviceTypeIds: number[],
    oldStartDate: Date,
    newStartDate: Date,
    oldEndDate: Date,
    newEndDate: Date
  ) {
    const result = await api.put(
      `api/vertical-service/${constructionId}/${chagedServiceId}/update-service-dates-by-service-type`,
      { serviceTypeIds, oldStartDate, newStartDate, oldEndDate, newEndDate }
    );

    return result.data;
  }

  async checkServiceOverlap(
    constructionId: number,
    serviceId: number
  ): Promise<{ overlap: boolean }> {
    const result = await api.get(
      `api/vertical-service/${constructionId}/${serviceId}/check-service-overlap`
    );

    return result.data;
  }

  async findVerticalServicesByFilters(
    constructionId: number,
    verticalServiceTypeId?: number,
    verticalServiceGroupId?: number
  ): Promise<VerticalServiceModel[]> {
    const result = await api.get(
      `api/vertical-service/${constructionId}/vertical-services`,
      {
        params: {
          serviceTypeId: verticalServiceTypeId,
          serviceGroupId: verticalServiceGroupId,
        },
      }
    );

    return result.data as VerticalServiceModel[];
  }

  async getVerticalServiceById(
    constructionId: number,
    verticalServiceId: number
  ): Promise<VerticalServiceModel> {
    const result = await api.get(`api/vertical-service/${verticalServiceId}`);
    return result.data;
  }

  async deleteUpdateProgress(updateProgressId: number): Promise<void> {
    try {
      const response = await api.delete(
        `api/vertical-service/delete-progress/${updateProgressId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting update progress:", error);
      throw error;
    }
  }
}
