import { useEffect, useState } from "react";
import { useDashboard } from "../../contexts/dashboard";
import { BaseState } from "../Actions/ViewActions/utils/BaseState";
import { useQuery } from "react-query";
import EmptyClientAndConstructionState from "../../components/StateComponents/EmptyClientAndConstruction";
import ReactPageBaseComponent from "../../components/BaseComponents/ReactPageBaseComponent";
import LoadingComponent from "../../components/StateComponents/LoadingComponent";
import ErrorComponent from "../../components/StateComponents/ErrorComponent";
import Title from "../../components/v2/Typography/Tittle";
import ButtonComponent, {
  ButtonType,
} from "../../components/v2/Button/ButtonComponent";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";
import {
  CenterContainer,
  ContentContainer,
  CustomTab,
  Description,
  EmptyListContainer,
  ListContainer,
  ListPurchasingContractionContainer,
  MobileMainButtonContainer,
  PageContainer,
  TabContainer,
  TitleContainer,
} from "./PurchasingContractingPlanPage.styles";
import { ReactComponent as Arow } from "../../assets/arow-white-down.svg";
import { Snackbar, Tabs } from "@mui/material";
import PurchasingAndContractingListHeader, {
  ListTypeEnum,
} from "./components/PurchasingAndContractingListHeader/PurchasingAndContractingListHeader";
import PurchasingModel, {
  parsePurchasingEntityToModel,
} from "./models/PurchasingModel";
import PurchasingAndContractingListItem from "./components/PurchasingAndContractingListItem/PurchasingAndContractingListItem";
import {
  PurchasingContractingENUM,
  PurchasingContractingENUM as TabsEnum,
} from "./models/PurchasingContractingENUM";
import { useNavigate } from "react-router-dom";
import {
  PURCHASING_CONTRACTING_PLAN_FORM_PATH,
  PURCHASING_CONTRACTING_PLAN_ORDER_DETAIL_PATH
} from "../../config/app-info";
import PurchasingContractingPlanService from "../../services/purchasingContractingPlan/PurchasingContractingPlanService";
import { PurchasingPlanEntity } from "../../services/purchasingContractingPlan/entity/PurchasingPlanEntity";
import ConfirmDeleteItemPopUp from "../../components/Dialogs/ConfirmDeleteItemPopUp";
import ContractingModel, {
  parseContractingEntityToModel,
} from "./models/ContractingMode";
import { ContractingPlanEntity } from "../../services/purchasingContractingPlan/entity/ContractingPlanEntity";
import ConstructionService from "../../services/ConstructionService";

import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Divider from "../../components/v2/Divider/Divider";
import Footer from "../../components/v2/Footer";
import { Colors } from "../../theme/v2/variants";

interface OrderOfStepsProps {}

const PurchasingContractingPlanPage: React.FC<OrderOfStepsProps> = () => {
  const navigation = useNavigate();
  const purchasingContractinService = new PurchasingContractingPlanService();
  const constructionService = new ConstructionService();
  const {
    constructionId,
    constructions,
    clientId,
    clients,
    setConstructionId,
  } = useDashboard();
  const [state, setState] = useState<BaseState>(BaseState.success);

  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>();
  const [selectedTab, setSelectedTab] = useState(TabsEnum.PURCHASING);

  const [selectedConstructionName, setSelectedConstructionName] = useState("");

  const [selectedPurchaseContractingPlan, setSelectedPurchaseContractingPlan] =
    useState<PurchasingModel | ContractingModel | undefined>(undefined);

  const [openDeleteActionDialog, setOpenDeleteActionDialog] = useState(false);
  const [isDeleteActionLoadingDialog, setIsDeleteActionLoadingDialog] =
    useState(false);

  const parsePurchasingListEntityToModel = (
    purchasing: PurchasingPlanEntity[]
  ): PurchasingModel[] => {
    return purchasing.map((item) => {
      return parsePurchasingEntityToModel(item);
    }) as PurchasingModel[];
  };

  const parseContractingListEntityToModel = (
    contracting: ContractingPlanEntity[]
  ): ContractingModel[] => {
    const result = contracting.map((item) => {
      return parseContractingEntityToModel(item);
    }) as ContractingModel[];
    return result;
  };

  const {
    data: contractingData,
    isLoading: isContractingLoading,
    isSuccess: isContractingSuccess,
    isError: isContracting,
    refetch: refetchContractingData,
  } = useQuery<ContractingModel[]>(
    ["todos-contractingList"],
    async () =>
      parseContractingListEntityToModel(
        await purchasingContractinService.getContractingPlanList(constructionId)
      ),
    {
      enabled: !isConstructionNotSelected(),
    }
  );

  const [page, setPage] = useState(0);

  const { data, isLoading, isSuccess, isError, refetch } = useQuery<
    PurchasingModel[]
  >(
    ["todos"],
    async () =>
      parsePurchasingListEntityToModel(
        await purchasingContractinService.getPurchasingPlanList(constructionId)
      ),
    {
      enabled: !isConstructionNotSelected(),
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );

  function isConstructionNotSelected() {
    return (
      constructionId === null ||
      constructionId === undefined ||
      constructionId === 0
    );
  }

  const handleResize = () => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const loadConstructionName = async (constructionId: number) => {
    const constructionInfo = await constructionService.findById(constructionId);

    setSelectedConstructionName(constructionInfo?.name);
  };

  useEffect(() => {
    setTimeout(() => {
      refetch();
      refetchContractingData();
    }, 100)
  }, [constructionId]);

  // create an event listener
  useEffect(() => {
    if (isMobile === undefined) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
  });

  const handleOnTabChange = (value: TabsEnum) => {
    setSelectedTab(value);
  };

  const newPlanOnClick = () => {
    navigation(PURCHASING_CONTRACTING_PLAN_FORM_PATH);
  };

  const editPlanOnClick = (id: number) => {
    navigation(
      `${PURCHASING_CONTRACTING_PLAN_FORM_PATH}?id=${id}&type=${selectedTab}`
    );
  };

  const goToPlan = (id: number) => {
    navigation(
      `${PURCHASING_CONTRACTING_PLAN_ORDER_DETAIL_PATH}?id=${id}&type=${selectedTab}`
    );
  }

  const openDeleteActionDialogOnClick = (id: number, type: PurchasingContractingENUM) => {
    if (type === PurchasingContractingENUM.PURCHASING) {
      setSelectedPurchaseContractingPlan(
        data?.find((item) => item.id === id) || undefined
      );
    } else {
      setSelectedPurchaseContractingPlan(
        contractingData?.find((item) => item.id === id) || undefined
      );
    }

    setOpenDeleteActionDialog(true);
  };

  const deleteActionialogButtonCancelOnClick = () => {
    setOpenDeleteActionDialog(false);
  };

  const deleteActionialogButtonConfirmOnClick = () => {
    setIsDeleteActionLoadingDialog(true);

    try {
      if (selectedTab === TabsEnum.PURCHASING) {
        purchasingContractinService.deletePurchasingPlan(
          selectedPurchaseContractingPlan?.id || 0
        );
      }

      if (selectedTab === TabsEnum.CONTRACTING) {
        purchasingContractinService.deleteContractingPlan(
          selectedPurchaseContractingPlan?.id || 0
        );
      }

      setOpenDeleteActionDialog(false);
      setIsDeleteActionLoadingDialog(false);
      setSelectedPurchaseContractingPlan(undefined);
      refetch();
      refetchContractingData();
      setSnackbarMessage("Plano excluído com sucesso");
      setOpenSnackbar(true);
    } catch (error) {
      setIsDeleteActionLoadingDialog(false);
      setOpenDeleteActionDialog(false);
      setSnackbarMessage("Error ao excluir plano");
      setOpenSnackbar(true);
    }
  };

  const returnPhaseCallback = (id: number, type: PurchasingContractingENUM) => {
    if (type === PurchasingContractingENUM.PURCHASING) {
      purchasingContractinService.returnPurchasingPhase(id).then(() => {
        refetch();
        setSnackbarMessage("Fase retornada");
        setOpenSnackbar(true);
      });
    }
    if (type === PurchasingContractingENUM.CONTRACTING) {
      purchasingContractinService.returnContractingPhase(id).then(() => {
        refetchContractingData();
        setSnackbarMessage("Fase retornada");
        setOpenSnackbar(true);
      });
    }
  };

  const initAndCompleteNegotiationCallback = (
    id: number,
    isPhaseInitiated: boolean,
    type: PurchasingContractingENUM
  ) => {
    if (type === PurchasingContractingENUM.PURCHASING) {
      purchasingContractinService
        .updateStatusPurchasing(id)
        .then(() => {
          refetch();
          setSnackbarMessage(
            isPhaseInitiated ? "Fase finalizada" : "Fase iniciada"
          );
          setOpenSnackbar(true);
        })
        .catch(() => {
          setSnackbarMessage("Erro ao iniciar ou finalizar a negociação");
          setOpenSnackbar(true);
        });
    }

    if (type === PurchasingContractingENUM.CONTRACTING) {
      purchasingContractinService
        .updateStatusContracting(id)
        .then(() => {
          refetchContractingData();
          setSnackbarMessage(
            isPhaseInitiated ? "Fase finalizada" : "Fase iniciada"
          );
          setOpenSnackbar(true);
        })
        .catch(() => {
          setSnackbarMessage("Erro ao iniciar ou finalizar a negociação");
          setOpenSnackbar(true);
        });
    }
  };

  const exportPdfOnClick = () => {
    if (selectedTab === TabsEnum.PURCHASING) {
      purchasingContractinService
        .purchasingExportPdf(constructionId)
        .then((pdf: any) => {
          sendPDFExportToUser(pdf);
          setSnackbarMessage("PDF Exportado com sucesso");
          setOpenSnackbar(true);
        })
        .catch(() => {
          setSnackbarMessage("Erro ao exportar PDF");
          setOpenSnackbar(true);
        });
    }

    if (selectedTab === TabsEnum.CONTRACTING) {
      purchasingContractinService
        .contractingExportPdf(constructionId)
        .then((pdf: any) => {
          sendPDFExportToUser(pdf);
          setSnackbarMessage("PDF Exportado com sucesso");
          setOpenSnackbar(true);
        })
        .catch(() => {
          setSnackbarMessage("Erro ao exportar PDF");
          setOpenSnackbar(true);
        });
    }
  };

  const sendPDFExportToUser = async (pdf: string) => {
    loadConstructionName(constructionId);
    const linkSource = `data:application/vnd.ms-excel;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = `${
      "Plano de Compras e Contratação_" + selectedConstructionName
    }.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <ReactPageBaseComponent>
      <PageContainer>
        {isConstructionNotSelected() ? (
          <CenterContainer>
            <EmptyClientAndConstructionState pageDescription="o plano de compras e contratação" />{" "}
          </CenterContainer>
        ) : null}

        {state === BaseState.loading && !isConstructionNotSelected() ? (
          <CenterContainer>
            <LoadingComponent />
          </CenterContainer>
        ) : null}

        {state === BaseState.error ? (
          <CenterContainer>
            <ErrorComponent />
          </CenterContainer>
        ) : null}

        {state === BaseState.success && !isConstructionNotSelected() ? (
          <ContentContainer>
            <TitleContainer>
              <Title>PLANO DE COMPRAS E CONTRATAÇÕES</Title>
              {!isMobile && (
                <ButtonComponent
                  text="Exportar PDF"
                  type={ButtonType.OUTLINE}
                  onClick={() => exportPdfOnClick()}
                  startIcon={<PictureAsPdfOutlinedIcon />}
                  padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                />
              )}

              {!isMobile && (
                //Alterar cor do seguinte botão quando novo tema for aplicado
                <ButtonComponent
                  text="Novo"
                  type={ButtonType.PRIMARY}
                  onClick={() => newPlanOnClick()}
                  startIcon={<AddOutlinedIcon />}
                  // endIcon={<Arow />}
                  padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                />
              )}
            </TitleContainer>
            <Description>
              Gerencie seus planos de compras e contratações de acordo com o
              serviço.
            </Description>

            <MobileMainButtonContainer>
              {isMobile && (
                <ButtonComponent
                  text="Novo"
                  type={ButtonType.PRIMARY}
                  onClick={() => newPlanOnClick()}
                  endIcon={<Arow />}
                />
              )}
            </MobileMainButtonContainer>

            <TabContainer>
              <Tabs
                value={selectedTab}
                onChange={(event, value) => handleOnTabChange(value)}
                style={{ width: "100%" }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: Colors.primaryColorDark,
                    height: "4px",
                  },
                }}
              >
                <CustomTab value={TabsEnum.PURCHASING} label="Compras" />
                <CustomTab value={TabsEnum.CONTRACTING} label="Contratação" />
              </Tabs>
            </TabContainer>

            <Divider marginBottom="30px" />

            {selectedTab === TabsEnum.PURCHASING &&
              data !== undefined &&
              data?.length > 0 && (
                <ListPurchasingContractionContainer>
                  <PurchasingAndContractingListHeader
                    isMobile={isMobile}
                    type={
                      selectedTab === TabsEnum.PURCHASING
                        ? ListTypeEnum.PURCHASING
                        : ListTypeEnum.CONTRACTING
                    }
                  />
                  <ListContainer>
                    {data?.map((item, index) => (
                      <PurchasingAndContractingListItem
                        index={index}
                        key={item.id}
                        purchasingModel={item}
                        onDelete={(id, type) => openDeleteActionDialogOnClick(id, type)}
                        onEdit={(id) => editPlanOnClick(id)}
                        onClickItem={(id) => goToPlan(id)}
                        isMobile={isMobile}
                        initAndCompleteNegotiationCallback={(
                          id,
                          isPhaseInitiated,
                          type
                        ) =>
                          initAndCompleteNegotiationCallback(
                            id,
                            isPhaseInitiated,
                            type
                          )
                        }
                        returnPhaseCallback={(id, type) => {
                          returnPhaseCallback(id, type);
                        }}
                      />
                    ))}
                  </ListContainer>
                </ListPurchasingContractionContainer>
              )}

            {selectedTab === TabsEnum.PURCHASING && data?.length === 0 && (
              <EmptyListContainer>
                Você ainda não possui um plano de {"compras"}. Clique em ”Novo”
                para começar.
              </EmptyListContainer>
            )}

            {selectedTab === TabsEnum.CONTRACTING &&
              contractingData !== undefined &&
              contractingData?.length > 0 && (
                <ListPurchasingContractionContainer>
                  <PurchasingAndContractingListHeader
                    isMobile={isMobile}
                    type={ListTypeEnum.CONTRACTING}
                  />
                  <ListContainer>
                    {contractingData?.map((item, index) => (
                      <PurchasingAndContractingListItem
                        key={item.id}
                        index={index}
                        contractingModel={item}
                        onDelete={(id, type) => openDeleteActionDialogOnClick(id, type)}
                        onEdit={(id) => editPlanOnClick(id)}
                        onClickItem={(id) => goToPlan(id)}
                        isMobile={isMobile}
                        initAndCompleteNegotiationCallback={(
                          id,
                          isPhaseInitiated,
                          phase
                        ) =>
                          initAndCompleteNegotiationCallback(
                            id,
                            isPhaseInitiated,
                            phase
                          )
                        }
                        returnPhaseCallback={(id, type) => {
                          returnPhaseCallback(id, type);
                        }}
                      />
                    ))}
                  </ListContainer>
                </ListPurchasingContractionContainer>
              )}

            {selectedTab === TabsEnum.CONTRACTING &&
              contractingData?.length === 0 && (
                <EmptyListContainer>
                  Você ainda não possui um plano de {"contratação"}. Clique em
                  ”Novo” para começar.
                </EmptyListContainer>
              )}
          </ContentContainer>
        ) : null}

        <ConfirmDeleteItemPopUp
          title="Excluir"
          description="Deseja realmente excluir este plano de compra ou contratação?"
          openDeleteActionDialog={openDeleteActionDialog}
          isLoadingDialog={isDeleteActionLoadingDialog}
          dialogButtonCancelOnClick={deleteActionialogButtonCancelOnClick}
          dialogButtonConfirmOnClick={deleteActionialogButtonConfirmOnClick}
        />

        <Snackbar
          message={snackbarMessage}
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={() => {
            setOpenSnackbar(false);
          }}
        />

        <Footer></Footer>
      </PageContainer>
    </ReactPageBaseComponent>
  );
};

export default PurchasingContractingPlanPage;
