import styled from "styled-components";
import { Colors } from "../../../../theme/v2/variants";

const SuppliersFormContainer = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 3fr;
  gap: 18px;
`;

const SupplierTableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.borderColorGray};
`

export { SuppliersFormContainer, SupplierTableRow };
