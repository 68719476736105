import React, { forwardRef } from "react";
import styled from "styled-components/macro";
import { NavLink, NavLinkProps } from "react-router-dom";
import { rgba, darken } from "polished";

import {
  Chip,
  Collapse,
  ListItemProps,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const CustomRouterLink = forwardRef<any, NavLinkProps>((props, ref) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
));

type ItemType = {
  activeclassname?: string;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
  depth: number;
};

const Item = styled(ListItemButton)<ItemType>`
  display: flex;
  flex-direction: column;
  padding: 12px 0px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  color: ${(props) => props.theme.sidebar.color};
  font-size: 12px;
  border-left: 4px solid transparent;
  border-top: ${(props) =>
    props.to === "/sair/" ? "1px solid rgba(0, 0, 0, 0.12);" : "none"};
  background-color: ${(props) =>
    props.depth !== 0 ? "#f9f9f9" : "transparent"};
  border-bottom: ${(props) =>
    props.depth !== 0 ? "1px solid rgba(0, 0, 0, 0.12);" : "none"};
  transition: all 300ms ease;
  svg {
    font-size: 20px;
    width: 26px;
    height: 26px;
  }
  &:hover {
    background: #dfdfdf;
    color: #465586;
  }
  &.${(props) => props.activeclassname} {
    color: #465586;
    background: #dfdfdf;
    border-left: 4px solid #465586;
  }
`;

type TitleType = {
  depth: number;
};

const Title = styled(ListItemText)<TitleType>`
  margin-top: 5px;
  span {
    color: ${(props) =>
      rgba(
        props.theme.sidebar.color,
        props.depth && props.depth > 0 ? 0.7 : 1
      )};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;

const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

type SidebarNavListItemProps = ListItemProps & {
  className?: string;
  depth: number;
  href: string;
  icon: React.FC<any>;
  badge?: string;
  open?: boolean;
  title: string;
  onDrawerToggle?: () => void;
};

const SidebarNavListItem: React.FC<SidebarNavListItemProps> = (props) => {
  const {
    title,
    href,
    depth = 0,
    children,
    icon: Icon,
    badge,
    open: openProp = false,
    onDrawerToggle,
  } = props;

  const [open, setOpen] = React.useState(openProp);

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  if (children) {
    return (
      <React.Fragment>
        <Item depth={depth} onClick={handleToggle}>
          {Icon && <Icon />}
          <div
            style={{
              marginTop: "10px",
              textWrap: "wrap",
              width: "100%",
              textAlign: "center",
              padding: "0px 10px",
              lineHeight: "100%",
            }}
          >
            {title}
            {/* {badge && <Badge label={badge} />} */}
          </div>
          {/* {open ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}
        </Item>
        <Collapse in={open}>{children}</Collapse>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Item
        depth={depth}
        component={CustomRouterLink}
        to={href}
        activeclassname="active"
        onClick={() => (onDrawerToggle ? onDrawerToggle() : null)}
      >
        {Icon && <Icon />}
        <div
          style={{
            marginTop: "10px",
            textWrap: "wrap",
            width: "100%",
            textAlign: "center",
            padding: "0px 10px",
            lineHeight: "100%",
          }}
        >
          {title}
          {/* {badge && <Badge label={badge} />} */}
        </div>
      </Item>
    </React.Fragment>
  );
};

export default SidebarNavListItem;
