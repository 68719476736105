import React from "react";
import styled from "styled-components";
import TodayIcon from "@mui/icons-material/Today";
import { Colors } from "../../../theme/v2/variants";

interface DateCardProps {
  title: string;
  date?: Date | string;
  color?: string;
  fontColor?: string;
  shortFormat?: boolean;
  fontSize?: string;
}

const CardContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div<{ color?: string }>`
  margin-right: 8px;
  color: ${(props) => props.color || Colors.greenLight};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const Title = styled.div<{ color?: string }>`
  font-family: "Noto Sans";
  font-size: 12px;
  color: ${(props) => props.color || Colors.greenLight};
  font-weight: 500;
  align-self: flex-start;
`;

const DateText = styled.div<{ fontColor?: string, fontSize?: string }>`
  color: ${(props) => props.fontColor || Colors.primaryColorDark};
  font-family: "Noto Sans";
  font-size: ${(props) => props.fontSize || "16px"};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const parseDateFromString = (dateStr: string): Date | null => {
  const [day, month, year] = dateStr.split("/").map(Number);

  if (day && month && year) {
    return new Date(year, month - 1, day);
  }

  return null;
};

const formatDate = (date?: Date): string => {
  if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
    return "Data não disponível";
  }

  const day = date.getDate().toString().padStart(2, "0");
  const month = date
    .toLocaleString("pt-BR", { month: "short" })
    .replace(".", "");
  const year = date.getFullYear();

  return `${day} ${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`;
};

const formatDateDDMM = (date?: Date): string => {
  if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
    return "Data não disponível";
  }

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Os meses em JavaScript começam do zero

  return `${day}/${month}`;
};

const DateCard: React.FC<DateCardProps> = ({
  title,
  date,
  color,
  fontColor,
  shortFormat = false,
  fontSize,
}) => {
  return (
    <CardContainer>
      <IconWrapper color={color}>
        <TodayIcon />
      </IconWrapper>
      <TextContainer>
        <Title color={color}>{title}</Title>
        { date && (
          <DateText fontColor={fontColor}fontSize={fontSize}>
          {shortFormat
            ? formatDateDDMM(
                date instanceof Date
                  ? date
                  : parseDateFromString(date) ?? undefined
              )
            :date instanceof Date
              ? formatDate(date)
              : formatDate(parseDateFromString(date) ?? undefined)}
          </DateText>
        )}
      </TextContainer>
    </CardContainer>
  );
};

export default DateCard;
