import styled from "styled-components";
import { Colors } from "../../../theme/v2/variants";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import { Box } from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";

interface InfoRowProps {
  gap?: string;
}

interface InfoTextDarkBoldProps {
  fontSize?: string;
}

const PageContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: auto, auto;
  background-color: ${Colors.neutralColorSurface};
  overflow: visible;
  position: relative;
  align-content: start;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-y: auto;
`;

const HeaderContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  max-width: 100%;
  margin-top: 18px;
  padding-bottom: 16px;
  gap: 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 0px;
    margin-right: 16px;
  }
`;

const DialogTitle = styled.div`
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${Colors.primaryColorDark};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 24px;
  }
`;

const StatusSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
`;

const StatusContainer = styled.div`
  display: grid;
  background-color: white;
  border: 1px solid ${Colors.alto};
  gap: 16px;
  padding: 24px 24px 32px 24px;
  border-radius: 16px;
  background-color: ${Colors.textColorLight};
  overflow: auto;
`;

const DialogTitleButton = styled.div`
  display: flex;
    justify-content: space-between;
`

const ReprovedStatusContainer = styled.div`
  display: grid;
  background-color: white;
  border: 1px solid rgba(222, 152, 152, 0.25);
  gap: 16px;
  padding: 24px 24px 32px 24px;
  border-radius: 16px;
  background-color: ${Colors.pippin};
  overflow: auto;
  box-shadow: 0px 30px 30px -30px ${Colors.stormGray};
`;

const ContrastRow = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: space-between;
  gap: 44px;
  align-self: stretch;
  border-radius: 8px;
  background-color: ${Colors.athensGray};
`;

const InfoRow = styled.div<InfoRowProps>`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: center;
  gap: ${(props) => props.gap || "24px"};
`;

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const InfoTitle = styled.div`
  color: ${Colors.greenLight};
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const ReprovedInfoTitle = styled.div`
  color: ${Colors.alertRed};
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

const InfoText = styled.div`
  color: ${Colors.neutralGrayDark};
  text-align: start;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const InfoTextDarkBold = styled.div<InfoTextDarkBoldProps>`
  color: ${Colors.primaryColorDark};
  text-align: center;
  font-family: "Noto Sans";
  font-size: ${(props) => props.fontSize || "16px"};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const EditTotalValue = styled.span`
  vertical-align: text-bottom;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  margin-left: 10px;
`

const ReprovedInfoTextDarkBold = styled.div<InfoTextDarkBoldProps>`
  color: ${Colors.silver};
  text-align: center;
  font-family: "Noto Sans";
  font-size: ${(props) => props.fontSize || "16px"};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const InfoTextBold = styled.div`
  color: ${Colors.neutralGrayDark};
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const BudgetSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  overflow-x: scroll;
`;

const HeaderBudgetContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  border-bottom: 1px solid ${Colors.gallery};
  transition: 0.3s;
`;

const AprovHeaderBudgetContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${Colors.gallery};
  transition: 0.3s;
`;

const ReprovedHeaderBudgetContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(222, 152, 152, 0.25);
  transition: 0.3s;
`;

const BudgetContainer = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  border: 1px solid ${Colors.gallery};
  transition: 0.3s;
  min-width: 368px;

  &:hover {
    box-shadow: 0px 30px 30px -30px ${Colors.baliHai};
    z-index: 99;
    ${HeaderBudgetContainer} {
      border-bottom: 1px solid ${Colors.gallery};
      background-color: ${Colors.gallery};
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
  }
`;

const ReprovedBudgetContainer = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  border: 1px solid ${Colors.gallery};
  transition: 0.3s;
  min-width: 368px;
`;

const SingleReprovedBudgetContainer = styled.div`
  width: 100%;
  background-color: ${Colors.pippin};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  border: 1px solid rgba(222, 152, 152, 0.25);
  transition: 0.3s;
`;

const BudgetInfoRow = styled.div<InfoRowProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: ${(props) => props.gap || "24px"};
`;

const ButtonInfoRow = styled.div<InfoRowProps>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: ${(props) => props.gap || "16px"};
`;

const ReprovedButtonInfoRow = styled.div<InfoRowProps>`
  width: 100%;
  display: flex;
  gap: ${(props) => props.gap || "16px"};

  button {
    flex-grow: 1;
  }
`;

const ContentBudgetContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
`;

const ObservationText = styled.div`
  color: ${Colors.primaryColorDark};
  text-align: start;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const ReprovedObservationText = styled.div`
  color: ${Colors.silver};
  text-align: start;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const StatusText = styled.div`
  color: ${Colors.greenLight};
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const IconInfoContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: ${Colors.greenLight};
`;

const ReprovedIconInfoContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: ${Colors.alertRed};
`;

const ObsModalContent = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 50%;
  background-color: white;
  border-radius: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0px 43px;
  padding-top: 33px;
  padding-bottom: 35px;
`;

const ModalSubtitle = styled.div`
  color: ${Colors.comet};
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 19px;
`;

const ModalObsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 0px;
    margin-right: 16px;
  }
`;

const ObsModalTitle = styled.div`
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${Colors.primaryColorDark};
  margin-top: 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 24px;
  }
`;

const OrderFileItem = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  border: 1px solid #ecedf3;
  padding: 6px 8px;
  color: ${Colors.neutralGrayDark};
  background-color: white;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.greenLight};
    color: #FFF;
    box-shadow: 0px 7px 10px -7px rgba(0, 0, 0, 0.66);
    border: 1px solid ${Colors.jungleGreen};
  },
`;

export {
  ReprovedHeaderBudgetContainer,
  SingleReprovedBudgetContainer,
  OrderFileItem,
  AprovHeaderBudgetContainer,
  ReprovedBudgetContainer,
  ReprovedButtonInfoRow,
  ReprovedObservationText,
  ReprovedInfoTextDarkBold,
  ButtonInfoRow,
  ReprovedIconInfoContent,
  ReprovedInfoTitle,
  ReprovedStatusContainer,
  ObsModalTitle,
  ModalObsHeader,
  ModalSubtitle,
  ObsModalContent,
  IconInfoContent,
  StatusText,
  ObservationText,
  ContentBudgetContainer,
  HeaderBudgetContainer,
  BudgetInfoRow,
  BudgetContainer,
  BudgetSection,
  StatusSection,
  InfoContent,
  InfoRow,
  InfoTitle,
  InfoText,
  InfoTextBold,
  InfoTextDarkBold,
  ContrastRow,
  StatusContainer,
  PageContainer,
  ContentContainer,
  HeaderContainer,
  DialogTitle,
  EditTotalValue,
  DialogTitleButton
};
