import styled from "styled-components";
import ReactPageBaseComponent from "../../../components/BaseComponents/ReactPageBaseComponent";
import { Colors } from "../../../theme/variants";
import { useDashboard } from "../../../contexts/dashboard";
import React, { useEffect, useState } from "react";
import { BaseState } from "../../Actions/ViewActions/utils/BaseState";
import EmptyClientAndConstructionState from "../../../components/StateComponents/EmptyClientAndConstruction";
import LoadingComponent from "../../../components/StateComponents/LoadingComponent";
import ErrorComponent from "../../../components/StateComponents/ErrorComponent";
import { ReactComponent as CurveEvolutionIcon } from "../../../assets/curve-evolution-section.svg";
import { Button, Checkbox, Menu, MenuItem, Snackbar } from "@mui/material";
import { ReactComponent as IndiceIcon } from "../../../assets/indice-icon.svg";
import { ReactComponent as ImagesIcon } from "../../../assets/image-outline-yellow.svg";
import Title from "../../../components/Typography/Tittle";
import { useDropzone } from "react-dropzone";
import AdvancementIndexReportComponent from "../components/AdvancementIndexReportComponent";
import { Line } from "react-chartjs-2";
import Subtitle from "../../../components/charts/Subtitle/Subtitle";
import VerticalService from "../../../services/VeritcalService";
import ConstructionService from "../../../services/ConstructionService";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import { VerticalServiceReport } from "../../../services/interfaces/VerticalServiceReport";
import { ReactComponent as EyeIcon } from "../../../assets/eye-icon.svg";
import { ServiceReportExportToPdf } from "../components/ServiceReportExportToPdf";
import ButtonComponent, {
  ButtonType,
} from "../../../components/v2/Button/ButtonComponent";
import DateFilter, {
  DateFilterType,
} from "../../Actions/ViewActions/DateFilter";
import { ReactComponent as Article } from "../../../assets/article.svg";
import { useNavigate } from "react-router-dom";

interface Props {}

export const baseData = {
  labels: [],
  datasets: [
    {
      data: [],
      borderColor: Colors.primaryColorLight,
      backgroundColor: Colors.primaryColorLight,
      pointRadius: 6,
    },
    {
      data: [],
      borderColor: Colors.secondaryColor,
      backgroundColor: Colors.secondaryColor,
      pointRadius: 6,
    },
  ],
};

export const PHYSICAL_PROGRESS = "Avanços físicos";
export const FINANCIAL_PROGRESS = "Avanços financeiros";
export const SERVICE_EVOLUTION = "Evolução dos serviços";
export const IMAGES = "Imagens";

const ServiceReportPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const verticalServiceService = new VerticalService();
  const constructionService = new ConstructionService();
  const { constructionId } = useDashboard();
  const [state, setState] = useState<BaseState>(BaseState.success);
  const [reportData, setReportData] = useState<VerticalServiceReport>();
  const [chartData, setChartData] = useState(baseData);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>();
  const [filterItems, setFilterItems] = useState<
    { name: string; checked: boolean }[]
  >([
    {
      checked: true,
      name: PHYSICAL_PROGRESS,
    },
    {
      checked: true,
      name: FINANCIAL_PROGRESS,
    },
    {
      checked: true,
      name: SERVICE_EVOLUTION,
    },
    {
      checked: true,
      name: IMAGES,
    },
  ]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isFilterVisibleDataOpen = Boolean(anchorEl);
  const [selectedDates, setSelectedDates] = useState<DateFilterType>();
  const [appliedDates, setAppliedDates] = useState<DateFilterType>();
  const [openDateFilterDialog, setOpenDateFilterDialog] =
    useState<boolean>(false);

  const handleResize = () => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    if (isMobile === undefined) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
  });

  function isConstructionNotSelected() {
    return (
      constructionId === null ||
      constructionId === undefined ||
      constructionId === 0
    );
  }

  const findReportData = async (
    constructionId: string,
    beginDate?: Date | unknown,
    endDate?: Date | unknown
  ) => {
    if (!constructionId) {
      return;
    }
    setState(BaseState.loading);
    try {
      const response =
        await verticalServiceService.getReportDataByConstructionId(
          constructionId,
          beginDate,
          endDate
        );
      handlePageData(response);
    } catch (error) {
      setState(BaseState.error);
    }
  };

  useEffect(() => {
    if (!constructionId) {
      return;
    }
    findReportData(constructionId?.toString());
  }, [constructionId]);

  const onSelectDateFilter = (dates: DateFilterType) => {
    setSelectedDates(dates);
    setOpenDateFilterDialog(false);

    const beginDate = dates?.startDate.toISOString().slice(0, 10);
    const endDate = dates?.deadlineDate.toISOString().slice(0, 10);

    findReportData(constructionId?.toString(), beginDate, endDate);
    setAppliedDates(dates);
  };

  const onCanceledDateFilter = () => {
    setOpenDateFilterDialog(false);
  };

  const handlePageData = (data: VerticalServiceReport) => {
    setReportData(data);
    if (isMobile) {
      (baseData.labels as string[]) = data?.serviceProgressLabels.map(
        (date: any) => date.monthAsString.substring(0, 3)
      );
    } else {
      (baseData.labels as string[]) = data?.serviceProgressLabels.map(
        (date: any) => date.monthAsString
      );
    }

    (baseData.datasets[0].data as any[]) = [];
    (baseData.datasets[0].data as any[]).push(...data?.concludedServices);
    (baseData.datasets[1].data as any[]) = [];
    (baseData.datasets[1].data as any[]).push(...data?.unconcludedServices);
    setChartData(baseData);
    setState(BaseState.success);
  };

  const onDrop = (acceptedFiles: any, fileRejections: any, event: any) => {
    if (fileRejections.length > 0) {
      return;
    }
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append("file", file);
    constructionService
      .updateImage(constructionId?.toString(), formData)
      .then((response) => {
        if (!reportData?.construction) {
          return;
        }
        setReportData({
          ...reportData,
          construction: {
            ...reportData?.construction,
            imageUrl: response,
          },
        });
      })
      .catch((error) => {
        setSnackbarMessage("Erro ao atualizar a imagem");
        setOpenSnackbar(true);
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/svg+xml": [".svg"],
    },
    maxFiles: 1,
  });

  const options = {
    responsive: true,
    maintainAspectRatio: isMobile ? false : true,
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };

  const menuFilterOnClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenuFilter = () => {
    setAnchorEl(null);
  };

  const menuItemFilterOnClick = (name: string, checked: boolean) => {
    setFilterItems(
      filterItems.map((item) => {
        if (item.name === name) {
          return {
            ...item,
            checked: !item.checked,
          };
        }
        return item;
      })
    );
  };

  const isPhysicalProgressAndFinancialProgressNotChecked = (): boolean => {
    return (
      !filterItems.find((item) => item.name === PHYSICAL_PROGRESS)?.checked &&
      !filterItems.find((item) => item.name === FINANCIAL_PROGRESS)?.checked
    );
  };

  const goToVertical = () => {
    navigate(`/vertical-servicos`);
  };

  return (
    <ReactPageBaseComponent>
      <PageContainer>
        {isConstructionNotSelected() ? (
          <CenterContainer>
            <EmptyClientAndConstructionState pageDescription="o relatório de servicos" />{" "}
          </CenterContainer>
        ) : null}

        {state === BaseState.loading && !isConstructionNotSelected() ? (
          <CenterContainer>
            <LoadingComponent />
          </CenterContainer>
        ) : null}

        {state === BaseState.error ? (
          <CenterContainer>
            <ErrorComponent />
          </CenterContainer>
        ) : null}

        {state === BaseState.success && !isConstructionNotSelected() ? (
          <SectionsContainer>
            <HeaderSection>
              <Title>Relatório de serviços</Title>
              {!isMobile && (
                <ServiceReportExportToPdf
                  chartData={chartData}
                  reportData={reportData}
                  filterItems={filterItems}
                  reportDates={appliedDates}
                />
              )}
            </HeaderSection>
            <ProfileSection>
              {!reportData?.construction.imageUrl ? (
                <ProfileInput {...getRootProps()}>
                  <DropAreaText>Logo da empresa</DropAreaText>
                  <InputFile type="file" {...getInputProps()} />
                </ProfileInput>
              ) : (
                <ProfileInput>
                  <ProfileImage
                    src={reportData?.construction.imageUrl}
                    crossOrigin="anonymous"
                    alt="Logo da empresa"
                  />
                </ProfileInput>
              )}

              <NameAndResponsibleContainer>
                <ConstructionName>
                  {reportData?.construction.name}
                </ConstructionName>
                <ConstructionResponsible>
                  Responsável: {reportData?.responsibleNames}
                </ConstructionResponsible>
              </NameAndResponsibleContainer>
            </ProfileSection>
            <IndiceContainer>
              <IndiceTitleContainer>
                {!isPhysicalProgressAndFinancialProgressNotChecked() ? (
                  <SectionTitleContainer>
                    <IndiceIcon />
                    <SectionTitle>Índice de avanços</SectionTitle>
                  </SectionTitleContainer>
                ) : (
                  <div />
                )}
                <ButtonOptionsContainer>
                  <ButtonComponent
                    type={ButtonType.OUTLINE}
                    text={"Vertical de Serviços"}
                    startIcon={<Article />}
                    onClick={() => goToVertical()}
                    padding={{ top: 10, bottom: 10, left: 14, right: 20 }}
                  />
                  <ButtonComponent
                    type={ButtonType.OUTLINE_SELECTED}
                    startIcon={<Article />}
                    onClick={() => {}}
                    text={isMobile ? "" : "Relatório"}
                    padding={{ top: 10, bottom: 10, left: 14, right: 20 }}
                  />
                  <span style={{ marginRight: "10px" }}>
                    <ButtonComponent
                      text={
                        !selectedDates
                          ? "Filtrar por data"
                          : selectedDates?.startDate?.toLocaleDateString(
                              "pt-BR",
                              {
                                day: "2-digit",
                                month: "2-digit",
                              }
                            ) +
                            " à " +
                            selectedDates?.deadlineDate?.toLocaleDateString(
                              "pt-BR",
                              {
                                day: "2-digit",
                                month: "2-digit",
                              }
                            )
                      }
                      type={ButtonType.OUTLINE}
                      onClick={() => setOpenDateFilterDialog(true)}
                    />
                  </span>
                  <Button
                    startIcon={<EyeIcon />}
                    onClick={menuFilterOnClick}
                    style={{
                      color: Colors.primaryColor, // Change the font color to red
                      textAlign: "center",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "20px",
                      letterSpacing: 0.1,
                      textTransform: "none",
                      fontFamily: "Noto Sans",
                      borderRadius: "20px",
                      height: "40px",
                      border: "1px solid " + Colors.primaryColor,
                      paddingLeft: "14px",
                      paddingRight: "22px",
                    }}
                    aria-controls={
                      isFilterVisibleDataOpen ? "basic-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={isFilterVisibleDataOpen ? "true" : undefined}
                  >
                    Mostrar / Ocultar
                  </Button>
                </ButtonOptionsContainer>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={isFilterVisibleDataOpen}
                  onClose={closeMenuFilter}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {filterItems.map((item, index) => {
                    return (
                      <MenuItem
                        key={item.name}
                        onClick={() =>
                          menuItemFilterOnClick(item.name, item.checked)
                        }
                      >
                        <MenuItemFilterContainer>
                          <Checkbox
                            checked={item.checked}
                            onClick={() =>
                              menuItemFilterOnClick(item.name, item.checked)
                            }
                          />
                          <MenuItemCheckboxText>
                            {item.name}
                          </MenuItemCheckboxText>
                        </MenuItemFilterContainer>
                      </MenuItem>
                    );
                  })}
                </Menu>
              </IndiceTitleContainer>
              {filterItems.find((item) => item.name === PHYSICAL_PROGRESS)
                ?.checked === true && (
                <IndiceSubContainer>
                  <IndiceTitleAndDescriptionContainer>
                    <IndiceTitle>Avanços físicos</IndiceTitle>
                    <IndiceDescription></IndiceDescription>
                  </IndiceTitleAndDescriptionContainer>
                  {reportData?.physicalProgress.map((indice) => (
                    <AdvancementIndexReportComponent
                      title={indice.groupName}
                      value={indice.progressAsPercent}
                    />
                  ))}
                </IndiceSubContainer>
              )}
            </IndiceContainer>
            {filterItems.find((item) => item.name === SERVICE_EVOLUTION)
              ?.checked === true && (
              <ServiceEvoluctionSection>
                <SectionTitleContainer>
                  <CurveEvolutionIcon />
                  <SectionTitle>Evolução dos serviços</SectionTitle>
                </SectionTitleContainer>
                <ChartWhiteContainer>
                  <ChartContainer>
                    <Line redraw={true} options={options} data={chartData} />
                  </ChartContainer>
                  <SubtitleGroup>
                    <Subtitle
                      color={Colors.primaryColorLight}
                      text="Físico Real Acum"
                    />
                    <Subtitle
                      color={Colors.secondaryColor}
                      text="Físico Planejado Acum"
                    />
                  </SubtitleGroup>
                </ChartWhiteContainer>
              </ServiceEvoluctionSection>
            )}
            {reportData &&
            reportData?.images.filter(
              (serviceGroup) => serviceGroup.imageUrl.length > 0
            ).length > 0 &&
            filterItems.find((item) => item.name === IMAGES)?.checked ===
              true ? (
              <ImagesSection>
                <SectionTitleContainer>
                  <ImagesIcon />
                  <SectionTitle>Imagens anexas dos serviços</SectionTitle>
                </SectionTitleContainer>
                {reportData?.images.map((serviceGroup) =>
                  serviceGroup.imageUrl.length > 0 ? (
                    <ImageListItem>
                      <ImageListDescription>
                        {serviceGroup.name}
                      </ImageListDescription>
                      <ImageListContainer>
                        {serviceGroup.imageUrl.map((image) => (
                          <ServiceImage
                            crossOrigin="anonymous"
                            src={image}
                            alt="Imagem do serviço"
                          />
                        ))}
                      </ImageListContainer>
                    </ImageListItem>
                  ) : null
                )}
              </ImagesSection>
            ) : null}
          </SectionsContainer>
        ) : null}
      </PageContainer>
      <DateFilter
        openDialog={openDateFilterDialog}
        dates={selectedDates}
        onConfirmDialogCallback={onSelectDateFilter}
        onCancelDialogCallback={() => onCanceledDateFilter()}
        doNotChangeEndDateWhenChangeBeginDate
      />
      <Snackbar
        message={snackbarMessage}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      />
    </ReactPageBaseComponent>
  );
};

const ButtonOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const PageContainer = styled.div`
  display: grid;
  width: auto;
  height: auto;
  grid-template-columns: 1fr;
  grid-template-rows: auto, auto;
  background-color: ${Colors.neutralColorSurface};
  overflow: visible;
  position: relative;
  align-content: start;
`;

const CenterContainer = styled.div`
  display: grid;
  height: 85vh;
  align-content: center;
`;

const SectionsContainer = styled.div`
  display: grid;
  grid-template-rows: min-content min-content min-content min-content min-content;
  padding-bottom: 32px;
`;

const HeaderSection = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 153px;
  column-gap: 18px;
  padding-right: 32px;
  align-content: center;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 153px;
  }
`;

const SectionTitleContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: min-content max-content;
  column-gap: 18px;
  justify-content: start;
  align-items: center;
`;

const SectionTitle = styled.h1`
  font-family: "Noto Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${Colors.textColorBlack};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 16px;
  }
`;

const ProfileSection = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: min-content 1fr;
  padding-left: 32px;
  padding-right: 32px;
  column-gap: 16px;
  margin-top: 48px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 34px;
    grid-template-columns: min-content 1fr;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const ProfileInput = styled.div`
  display: grid;
  width: 155px;
  height: 141px;

  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: "#fff";
  border-radius: 16px;
  border: 1px solid ${Colors.primaryColorDark};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80px;
    height: 72px;
  }
`;

const ProfileImage = styled.img`
  width: 153px;
  height: 139px;
  border-radius: 16px;
  padding: 1px;
  overflow: hidden;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 78px;
    height: 70px;
  }
`;

const InputFile = styled.input`
  padding: 37.5px 25.5px;
  width: 155px;
  height: 141px;
`;

const DropAreaText = styled.div`
  color: ${Colors.primaryColor};
  text-align: center;
  font-family: "Noto Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 14px;
  }
`;

const NameAndResponsibleContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: min-content 1fr;
  row-gap: 4px;
`;

const ConstructionName = styled.div`
  color: ${Colors.textColorBlack};
  font-family: "Noto Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const ConstructionResponsible = styled.div`
  color: ${Colors.textColorGray};
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const ServiceEvoluctionSection = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: max-content max-content;
  row-gap: 20px;
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 44px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 34px;
  }
`;

const IndiceContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: 1fr min-content min-content;
  row-gap: 18px;
  margin-top: 32px;
  padding-left: 32px;
  padding-right: 32px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-left: 16px;
    padding-right: 8px;
  }
`;

const IndiceTitleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
`;

const IndiceSubContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: auto auto;
  row-gap: 18px;
`;

const IndiceTitleAndDescriptionContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 8px;
  align-items: center;
`;

const IndiceTitle = styled.div`
  color: ${Colors.textColorBlack};
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 14px;
  }
`;

const IndiceDescription = styled.div`
  color: ${Colors.primaryColorLight};
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const ChartWhiteContainer = styled.div`
  display: grid;
  max-width: 100%;
  height: 547px;
  grid-template-rows: 388px 56px;
  background-color: #fff;
  overflow-x: hidden;
  justify-items: center;
  border-radius: 8px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 540px;
  }
`;

const ChartContainer = styled.div`
  display: grid;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    height: 440px;
    width: 100%;
    margin-top: 32px;
    justify-items: center;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    max-height: fit-content;
    max-width: 100%;
    overflow: hidden;
    justify-items: center;
    margin-top: 16px;
  }
`;

const SubtitleGroup = styled.div`
  display: grid;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: min-content min-content;
    margin-top: 100px;
    column-gap: 32px;
    width: fit-content;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-rows: min-content min-content;
    margin-top: 34px;
    column-gap: 32px;
    width: fit-content;
  }
`;

const ImagesSection = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: min-content min-content min-content;
  padding-left: 32px;
  padding-right: 32px;
  column-gap: 24px;
  margin-top: 32px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 24px;
  }
`;

const ImageListItem = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: min-content min-content;
  row-gap: 24px;
`;

const ImageListContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(268px, max-content));
  grid-gap: 16px;
  align-content: start;
  justify-content: start;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(auto-fill, minmax(45%, max-content));
  }
`;

const ImageListDescription = styled.div`
  color: ${Colors.textColorBlack};
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const ServiceImage = styled.img`
  width: 268px;
  height: 311px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    height: 175px;
  }
`;

const MenuItemFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MenuItemCheckboxText = styled.div`
  color: ${Colors.textColorBlack};
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 8px;
`;

export default ServiceReportPage;
