import React, { useState, ChangeEvent, useEffect, useCallback } from "react";
import { Box, Modal, TextField, ToggleButtonGroup } from "@mui/material";
import ReactPageBaseComponent from "../../../components/BaseComponents/ReactPageBaseComponent";
import * as Styles from "./PurchasingContractingPlanOrderDetail.styles";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../../components/v2/Button/ButtonComponent";
import { ArrowBackSharp, Man, Edit } from "@mui/icons-material";
import { PURCHASING_CONTRACTING_PLAN_PATH } from "../../../config/app-info";
import { useNavigate, useSearchParams } from "react-router-dom";
import Title from "../../../components/v2/Typography/Tittle";
import Alert from "../../../components/Alert";

import {
  CloseButton,
  FileItem,
  FileItemText,
  ListFilesContainer,
  ModalButtonRow,
  ModalContent,
  ModalForm,
  ModalFormRow,
  ModalHeader,
  ModalTitle,
  ModalToggleButton,
} from "../Form/PurchasingAndContractingPlanForm.styles";
import { PurchasingContractingPlanFormType } from "../Form";
import DropDownComponent from "../../../components/InputValues/DropDownComponent";
import { useAuth } from "../../../contexts/auth";
import { modalSelectStyle } from "../../../utils/custom-styles/selectCustomStyleModal";
import { modalTextFieldStyle } from "../../../utils/custom-styles/textFieldCustomStylesModal";
import LoadingModal from "../components/Modals/LoadingModal";
import PurchasingAndContractingListUploadSuccessModal from "../components/Modals/PurchasingAndContractingListUploadSuccessModal";
import { PurchasingContractingENUM } from "../models/PurchasingContractingENUM";
import ManageFilesService from "../../../services/files/ManageFilesService";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import Avatar from "@mui/material/Avatar";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
// import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { ReactComponent as PendingActionIcon } from "../../../assets/pending-action.svg";

import Timeline, { StatusEnum } from "../components/Timeline/Timeline";

import ReqImg from "../../../assets/images/req-img.jpg";
import DateCard from "../../../components/Cards/DateCard/DateCard";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Footer from "../../../components/v2/Footer";
import UserInfo from "../components/UserInfo/UserInfo";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import { Colors } from "../../../theme/v2/variants";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import PurchasingContractingPlanService from "../../../services/purchasingContractingPlan/PurchasingContractingPlanService";
import PurchasingModel, {
  parsePurchasingEntityToModel,
} from "../models/PurchasingModel";
import { BaseState } from "../../Actions/ViewActions/utils/BaseState";
import ContractingModel, {
  parseContractingEntityToModel,
} from "../models/ContractingMode";
import VerticalServiceModel from "../../../services/interfaces/VerticalServiceModel";
import VerticalService from "../../../services/VeritcalService";
import { ItemType } from "../../../components/InputValues/CustomDropDownComponent";
import { useDashboard } from "../../../contexts/dashboard";
import ManageBudgetsService from "../../../services/budgets/ManageBudgetsService";
import { BudgetEntity } from "../../../services/budgets/entity/budget.entity";
import ManageSuppliersService from "../../../services/suppliers/ManageSuppliersService";
import { Supplier } from "../../../services/suppliers/entity/supplier.entity";
import ConstructionService from "../../../services/ConstructionService";
import { PurchasingPhaseENUM } from "../models/PurchasingPhaseENUM";
import { PurchasingPlanStatusENUM } from "../models/PurchasingPlanStatusENUM";
import { ContractingPhaseENUM } from "../models/ContractingPhaseENUM";
import { ContractingPlanStatusENUM } from "../models/ContractingPlanStatusENUM";
import EditBudgetTotalValueDialog from "../components/EditBudgetTotalValueDialog";
import PurchasingAndContractingSuppliersModal
  from "../components/PurchasingAndContractingSuppliersModal/PurchasingAndContractingSuppliersModal";
import {
  PurchasingPlanSuppliersEntity
} from "../../../services/purchasingContractingPlan/entity/PurchasingPlanSuppliersEntity";
import {
  ContractingPlanSuppliersEntity
} from "../../../services/purchasingContractingPlan/entity/ContractingPlanSuppliersEntity";

interface OrderOfStepsProps {}

export enum BudgetStatusENUM {
  APROVADO = "APROVADO",
  REPROVADO = "REPROVADO",
  PENDENTE = "PENDENTE",
}

export enum OrderStatusEnum {
  APROVADO = "APROVADO",
  REPROVADO = "REPROVADO",
  AGUARDANDO = "AGUARDANDO",
  PENDENTE = "PENDENTE",
  CANCELADO = "CANCELADO",
}

export enum ReponseStatusEnum {
  APPROVED = "APPROVED",
  REPROVED = "REPROVED"
}

interface FileProps {
  id: number;
  fileName: string;
  uploadedBy: string;
}

const fileCategories = [
  {
    key: 'pre-quoting',
    name: 'Pré-cotação',
    subCategories: [
      {
        name: 'Requisição de compra',
      },
      {
        name: 'Especificações técnicas',
      },
      {
        name: 'Projetos e Desenhos',
      },
      {
        name: 'Lista de Fornecedores',
      }
    ]
  },
  {
    key: 'quoting',
    name: 'Cotação',
    subCategories: [
      {
        name: 'Planilha de Cotação',
      },
      {
        name: 'Política de Compras',
      },
    ]
  },
  {
    key: 'negociation',
    name: 'Negociação e Seleção',
    subCategories: [
      {
        name: 'Planilha Comparativa'
      },
      {
        name: 'Análise de Fornecedores'
      },
      {
        name: 'Minuta de Contrato'
      }
    ]
  },
  {
    key: 'purchasing-emission',
    name: 'Emissão do Pedido de Compra',
    subCategories: [
      {
        name: 'Pedido de Compra'
      },
      {
        name: 'Ordem de Serviço'
      },
      {
        name: 'Nota Fiscal'
      }
    ]
  },
  {
    key: 'order-accompaniment',
    name: 'Acompanhamento do Pedido',
    subCategories: [
      {
        name: 'Cronograma de Entregas'
      },
      {
        name: 'Relatório de Status'
      },
      {
        name: 'Checklists'
      }
    ]

  },
  {
    key: 'building-receiving',
    name: 'Recebimento na Obra',
    subCategories: [
      {
        name: 'Comprovante de Recebimento'
      },
      {
        name: 'Nota Fiscal'
      },
      {
        name: 'Registro de Ocorrências'
      }
    ]
  },
  {
    key: 'pos-delivery-and-payment',
    name: 'Pós-Entrega e Pagamento',
    subCategories: [
      {
        name: 'Comprovante de Pagamento'
      },
      {
        name: 'Avaliação do Fornecedor'
      },
      {
        name: 'Histórico de Compras'
      }
    ]
  }
]

const getInitials = (name: string) => {
  const initials = name.split(" ").map((n) => n[0]).join("").toUpperCase();
  return initials.slice(0, 1);
};

const formatDate = (date?: Date | undefined): string => {
  if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
    return '';
  }

  const day = date.getDate().toString().padStart(2, "0");
  const month = date
    .toLocaleString("pt-BR", { month: "short" })
    .replace(".", "");
  const year = date.getFullYear();

  return `${day} ${month.charAt(0).toUpperCase() + month.slice(1)} ${year}`;
};

const PurchasingContractingPlanOrderDetail: React.FC<
  OrderOfStepsProps
> = () => {
  const navigation = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const purchasingContractingService = new PurchasingContractingPlanService();
  const verticalService = new VerticalService();
  const manageFilesService = new ManageFilesService();
  const budgetService = new ManageBudgetsService();
  const supplierService = new ManageSuppliersService();
  const constructionService = new ConstructionService();

  const { constructionId } = useDashboard();

  const [alert, setAlert] = useState({
    title: "",
    message: "",
    isOpened: false,
  });

  const [alignment, setAlignment] = React.useState("anexar");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalFilesOpen, setIsModalFilesOpen] = useState(false);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string>("");
  const [identification, setIdentification] = useState<string>("");
  const [filesList, setFilesList] = useState<FileProps[]>([]);

  // Esse estado armazena o Id do VerticalServiceGroup selecionado
  const [groupId, setGroupId] = useState<number | null>(null);

  const planId = searchParams.get("id");
  const planType =
    searchParams.get("type") || PurchasingContractingPlanFormType.CREATE;

  const [isMobile, setIsMobile] = useState<boolean>();

  const [modalBudgetText, setModalBudgetText] = useState<string>("");
  const [modalPurchaseText, setModalPurchaseText] = useState<string>("");
  const [modalReceiptText, setModalReceiptText] = useState<string>("");

  //Modal de Observação Salva
  const [isBudgetRejObsModalOpen, setIsBudgetRejObsModalOpen] = useState(false);
  const [isRejObsSavedModalOpen, setIsRejObsSavedModalOpen] = useState(false);

  // Modais de aprovação/reprovação do orçamento
  const [isBudgetApprovObsModalOpen, setIsBudgetApprovObsModalOpen] =
    useState(false);
  const [isBudgetApprovObsSavedModalOpen, setIsBudgetApprovObsSavedModalOpen] =
    useState(false);

  const [isRevertApprovBudgetModalOpen, setRevertApprovBudgetModalOpen] = useState(false)
  const [isRevertApprovBudgetObsSavedModalOpen, setIsRevertApprovBudgetObsSavedModalOpen] = useState(false);

  // Modais de aprovação/reprovação da Compra
  const [isPurchaseRejObsModalOpen, setIsPurchaseRejObsModalOpen] =
    useState(false);
  const [isPurchaseApprovObsModalOpen, setIsPurchaseApprovObsModalOpen] =
    useState(false);
  const [
    isPurchaseApprovObsSavedModalOpen,
    setIsPurchaseApprovObsSavedModalOpen,
  ] = useState(false);

  // Modais de aprovação/reprovação do Recebimento
  const [isReceiptRejObsModalOpen, setIsReceiptRejObsModalOpen] =
    useState(false);
  const [isReceiptApprovObsModalOpen, setIsReceiptApprovObsModalOpen] =
    useState(false);
  const [
    isReceiptApprovObsSavedModalOpen,
    setIsReceiptApprovObsSavedModalOpen,
  ] = useState(false);

  const [progress, setProgress] = useState(0);

  const { user } = useAuth();

  const [state, setState] = useState<BaseState>(BaseState.success);
  const [purchasingSelectedPlan, setPurchasingSelectedPlan] = useState<
    PurchasingModel | undefined
  >();

  const [contractingSelectedPlan, setContractingSelectedPlan] = useState<
    ContractingModel | undefined
  >();

  const [selectedServices, setSelectedServices] = useState<
    VerticalServiceModel[]
  >([]);

  const [selectedServiceType, setSelectedServiceType] = useState<ItemType>();

  const [servicesTypes, setServicesTypes] = useState<any[]>([]);

  const [supplier, setSupplier] = useState<number>();
  const [allSupplier, setAllSuppliers] = useState<any[]>([]);

  const [constructionInfo, setConstructionInfo] = useState<any>();
  const [actualPhase, setActualPhase] = useState<
    PurchasingPhaseENUM | ContractingPhaseENUM
  >(PurchasingPhaseENUM.QUOTATION || ContractingPhaseENUM.QUOTATION);

  const [budgets, setBudgets] = useState<BudgetEntity[]>([]);

  const [suppliers, setSuppliers] = useState<Supplier[]>([]);

  const [approvedSupplier, setApprovedSupplier] = useState<string>("");

  const [approvedBudget, setApprovedBudget] = useState<BudgetEntity>();
  const [isSuppliersModalOpen, setIsSuppliersModalOpen] = useState(false);


  let purchaseConfirmationStatus = OrderStatusEnum.PENDENTE
  let receiptApprovalStatus = OrderStatusEnum.PENDENTE

  let quotationStatus = StatusEnum.NOT_STARTED
  let approvalStatus = StatusEnum.NOT_STARTED
  let purchasingStatus = StatusEnum.NOT_STARTED
  let receiptStatus = StatusEnum.NOT_STARTED

  if (purchasingSelectedPlan) {
    const phase = purchasingSelectedPlan?.phase;
    const status = purchasingSelectedPlan?.status;

    // QUOTATION
    if (phase === PurchasingPhaseENUM.QUOTATION) {
      if (status === PurchasingPlanStatusENUM.OVERDUE) {
        quotationStatus = StatusEnum.OVERDUE
      } else if (status === PurchasingPlanStatusENUM.NOT_STARTED) {
        quotationStatus = StatusEnum.PENDING
      } else {
        quotationStatus = StatusEnum.IN_PROGRESS
      }
    } else {
      quotationStatus = StatusEnum.COMPLETED
    }

    // ON_APPROVAL
    if (phase === PurchasingPhaseENUM.ON_APPROVAL) {
      if (status === PurchasingPlanStatusENUM.OVERDUE) {
        approvalStatus = StatusEnum.OVERDUE
      } else if (status === PurchasingPlanStatusENUM.NOT_STARTED) {
        approvalStatus = StatusEnum.PENDING
      } else {
        approvalStatus = StatusEnum.IN_PROGRESS
      }
    } else if (phase !== PurchasingPhaseENUM.QUOTATION) {
      approvalStatus = StatusEnum.COMPLETED
    }

    // PURCHASING
    if (phase === PurchasingPhaseENUM.PURCHASING) {
      if (status === PurchasingPlanStatusENUM.OVERDUE) {
        purchasingStatus = StatusEnum.OVERDUE
      } else if (status === PurchasingPlanStatusENUM.NOT_STARTED) {
        purchasingStatus = StatusEnum.PENDING
      } else {
        purchasingStatus = StatusEnum.IN_PROGRESS
      }
    } else if (phase !== PurchasingPhaseENUM.QUOTATION && phase !== PurchasingPhaseENUM.ON_APPROVAL) {
      purchasingStatus = StatusEnum.COMPLETED
    }

    // ON_APPROVAL_RECEIPT
    if (phase === PurchasingPhaseENUM.ON_APPROVAL_RECEIPT) {
      purchaseConfirmationStatus = OrderStatusEnum.APROVADO
      if (status === PurchasingPlanStatusENUM.OVERDUE) {
        receiptStatus = StatusEnum.OVERDUE
      } else if (status === PurchasingPlanStatusENUM.NOT_STARTED) {
        receiptStatus = StatusEnum.PENDING
      } else {
        receiptStatus = StatusEnum.IN_PROGRESS
      }
    } else if (
      phase !== PurchasingPhaseENUM.QUOTATION &&
      phase !== PurchasingPhaseENUM.ON_APPROVAL &&
      phase !== PurchasingPhaseENUM.PURCHASING
    ) {
      receiptStatus = StatusEnum.COMPLETED
    }

    // TOTALLY_COMPLETED
    if (phase === PurchasingPhaseENUM.TOTALLY_COMPLETED) {
      purchaseConfirmationStatus = OrderStatusEnum.APROVADO
      receiptStatus = StatusEnum.COMPLETED
    }
  } else if (contractingSelectedPlan) {
    const phase = contractingSelectedPlan?.phase;
    const status = contractingSelectedPlan?.status;

    // QUOTATION
    if (phase === ContractingPhaseENUM.QUOTATION) {
      if (status === ContractingPlanStatusENUM.OVERDUE) {
        quotationStatus = StatusEnum.OVERDUE
      } else if (status === ContractingPlanStatusENUM.NOT_STARTED) {
        quotationStatus = StatusEnum.PENDING
      } else {
        quotationStatus = StatusEnum.IN_PROGRESS
      }
    } else {
      quotationStatus = StatusEnum.COMPLETED
    }

    // ON_APPROVAL
    if (phase === ContractingPhaseENUM.ON_APPROVAL) {
      if (status === ContractingPlanStatusENUM.OVERDUE) {
        approvalStatus = StatusEnum.OVERDUE
      } else if (status === ContractingPlanStatusENUM.NOT_STARTED) {
        approvalStatus = StatusEnum.PENDING
      } else {
        approvalStatus = StatusEnum.IN_PROGRESS
      }
    } else if (phase !== ContractingPhaseENUM.QUOTATION) {
      approvalStatus = StatusEnum.COMPLETED
    }

    // CONTRACTING
    if (phase === ContractingPhaseENUM.CONTRACTING) {
      if (status === ContractingPlanStatusENUM.OVERDUE) {
        purchasingStatus = StatusEnum.OVERDUE
      } else if (status === ContractingPlanStatusENUM.NOT_STARTED) {
        purchasingStatus = StatusEnum.PENDING
      } else {
        purchasingStatus = StatusEnum.IN_PROGRESS
      }
    } else if (phase !== ContractingPhaseENUM.QUOTATION && phase !== ContractingPhaseENUM.ON_APPROVAL) {
      purchasingStatus = StatusEnum.COMPLETED
    }

    // ON_APPROVAL_RECEIPT
    if (phase === ContractingPhaseENUM.ON_APPROVAL_RECEIPT) {
      purchaseConfirmationStatus = OrderStatusEnum.APROVADO
      if (status === ContractingPlanStatusENUM.OVERDUE) {
        receiptStatus = StatusEnum.OVERDUE
      } else if (status === ContractingPlanStatusENUM.NOT_STARTED) {
        receiptStatus = StatusEnum.PENDING
      } else {
        receiptStatus = StatusEnum.IN_PROGRESS
      }
    } else if (
      phase !== ContractingPhaseENUM.QUOTATION &&
      phase !== ContractingPhaseENUM.ON_APPROVAL &&
      phase !== ContractingPhaseENUM.CONTRACTING
    ) {
      receiptStatus = StatusEnum.COMPLETED
    }

    // TOTALLY_COMPLETED
    if (phase === ContractingPhaseENUM.TOTALLY_COMPLETED) {
      purchaseConfirmationStatus = OrderStatusEnum.APROVADO
      receiptStatus = StatusEnum.COMPLETED
    }
  }

  const steps = [
    {
      title: "Pedido de Cotação",
      active: purchasingSelectedPlan?.phase === PurchasingPhaseENUM.QUOTATION,
      completed: false,
      status: quotationStatus,
      Date:
        (purchasingSelectedPlan?.supplierQuotationDate && formatDate(new Date(purchasingSelectedPlan?.supplierQuotationDate))) ||
        (contractingSelectedPlan?.supplierQuotationDate && formatDate(new Date(contractingSelectedPlan?.supplierQuotationDate))),
      UserName:
        purchasingSelectedPlan?.supplierQuotatioResponsable?.name ||
        contractingSelectedPlan?.supplierQuotatioResponsable?.name,
      action: "Requisitado",
    },
    {
      title: "Aprovação do Orçamento",
      active: false,
      completed: false,
      status: approvalStatus,
      Date:
        (purchasingSelectedPlan?.supplierQuotationFinishedDate && formatDate(new Date(purchasingSelectedPlan?.supplierQuotationFinishedDate))) ||
        (contractingSelectedPlan?.supplierQuotationFinishedDate && formatDate(new Date(contractingSelectedPlan?.supplierQuotationFinishedDate))),
      UserName:
        purchasingSelectedPlan?.approveResponsable?.name ||
        contractingSelectedPlan?.approveResponsable?.name,
      action:
        approvalStatus == StatusEnum.COMPLETED
          ? "Aprovado"
          : "Reprovado",
    },
    {
      title: "Efetuar Compra",
      active: false,
      completed: false,
      status: purchasingStatus,
      Date:
        (purchasingSelectedPlan?.purchasingFinishedDate && formatDate(new Date(purchasingSelectedPlan?.purchasingFinishedDate))) ||
        (contractingSelectedPlan?.contractingFinishedDate && formatDate(new Date(contractingSelectedPlan?.contractingFinishedDate))),
      UserName:
        purchasingSelectedPlan?.purchasingResponsable?.name ||
        contractingSelectedPlan?.purchasingResponsable?.name,
      action:
        purchasingStatus == StatusEnum.COMPLETED ? "Efetivada" : "Reprovado",
    },
    {
      title: "Aprovar Recebimento",
      active: false,
      completed: false,
      status: receiptStatus,
      Date:
        (purchasingSelectedPlan?.approveReceiptFinishedDate && formatDate(new Date(purchasingSelectedPlan?.approveReceiptFinishedDate))) ||
        (contractingSelectedPlan?.approveReceiptFinishedDate && formatDate(new Date(contractingSelectedPlan?.approveReceiptFinishedDate))),
      UserName:
        purchasingSelectedPlan?.finishPlanResponsable?.name ||
        contractingSelectedPlan?.finishPlanResponsable?.name,
      action:
        receiptStatus == StatusEnum.COMPLETED
          ? "Recebido e Aprovado"
          : "Não concluído",
    },
  ];

  // Função para abrir e fechar modal de Observação Salva
  const openRejObsSavedModal = () => {
    setIsRejObsSavedModalOpen(true);
  };
  const closeRejObsSavedModal = () => {
    setIsRejObsSavedModalOpen(false);
  };


  const openRevertApprovBudgetObsSavedModal = () => {
    setIsRevertApprovBudgetObsSavedModalOpen(true);
  };

  const closeRevertApprovBudgetObsSavedModal = () => {
    setIsRevertApprovBudgetObsSavedModalOpen(false);
  };

  // Funções para abrir e fechar os modais de aprovação/reprovação do orçamento
  const openBudgetRejObsModal = () => {
    setIsBudgetRejObsModalOpen(true);
  };
  const closeBudgetRejObsModal = () => {
    setIsBudgetRejObsModalOpen(false);
  };

  const openBudgetApprovObsModal = () => {
    setIsBudgetApprovObsModalOpen(true);
  };
  const closeBudgetApprovObsModal = () => {
    setIsBudgetApprovObsModalOpen(false);
  };

  const openRevertBudgetApprovObsModal = () => {
    setRevertApprovBudgetModalOpen(true);
  };
  const closeRevertBudgetApprovObsModal = () => {
    setRevertApprovBudgetModalOpen(false);
  };

  const openBudgetApprovObsSavedModal = () => {
    setIsBudgetApprovObsSavedModalOpen(true);
  };
  const closeBudgetApprovObsSavedModal = () => {
    setIsBudgetApprovObsSavedModalOpen(false);
  };

  // Funções para abrir e fechar os modais de aprovação/reprovação de Compra
  const openPurchaseRejObsModal = () => {
    setIsPurchaseRejObsModalOpen(true);
  };
  const closePurchaseRejObsModal = () => {
    setIsPurchaseRejObsModalOpen(false);
  };

  const openPurchaseApprovObsModal = () => {
    setIsPurchaseApprovObsModalOpen(true);
  };
  const closePurchaseApprovObsModal = () => {
    setIsPurchaseApprovObsModalOpen(false);
  };

  const openPurchaseApprovObsSavedModal = () => {
    setIsPurchaseApprovObsSavedModalOpen(true);
  };
  const closePurchaseApprovObsSavedModal = () => {
    setIsPurchaseApprovObsSavedModalOpen(false);
  };

  // Funções para abrir e fechar os modais de aprovação/reprovação do Recebimento
  const openReceiptRejObsModal = () => {
    setIsReceiptRejObsModalOpen(true);
  };
  const closeReceiptRejObsModal = () => {
    setIsReceiptRejObsModalOpen(false);
  };

  const openReceiptApprovObsModal = () => {
    setIsReceiptApprovObsModalOpen(true);
  };
  const closeReceiptApprovObsModal = () => {
    setIsReceiptApprovObsModalOpen(false);
  };

  const openReceiptApprovObsSavedModal = () => {
    setIsReceiptApprovObsSavedModalOpen(true);
  };
  const closeReceiptApprovObsSavedModal = () => {
    setIsReceiptApprovObsSavedModalOpen(false);
  };

  const handleResize = () => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const loadPageData = async (id: string | null, type: string | null) => {
    const verticalServiceId = searchParams.get("verticalServiceId");
    let selectedPlan: PurchasingModel | ContractingModel | undefined;
    if (id && type) {
      selectedPlan = await findPlanById(Number(id), type || "");
    }

    let selectedVerticalService = undefined;

    if (verticalServiceId) {
      selectedVerticalService = await verticalService.getVerticalServiceById(
        constructionId,
        Number(verticalServiceId)
      );
      setSelectedServices([selectedVerticalService]);
    }

    findServiceTypes(
      constructionId,
      selectedPlan?.services[0]?.verticalServiceTypeId ||
        selectedVerticalService?.verticalServiceTypeId
    );

    let planType: "PURCHASING" | "CONTRACTING" =
      type == PurchasingContractingENUM.PURCHASING
        ? "PURCHASING"
        : "CONTRACTING";

    const budgets = await budgetService.getBudgetsByPlanId(
      Number(id),
      planType
    );

    const suppliers = await supplierService.getSuppliersByPlanId(
      Number(id),
      planType
    );

    const constructionInfo = await constructionService.findById(constructionId);

    setConstructionInfo(constructionInfo);
    setSuppliers(suppliers);
    setBudgets(budgets);

    for (const item of budgets) {
      if (item.budgetStatus === BudgetStatusENUM.APROVADO) {
        setApprovedSupplier(item.supplier.name);
        setApprovedBudget(item);
      }
    }

    setActualPhase(selectedPlan?.phase || PurchasingPhaseENUM.QUOTATION);

    setState(BaseState.success);
  };

  useEffect(() => {
    if (isMobile === undefined) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
  });

  const goToList = () => {
    navigation(PURCHASING_CONTRACTING_PLAN_PATH);
  };

  const cancelOnClick = () => {
    goToList();
  };

  const toggleModal = () => {
    getUploadedFilesHandler();
    setIsModalOpen(!isModalOpen);
  };
  const closeModal = () => setIsModalOpen(false);

  const closeFilesModal = () => setIsModalFilesOpen(false);

  const closeLoadingModal = () => setIsLoadingModalOpen(false);

  const findServiceTypes = async (
    constructionId?: number,
    selectedServicesTypeId?: number
  ) => {
    const response = await verticalService.getServiceTypes(
      constructionId,
      false,
      true
    );
    setServicesTypes(response);

    if (selectedServicesTypeId) {
      response.find((serviceGroup) => {
        const service = serviceGroup.services.find(
          (serviceType: any) => serviceType.id === selectedServicesTypeId
        );

        if (service) {
          setGroupId(serviceGroup.id);
          setSelectedServiceType({
            group: serviceGroup.name,
            color: serviceGroup.color,
            id: service.id,
            type: service.name,
            addByUser: service.isAddByUser === true,
          });
        }
      });
    }
  };

  const findPlanById = async (
    id: number,
    type: string
  ): Promise<PurchasingModel | ContractingModel | undefined> => {
    try {
      let model: PurchasingModel | ContractingModel | undefined;

      if (id !== undefined && type === PurchasingContractingENUM.PURCHASING) {
        const response =
          await purchasingContractingService.getPurchasingPlanById(id);
        model = parsePurchasingEntityToModel(response);
        setPurchasingSelectedPlan(model);
        setSelectedServices(model.services);
      }

      if (id !== undefined && type === PurchasingContractingENUM.CONTRACTING) {
        const response =
          await purchasingContractingService.getContractingPlanById(id);
        model = parseContractingEntityToModel(response);
        setContractingSelectedPlan(model);
        setSelectedServices(model.services);

      }

      // handleStepStatusChange();
      return model;
    } catch (error) {
      setState(BaseState.error);
      return undefined;
    }
  };

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const handleCategoryChange = (value: string) => {
    setSelectedCategory(value);
  };

  const handleTypeChange = (value: string) => {
    setSelectedType(value);
  };

  const handleIdentificationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIdentification(e.target.value);
  };

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0].size < 15 * 1024 * 1024) {
        setIsLoadingModalOpen(true);

        const selectedFiles = Array.from(e.target.files);
        const fileName = selectedFiles.map((file) => file.name);

        const uploadedFileDto = {
          fileName: fileName,
          userId: user.id,
          uploadedBy: user.name,
          category: selectedCategory,
          fileType: selectedType,
          identifier: identification,
        };

        const pcpDto = {
          serviceType:
            planType === PurchasingContractingENUM.PURCHASING
              ? "PURCHASING"
              : "CONTRACTING",
          ContractingPlanId:
            planType === PurchasingContractingENUM.CONTRACTING
              ? contractingSelectedPlan?.id
              : "",
          contractingPhase:
            planType === PurchasingContractingENUM.CONTRACTING
              ? contractingSelectedPlan?.phase
              : null,
          PurchasingPlanId:
            planType === PurchasingContractingENUM.PURCHASING
              ? purchasingSelectedPlan?.id
              : "",
          purchasingPhase:
            planType === PurchasingContractingENUM.PURCHASING
              ? purchasingSelectedPlan?.phase
              : null,
          verticalServiceGroupId: groupId,
        };

        try {
          const response = await manageFilesService.uploadFiles(
            selectedFiles,
            uploadedFileDto,
            pcpDto,
            (progressEvent: ProgressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(progress);
            }
          );
          console.log(response);
          getUploadedFilesHandler();
          setIsLoadingModalOpen(false);
          setIsModalFilesOpen(true);
          setAlignment("listar");
        } catch (error) {
          console.error("Erro ao enviar arquivos:", error);
          setAlert({
            title: "Error",
            message: "Erro ao enviar arquivos",
            isOpened: true,
          });
        }
      } else {
        e.target.value = '';
        setAlert({
          title: "Error",
          message: "Tamanho máximo de 15 MB",
          isOpened: true,
        });
        return false
      }
    }
  };

  const handleFilesDelete = async (id: number, type: string) => {
    if (type === PurchasingContractingENUM.PURCHASING) {
      type = "PURCHASING";
    } else if (type === PurchasingContractingENUM.CONTRACTING) {
      type = "CONTRACTING";
    }

    try {
      await manageFilesService.deletePurcharsingContractingUploadedFiles(
        id,
        type
      );

      getUploadedFilesHandler();
    } catch (error) {
      console.error("Erro ao deletar o arquivo:", error);
    }
  };

  const getUploadedFilesHandler = async () => {
    let Id = Number(planId);
    let type = planType;
    if (type === PurchasingContractingENUM.PURCHASING) {
      type = "PURCHASING";
    } else if (type === PurchasingContractingENUM.CONTRACTING) {
      type = "CONTRACTING";
    }
    const data =
      await manageFilesService.getPurcharsingContractingUploadedFiles(
        Id!,
        type
      );

    setFilesList(data);
  };

  useEffect(() => {
    findPlanById(Number(planId), planType);
  }, []);

  useEffect(() => {
    try {
      setState(BaseState.loading);

      let id = searchParams.get("id");
      let type = searchParams.get("type");

      loadPageData(id, type);
    } catch (error) {
      setState(BaseState.error);
    }
  }, []);

  useEffect(() => {
    getUploadedFilesHandler();
  }, []);

  function calculateLimitDate(
    baseDate: Date | undefined,
    additionalDays: number,
    extraDays: number
  ): Date | undefined {
    if (!baseDate) {
      return undefined;
    }

    const limitDate = new Date(baseDate);

    limitDate.setDate(limitDate.getDate() + additionalDays + extraDays);

    return limitDate;
  }

  const [idSelectedBudget, setIdSelectedBudget] = useState<number>();

  const approveBudget = async (budgetId: number, observation: string) => {
    try {
      const status = await budgetService.updateBudgetStatus(
        budgetId,
        BudgetStatusENUM.APROVADO
      );

      const observationResponse =
        await budgetService.updateBudgetDecisionObservation(
          budgetId,
          observation
        );

      loadPageData(planId, planType);
      closeBudgetApprovObsModal();
      openRejObsSavedModal();
      return observationResponse;
    } catch (error) {
      console.error("Erro ao aprovar o orçamento:", error);
      throw error;
    }
  };

  const reproveBudget = async (budgetId: number, observation: string) => {
    try {
      const status = await budgetService.updateBudgetStatus(
        budgetId,
        BudgetStatusENUM.REPROVADO
      );

      const observationResponse =
        await budgetService.updateBudgetDecisionObservation(
          budgetId,
          observation
        );

      loadPageData(planId, planType);
      closeBudgetRejObsModal();
      openRejObsSavedModal();
      return observationResponse;
    } catch (error) {
      console.error("Erro ao reprovar o orçamento:", error);
      throw error;
    }
  };

  const revertBudgetApproval = async (budgetId: number, observation: string) => {
    try {
      const status = await budgetService.updateBudgetStatus(
        budgetId,
        BudgetStatusENUM.PENDENTE
      );

      const observationResponse =
        await budgetService.updateBudgetDecisionObservation(
          budgetId,
          observation
        );

      loadPageData(planId, planType);
      closeRevertBudgetApprovObsModal()
      openRevertApprovBudgetObsSavedModal();
      clearModalData();
      return observationResponse;
    } catch (error) {
      console.error("Erro ao aprovar o orçamento:", error);
      throw error;
    }
  };


  const approvePurchase = async (budgetId: number, observation: string) => {
    try {
      const response = await budgetService.updatePurchaseDecisionObservation(
        budgetId,
        observation,
        ReponseStatusEnum.APPROVED
      );

      loadPageData(planId, planType);

      return response;
    } catch (error) {
      console.error("Erro ao atualizar a observação da compra:", error);
      throw error;
    }
  };

  const reprovePurchase = async (
    budgetId: number,
    observation: string
  ) => {
    try {
      const response = await budgetService.updatePurchaseDecisionObservation(
        budgetId,
        observation,
        ReponseStatusEnum.REPROVED
      );

      loadPageData(planId, planType);

      return response;
    } catch (error) {
      console.error("Erro ao atualizar a observação da compra:", error);
      throw error;
    }
  };

  const approveReceipt = async (budgetId: number, observation: string) => {
    try {
      const response = await budgetService.updateReceiptDecisionObservation(
        budgetId,
        observation,
        ReponseStatusEnum.APPROVED
      );

      loadPageData(planId, planType);

      return response;
    } catch (error) {
      console.error("Erro ao atualizar a observação da compra:", error);
      throw error;
    }
  }

  const reproveReceipt = async (
    budgetId: number,
    observation: string
  ) => {
    try {
      const response = await budgetService.updateReceiptDecisionObservation(
        budgetId,
        observation,
        ReponseStatusEnum.REPROVED
      );

      loadPageData(planId, planType);

      return response;
    } catch (error) {
      console.error("Erro ao atualizar a observação da compra:", error);
      throw error;
    }
  };

  const requestNewQuoting = async (supplierIds: number[]) => {
    if (purchasingSelectedPlan && purchasingSelectedPlan.id) {
      try {
        const response = await purchasingContractingService.requestPurchasingPlanQuoting(purchasingSelectedPlan.id, supplierIds)
      } catch (error) {
        console.error("Erro ao solicitar novo orçamento:", error);
        setAlert({
          title: "Error",
          message: "Erro ao solicitar novo orçamento:",
          isOpened: true,
        });
      }
    } else if (contractingSelectedPlan && contractingSelectedPlan.id) {
      try {
        const response = await purchasingContractingService.requestContractingPlanQuoting(contractingSelectedPlan.id, supplierIds)
      } catch (error) {
        console.error("Erro ao solicitar novo orçamento:", error);
        setAlert({
          title: "Error",
          message: "Erro ao solicitar novo orçamento:",
          isOpened: true,
        });
      }
    }
    loadPageData(planId, planType);
    handleOpenSuppliersModal()
  }

  const clearModalData = () => {
    setModalBudgetText("");
    setModalPurchaseText("");
    setModalReceiptText("");
    setIdSelectedBudget(undefined);
  };

  const handleOpenSuppliersModal = () => {
    setIsSuppliersModalOpen(!isSuppliersModalOpen);
  };

  const renderApprovedBudget = () => {
    const appvdBudget = budgets.find(budget => budget.budgetStatus === BudgetStatusENUM.APROVADO)
    return (
      <Styles.BudgetContainer>
        <Styles.AprovHeaderBudgetContainer>
          <Styles.BudgetInfoRow>
            <Styles.InfoRow>
              <Styles.InfoContent>
                <Styles.InfoTitle>
                  Orçamento Nº
                </Styles.InfoTitle>
                <Styles.InfoTextDarkBold>
                  {planId}
                </Styles.InfoTextDarkBold>
              </Styles.InfoContent>
              <Styles.InfoContent>
                <Styles.InfoTitle>Cliente</Styles.InfoTitle>
                <Styles.InfoTextDarkBold>
                  {constructionInfo
                    ? `${constructionInfo.clientId.name} - ${constructionInfo.name}`
                    : "No construction selected"}
                </Styles.InfoTextDarkBold>
              </Styles.InfoContent>
            </Styles.InfoRow>
            <Styles.InfoRow>
              <DateCard
                title="Data da Proposta"
                date={
                  appvdBudget?.uploadedAt
                    ? new Date(appvdBudget.uploadedAt)
                    : new Date()
                }
              />
              <Styles.InfoContent>
                <Styles.InfoTitle>
                  Valor total pedido
                </Styles.InfoTitle>
                <Styles.InfoTextDarkBold fontSize="24px">
                  {
                    (parseFloat(appvdBudget?.value.toString() || '0')).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }
                  <EditBudgetTotalValueDialog
                    budgetId={appvdBudget?.id}
                    onSave={() => loadPageData(planId, planType)}
                  />
                </Styles.InfoTextDarkBold>
              </Styles.InfoContent>
            </Styles.InfoRow>
          </Styles.BudgetInfoRow>
        </Styles.AprovHeaderBudgetContainer>

          <Styles.ContentBudgetContainer>
            <Styles.InfoRow>
              <Styles.InfoContent>
                <Styles.InfoTitle>Observação</Styles.InfoTitle>
                <Styles.ObservationText>
                  {appvdBudget?.budgetDecisionObservation}
                </Styles.ObservationText>
              </Styles.InfoContent>
            </Styles.InfoRow>
            <Styles.BudgetInfoRow>
              <Styles.InfoRow>
                <Styles.OrderFileItem
                  onClick={() => {
                    if (appvdBudget?.fileUrl) {
                      window.open(appvdBudget?.fileUrl, "_blank");
                    }
                  }}
                >
                  <InsertDriveFileOutlinedIcon fontSize="small" />
                  <FileItemText>{appvdBudget?.fileName}</FileItemText>
                  {/* <DeleteOutlineRoundedIcon
                                    fontSize="small"
                                    onClick={() =>
                                      handleFilesDelete(
                                        filesList[0].id,
                                        planType
                                      )
                                    }
                                  /> */}
                </Styles.OrderFileItem>
              </Styles.InfoRow>
              <Styles.InfoRow
                gap="16px"
                style={{ marginLeft: "auto" }}
              >
                <UserInfo
                  Date={formatDate(
                    new Date(appvdBudget?.decisionDate ?? new Date())
                  )}
                  UserName={
                    purchasingSelectedPlan?.approveResponsable
                      ?.name ||
                    contractingSelectedPlan?.approveResponsable
                      ?.name
                  }
                  action="Aprovado"
                />
                {
                  (actualPhase === PurchasingPhaseENUM.QUOTATION || actualPhase === ContractingPhaseENUM.QUOTATION) && (
                    <ButtonComponent
                      text="Desfazer Aprovação"
                      type={ButtonType.OUTLINELINK}
                      onClick={() => {
                        openRevertBudgetApprovObsModal()
                      }}
                      startIcon={<CloseIcon />}
                      padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                    />
                  )
                }
                <ButtonComponent
                  text="Aprovado"
                  type={ButtonType.APPROVED}
                  onClick={() => {}}
                  startIcon={<ThumbUpAltOutlinedIcon />}
                  padding={{
                    left: 24,
                    right: 24,
                    top: 10,
                    bottom: 10,
                  }}
                />
              </Styles.InfoRow>
            </Styles.BudgetInfoRow>
          </Styles.ContentBudgetContainer>
        </Styles.BudgetContainer>
    )
  }

  const renderPendingBudget = (
    index: number | string,
    supplier: Supplier,
    planSupplier: PurchasingPlanSuppliersEntity | ContractingPlanSuppliersEntity,
    budget: BudgetEntity
  ) => {
    return (
      <Styles.BudgetContainer key={index}>
        <Styles.HeaderBudgetContainer>
          <Styles.BudgetInfoRow>
            <Styles.InfoContent>
              <Styles.InfoTitle>Pedido Nº</Styles.InfoTitle>
              <Styles.InfoTextDarkBold>
                {planId}
              </Styles.InfoTextDarkBold>
            </Styles.InfoContent>
            <DateCard
              title="Data do envio"
              date={planSupplier?.createdAt && new Date(planSupplier?.createdAt)}
            />
          </Styles.BudgetInfoRow>
          <Styles.BudgetInfoRow>
            <Styles.InfoContent>
              <Styles.InfoTitle>
                Fornecedor
              </Styles.InfoTitle>
              <Styles.InfoTextDarkBold>
                {supplier.name}
              </Styles.InfoTextDarkBold>
            </Styles.InfoContent>
          </Styles.BudgetInfoRow>
          <Styles.BudgetInfoRow>
            <Styles.InfoContent>
              <Styles.InfoTitle>
                Valor total pedido
              </Styles.InfoTitle>
              <Styles.InfoTextDarkBold fontSize="24px">
                {
                  (parseFloat(budget.value.toString())).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                }
                <EditBudgetTotalValueDialog
                  budgetId={budget.id}
                  onSave={() => loadPageData(planId, planType)}
                />
              </Styles.InfoTextDarkBold>
            </Styles.InfoContent>
          </Styles.BudgetInfoRow>
        </Styles.HeaderBudgetContainer>
        {
          budget ? (
            <Styles.ContentBudgetContainer>
              <Styles.InfoRow>
                <DateCard
                  title="Data de Entrega"
                  date={budget?.uploadedAt && new Date(budget.uploadedAt)}
                />
              </Styles.InfoRow>
              { budget.budgetDecisionObservation ? (
                <Styles.InfoRow>
                  <Styles.InfoContent>
                    <Styles.InfoTitle>
                      Observação
                    </Styles.InfoTitle>
                    <Styles.ObservationText>
                      {budget.budgetDecisionObservation}
                    </Styles.ObservationText>
                  </Styles.InfoContent>
                </Styles.InfoRow>
              ) : (
                <>
                  <br/>
                  <br/>
                </>
              ) }
              <Styles.InfoRow>
                <FileItem
                  onClick={() => {
                    if (budget.fileUrl) {
                      window.open(budget.fileUrl, "_blank");
                    }
                  }}
                >
                  <InsertDriveFileOutlinedIcon fontSize="small" />
                  <FileItemText>{budget.fileName}</FileItemText>
                </FileItem>
              </Styles.InfoRow>
              <Styles.ButtonInfoRow>
                <ButtonComponent
                  text="Reprovar"
                  type={ButtonType.OUTLINELINK}
                  onClick={() => {
                    openBudgetRejObsModal();
                    setIdSelectedBudget(budget.id);
                  }}
                  startIcon={<CloseIcon />}
                  padding={{
                    left: 24,
                    right: 24,
                    top: 10,
                    bottom: 10,
                  }}
                />
                <ButtonComponent
                  text="Aprovar"
                  type={ButtonType.PRIMARY}
                  onClick={() => {
                    openBudgetApprovObsModal();
                    setIdSelectedBudget(budget.id);
                  }}
                  startIcon={<CheckIcon />}
                  padding={{
                    left: 24,
                    right: 24,
                    top: 10,
                    bottom: 10,
                  }}
                />
              </Styles.ButtonInfoRow>
            </Styles.ContentBudgetContainer>
          ) : (
            <Styles.ContentBudgetContainer>
              <Styles.InfoRow>
                <Styles.InfoTitle>
                  Aguardando envio do orçamento...
                </Styles.InfoTitle>
              </Styles.InfoRow>
            </Styles.ContentBudgetContainer>
          )
        }
      </Styles.BudgetContainer>
    )
  }

  const renderWaitingBudget = (
    index: number | string,
    supplier: Supplier,
    planSupplier: PurchasingPlanSuppliersEntity | ContractingPlanSuppliersEntity,
  ) => {
    return (
      <Styles.BudgetContainer key={index}>
        <Styles.HeaderBudgetContainer>
          <Styles.BudgetInfoRow>
            <Styles.InfoContent>
              <Styles.InfoTitle>Pedido Nº</Styles.InfoTitle>
              <Styles.InfoTextDarkBold>
                {planId}
              </Styles.InfoTextDarkBold>
            </Styles.InfoContent>
            <DateCard
              title="Data do envio"
              date={planSupplier?.createdAt && new Date(planSupplier?.createdAt)}
            />
          </Styles.BudgetInfoRow>
          <Styles.BudgetInfoRow>
            <Styles.InfoContent>
              <Styles.InfoTitle>
                Fornecedor
              </Styles.InfoTitle>
              <Styles.InfoTextDarkBold>
                {supplier.name}
              </Styles.InfoTextDarkBold>
            </Styles.InfoContent>
          </Styles.BudgetInfoRow>
          <Styles.BudgetInfoRow>
            <Styles.InfoContent>
              <Styles.InfoTitle>
                Valor total pedido
              </Styles.InfoTitle>
              <Styles.InfoTextDarkBold fontSize="24px">
                R$ 0,00
              </Styles.InfoTextDarkBold>
            </Styles.InfoContent>
          </Styles.BudgetInfoRow>
        </Styles.HeaderBudgetContainer>
        <Styles.ContentBudgetContainer>
          <Styles.InfoRow>
            <Styles.InfoTitle>
              Aguardando envio do orçamento...
            </Styles.InfoTitle>
          </Styles.InfoRow>
        </Styles.ContentBudgetContainer>
      </Styles.BudgetContainer>
    )
  }

  const renderReprovedBudget = (
    index: number | string,
    supplier: Supplier,
    planSupplier: PurchasingPlanSuppliersEntity | ContractingPlanSuppliersEntity,
    budget: BudgetEntity
  ) => {
    return (
      <Styles.ReprovedBudgetContainer key={index}>
        <Styles.HeaderBudgetContainer>
          <Styles.BudgetInfoRow>
            <Styles.InfoContent>
              <Styles.ReprovedInfoTitle>
                Pedido Nº
              </Styles.ReprovedInfoTitle>
              <Styles.ReprovedInfoTextDarkBold>
                {planId}
              </Styles.ReprovedInfoTextDarkBold>
            </Styles.InfoContent>
            <DateCard
              title="Data do envio"
              date={planSupplier?.createdAt && new Date(planSupplier?.createdAt)}
              color={Colors.alertRed}
              fontColor={Colors.silver}
            />
          </Styles.BudgetInfoRow>
          <Styles.BudgetInfoRow>
            <Styles.InfoContent>
              <Styles.ReprovedInfoTitle>
                Fornecedor
              </Styles.ReprovedInfoTitle>
              <Styles.ReprovedInfoTextDarkBold>
                {supplier.name}
              </Styles.ReprovedInfoTextDarkBold>
            </Styles.InfoContent>
          </Styles.BudgetInfoRow>
          <Styles.BudgetInfoRow>
            <Styles.InfoContent>
              <Styles.ReprovedInfoTitle>
                Valor total pedido
              </Styles.ReprovedInfoTitle>
              <Styles.ReprovedInfoTextDarkBold fontSize="24px">
                {
                  (parseFloat(budget.value.toString() || '0')).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                }
                <EditBudgetTotalValueDialog
                  budgetId={budget.id}
                  onSave={() => loadPageData(planId, planType)}
                />
              </Styles.ReprovedInfoTextDarkBold>
            </Styles.InfoContent>
          </Styles.BudgetInfoRow>
        </Styles.HeaderBudgetContainer>

        <Styles.ContentBudgetContainer>
          <Styles.InfoRow gap="16px">
            <Styles.ReprovedIconInfoContent>
              <WarningAmberOutlinedIcon fontSize="large" />
              <Styles.InfoContent>
                <Styles.ReprovedInfoTitle>
                  Reprovado em
                </Styles.ReprovedInfoTitle>
                <Styles.InfoText>
                  {formatDate(
                    new Date(budget.decisionDate ?? new Date())
                  )}
                </Styles.InfoText>
              </Styles.InfoContent>
            </Styles.ReprovedIconInfoContent>
            <Styles.ReprovedIconInfoContent>
              <Avatar
                alt={
                  purchasingSelectedPlan
                    ?.approveResponsable?.name ||
                  contractingSelectedPlan
                    ?.approveResponsable?.name
                }
                sx={{ width: 26, height: 26 }}
              >
                <Avatar sx={{ width: 26, height: 26, backgroundColor: Colors.primaryColor, fontWeight: 700, fontSize: 'initial' }}>
                  {getInitials(purchasingSelectedPlan?.approveResponsable?.name || '')}
                </Avatar>
              </Avatar>
              <Styles.InfoContent>
                <Styles.ReprovedInfoTitle>
                  Responsável
                </Styles.ReprovedInfoTitle>
                <Styles.InfoText>
                  {purchasingSelectedPlan?.approveResponsable?.name || contractingSelectedPlan?.approveResponsable?.name}
                </Styles.InfoText>
              </Styles.InfoContent>
            </Styles.ReprovedIconInfoContent>
          </Styles.InfoRow>
          <Styles.InfoRow>
            <Styles.InfoContent>
              <Styles.ReprovedInfoTitle>
                Observação
              </Styles.ReprovedInfoTitle>
              <Styles.ReprovedObservationText>
                {budget.budgetDecisionObservation}
              </Styles.ReprovedObservationText>
            </Styles.InfoContent>
          </Styles.InfoRow>
          <Styles.InfoRow>
            <FileItem
              onClick={() => {
                if (budget.fileUrl) {
                  window.open(budget.fileUrl, "_blank");
                }
              }}
            >
              <InsertDriveFileOutlinedIcon fontSize="small" />
              <FileItemText>
                {budget.fileName}
              </FileItemText>
              {/* <DeleteOutlineRoundedIcon
                                          fontSize="small"
                                          onClick={() =>
                                            handleFilesDelete(
                                              filesList[0].id,
                                              planType
                                            )
                                          }
                                        /> */}
            </FileItem>
          </Styles.InfoRow>
          <Styles.ReprovedButtonInfoRow>
            <ButtonComponent
              text="Reprovado"
              type={ButtonType.REPROVED}
              onClick={() => {
              }}
              startIcon={<ThumbDownAltOutlinedIcon />}
              padding={{
                left: 24,
                right: 24,
                top: 10,
                bottom: 10,
              }}
            />
          </Styles.ReprovedButtonInfoRow>
        </Styles.ContentBudgetContainer>
      </Styles.ReprovedBudgetContainer>
    )
  }

  const renderBudgetsList = () => {
    const budgets: JSX.Element[] = [];


    // Process PENDENTE budgets first
    suppliers.forEach((supplier: Supplier, index) => {
      let planSuppliers: PurchasingPlanSuppliersEntity[] | ContractingPlanSuppliersEntity[] = []
      if (supplier.purchasingPlanSuppliers) {
        planSuppliers = supplier.purchasingPlanSuppliers
      } else if (supplier.contractingPlanSuppliers) {
        planSuppliers = supplier.contractingPlanSuppliers
      }

      planSuppliers.forEach(planSupplier => {
        planSupplier.budgets.forEach(budget => {
          if (budget.budgetStatus === BudgetStatusENUM.PENDENTE) {
            budgets.push(renderPendingBudget(`${budget.id}-${BudgetStatusENUM.PENDENTE}`, supplier, planSupplier, budget))
          }
        })
      })
    })

    // Process NO budgets next
    suppliers.forEach((supplier: Supplier, index) => {
      let planSuppliers: PurchasingPlanSuppliersEntity[] | ContractingPlanSuppliersEntity[] = []
      if (supplier.purchasingPlanSuppliers) {
        planSuppliers = supplier.purchasingPlanSuppliers
      } else if (supplier.contractingPlanSuppliers) {
        planSuppliers = supplier.contractingPlanSuppliers
      }

      planSuppliers.forEach(planSupplier => {
        if (!planSupplier.budgets.length) {
          budgets.push(renderWaitingBudget(index, supplier, planSupplier))
        }
      })
    })

    // Process REPROVADO budgets last
    suppliers.forEach((supplier: Supplier, index) => {
      let planSuppliers: PurchasingPlanSuppliersEntity[] | ContractingPlanSuppliersEntity[] = []
      if (supplier.purchasingPlanSuppliers) {
        planSuppliers = supplier.purchasingPlanSuppliers
      } else if (supplier.contractingPlanSuppliers) {
        planSuppliers = supplier.contractingPlanSuppliers
      }

      planSuppliers.forEach(planSupplier => {
        planSupplier.budgets.forEach(budget => {
          if (budget.budgetStatus === BudgetStatusENUM.REPROVADO) {
            budgets.push(renderReprovedBudget(`${budget.id}-${BudgetStatusENUM.REPROVADO}`, supplier, planSupplier, budget))
          }
        })
      })
    })


    return <>{ budgets }</>
  }

  const renderBudgets = () => {
    if (budgets.some(budget => budget.budgetStatus === BudgetStatusENUM.APROVADO)) {
      return renderApprovedBudget()
    } else {
      return renderBudgetsList()
    }
  }

  return (
    <ReactPageBaseComponent>
      <Styles.PageContainer>
        <Styles.ContentContainer>
          <Box
            display={{ md: "flex" }}
            alignItems="center"
            gap="10px"
            mb="40px"
          >
            <ButtonComponent
              text={"Voltar"}
              type={ButtonType.TRANSPARENT}
              startIcon={<ArrowBackSharp />}
              onClick={() => cancelOnClick?.()}
            />
            <Title>Plano de Compras e Contratação</Title>
          </Box>

          <Styles.HeaderContainer isMobile={isMobile}>
            <ButtonComponent
              text={`Há ${filesList.length} arquivos adicionados`}
              type={ButtonType.TRANSPARENT}
              startIcon={<FolderOutlinedIcon />}
              onClick={() => {
                setAlignment("listar");
                toggleModal();
              }}
            />

            <ButtonComponent
              text="Anexar Documentos"
              type={ButtonType.PRIMARY}
              onClick={() => {
                setAlignment("anexar");
                toggleModal();
              }}
              startIcon={<AttachFileIcon />}
              padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
            />
          </Styles.HeaderContainer>

          <Styles.DialogTitle>Status do Pedido #{planId}</Styles.DialogTitle>

          <Timeline steps={steps} />

          <Styles.StatusSection>
            <Styles.StatusContainer>
              <Styles.DialogTitle>Cotações em Andamento</Styles.DialogTitle>

              <Styles.ContrastRow>
                <Styles.InfoRow>
                  <Styles.InfoContent>
                    <Styles.InfoTitle>Pedido Nº</Styles.InfoTitle>
                    <Styles.InfoTextDarkBold>{planId}</Styles.InfoTextDarkBold>
                  </Styles.InfoContent>
                  <Styles.InfoContent>
                    <Styles.InfoTitle>Serviço</Styles.InfoTitle>
                    <Styles.InfoTextBold>
                      {selectedServiceType?.type}
                    </Styles.InfoTextBold>
                  </Styles.InfoContent>
                </Styles.InfoRow>
                <Styles.InfoRow gap="44px">
                  <DateCard
                    title="Abertura do Pedido"
                    date={
                      purchasingSelectedPlan?.supplierQuotationDate ||
                      contractingSelectedPlan?.supplierQuotationDate ||
                      new Date()
                    }
                  />
                  <DateCard
                    title="Limite Aprovação"
                    date={
                      calculateLimitDate(
                        purchasingSelectedPlan?.supplierQuotationDate ||
                          contractingSelectedPlan?.supplierQuotationDate,
                        purchasingSelectedPlan?.supplierQuotationDays ||
                          contractingSelectedPlan?.supplierQuotationDays ||
                          0,
                        purchasingSelectedPlan?.securityDays ||
                          contractingSelectedPlan?.securityDays ||
                          0
                      ) || new Date()
                    }
                  />
                  {/*<ButtonComponent*/}
                  {/*  text={"Visualizar"}*/}
                  {/*  type={ButtonType.TRANSPARENT}*/}
                  {/*  startIcon={<VisibilityOutlinedIcon />}*/}
                  {/*  onClick={() => {*/}
                  {/*    setAlignment("listar");*/}
                  {/*    toggleModal();*/}
                  {/*  }}*/}
                  {/*/>*/}
                </Styles.InfoRow>
              </Styles.ContrastRow>

              <Styles.InfoRow>
                <Styles.InfoContent>
                  <Styles.InfoTitle>Produto</Styles.InfoTitle>
                  <Styles.InfoText>
                    {purchasingSelectedPlan?.name ||
                      contractingSelectedPlan?.name}
                  </Styles.InfoText>
                </Styles.InfoContent>
                <Styles.InfoContent>
                  <Styles.InfoTitle>Quantidade</Styles.InfoTitle>
                  <Styles.InfoText>
                    {purchasingSelectedPlan?.quantity ||
                      contractingSelectedPlan?.quantity}{" "}
                    {purchasingSelectedPlan?.unit ||
                      contractingSelectedPlan?.unit}
                  </Styles.InfoText>
                </Styles.InfoContent>
              </Styles.InfoRow>

              <Styles.InfoRow>
                <Styles.InfoContent>
                  <Styles.InfoTitle>Cotação</Styles.InfoTitle>
                  <Styles.InfoText>
                    {purchasingSelectedPlan?.supplierQuotationDays ||
                      contractingSelectedPlan?.supplierQuotationDays}{" "}
                    dias
                  </Styles.InfoText>
                </Styles.InfoContent>
                <Styles.InfoContent>
                  <Styles.InfoTitle>Compra</Styles.InfoTitle>
                  <Styles.InfoText>
                    {purchasingSelectedPlan?.purchasingLimitDays ||
                      contractingSelectedPlan?.purchasingLimitDays}{" "}
                    dias
                  </Styles.InfoText>
                </Styles.InfoContent>
                <Styles.InfoContent>
                  <Styles.InfoTitle>Fornecimento</Styles.InfoTitle>
                  <Styles.InfoText>
                    {purchasingSelectedPlan?.deliveryLimitDays ||
                      contractingSelectedPlan?.deliveryLimitDays}{" "}
                    dias
                  </Styles.InfoText>
                </Styles.InfoContent>
                <Styles.InfoContent>
                  <Styles.InfoTitle>Margem de Segurança</Styles.InfoTitle>
                  <Styles.InfoText>
                    {purchasingSelectedPlan?.securityDays ||
                      contractingSelectedPlan?.securityDays}{" "}
                    dias
                  </Styles.InfoText>
                </Styles.InfoContent>
                <Styles.InfoContent>
                  <Styles.InfoTitle>Especificações</Styles.InfoTitle>
                  <Styles.InfoText>
                    {purchasingSelectedPlan?.specifications ||
                      contractingSelectedPlan?.specifications}
                  </Styles.InfoText>
                </Styles.InfoContent>
              </Styles.InfoRow>

              <Styles.InfoRow>
                <Styles.InfoContent>
                  <Styles.InfoTitle>Requisitante</Styles.InfoTitle>
                  <Styles.InfoText>
                    {purchasingSelectedPlan?.requesterResponsable?.name ||
                      contractingSelectedPlan?.requesterResponsable?.name}
                  </Styles.InfoText>
                </Styles.InfoContent>
                <Styles.InfoContent>
                  <Styles.InfoTitle>Fornecedores</Styles.InfoTitle>
                  <Styles.InfoText>
                    {suppliers
                      .slice()
                      .sort((a, b) => (a.id ?? 0) - (b.id ?? 0))
                      .map((supplier) => supplier.name)
                      .join(", ") || "Nenhum fornecedor disponível"}
                  </Styles.InfoText>
                </Styles.InfoContent>
              </Styles.InfoRow>
              <Styles.InfoRow>
                {budgets
                  .slice()
                  .sort(
                    (a, b) =>
                      (a.supplier ? a.supplier.id || 0 : 0) -
                      (b.supplier ? b.supplier.id || 0 : 0)
                  )
                  .map((budget: BudgetEntity, idx: number) => (
                    <FileItem
                      key={idx}
                      onClick={() => {
                        if (budget.fileUrl) {
                          window.open(budget.fileUrl, "_blank");
                        }
                      }}
                    >
                      <InsertDriveFileOutlinedIcon fontSize="small" />
                      <FileItemText>{budget.fileName}</FileItemText>
                      {/* <DeleteOutlineRoundedIcon
                        fontSize="small"
                        onClick={() => {
                          handleFilesDelete(budget.id, planType);
                        }}
                      /> */}
                    </FileItem>
                  ))}
              </Styles.InfoRow>
            </Styles.StatusContainer>

            <Styles.StatusContainer>
              <Styles.DialogTitleButton>
                <Styles.DialogTitle>Aprovação do Orçamento</Styles.DialogTitle>
                {
                  (actualPhase === PurchasingPhaseENUM.QUOTATION || actualPhase === ContractingPhaseENUM.QUOTATION) && (
                    <ButtonComponent
                      text="Solicitar nova cotação"
                      type={ButtonType.OUTLINE_GREEN}
                      onClick={() => {
                        handleOpenSuppliersModal()
                      }}
                      startIcon={<AddIcon />}
                      padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                    />
                  )
                }
              </Styles.DialogTitleButton>

              <Styles.BudgetSection>
                { renderBudgets() }
              </Styles.BudgetSection>
            </Styles.StatusContainer>

            {actualPhase === PurchasingPhaseENUM.PURCHASING || actualPhase === ContractingPhaseENUM.CONTRACTING ? (
              <Styles.StatusContainer>
                <Styles.DialogTitle>Efetivação da compra</Styles.DialogTitle>
                <Styles.InfoRow>
                  <DateCard title="Aprovação Limite" date={new Date()} />
                  <DateCard
                    title="Compra Limite"
                    date={
                      (purchasingSelectedPlan?.purchasingDateLimit ||
                        contractingSelectedPlan?.contractingDateLimit) ??
                      new Date()
                    }
                  />
                  <Styles.IconInfoContent>
                    <FmdGoodOutlinedIcon fontSize="large" />
                    <Styles.InfoContent>
                      <Styles.InfoTitle>Fornecedor</Styles.InfoTitle>
                      <Styles.InfoText>{approvedSupplier}</Styles.InfoText>
                    </Styles.InfoContent>
                  </Styles.IconInfoContent>
                  <Styles.InfoRow gap="16px" style={{ marginLeft: "auto" }}>
                    <ButtonComponent
                      text="Reprovar Compra"
                      type={ButtonType.OUTLINELINK}
                      onClick={() => {
                        openPurchaseRejObsModal();
                      }}
                      startIcon={<CloseIcon />}
                      padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                    />
                    <ButtonComponent
                      text="Efetivar compra"
                      type={ButtonType.PRIMARY}
                      onClick={() => {
                        openPurchaseApprovObsModal();
                      }}
                      startIcon={<CheckIcon />}
                      padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                    />
                  </Styles.InfoRow>
                </Styles.InfoRow>
              </Styles.StatusContainer>
            ) : actualPhase === PurchasingPhaseENUM.QUOTATION || actualPhase === ContractingPhaseENUM.QUOTATION ? (
              <Styles.StatusContainer>
                <Styles.DialogTitle>Efetivação da compra</Styles.DialogTitle>
                <Styles.StatusText>Aguardando...</Styles.StatusText>
              </Styles.StatusContainer>
            ) : (
              actualPhase === PurchasingPhaseENUM.ON_APPROVAL_RECEIPT ||
              actualPhase === ContractingPhaseENUM.ON_APPROVAL_RECEIPT ||
              actualPhase === PurchasingPhaseENUM.TOTALLY_COMPLETED ||
              actualPhase === ContractingPhaseENUM.TOTALLY_COMPLETED ||
              actualPhase === PurchasingPhaseENUM.FINISHED ||
              actualPhase === ContractingPhaseENUM.FINISHED
            ) ? (
              purchaseConfirmationStatus === OrderStatusEnum.APROVADO && (
                <Styles.StatusContainer>
                  <Styles.DialogTitle>
                    Efetivação da compra
                  </Styles.DialogTitle>
                  <Styles.InfoRow>
                    <DateCard title="Aprovação Limite" date={new Date()} />
                    <DateCard
                      title="Compra Limite"
                      date={
                        (purchasingSelectedPlan?.purchasingDateLimit ||
                          contractingSelectedPlan?.contractingDateLimit) ??
                        new Date()
                      }
                    />
                    <Styles.IconInfoContent>
                      <FmdGoodOutlinedIcon fontSize="large" />
                      <Styles.InfoContent>
                        <Styles.InfoTitle>Fornecedor</Styles.InfoTitle>
                        <Styles.InfoText>{approvedSupplier}</Styles.InfoText>
                      </Styles.InfoContent>
                    </Styles.IconInfoContent>
                  </Styles.InfoRow>
                  <Styles.InfoRow>
                    <Styles.InfoContent>
                      <Styles.InfoTitle>
                        Observações do Aprovador
                      </Styles.InfoTitle>
                      <Styles.ObservationText>
                        {approvedBudget?.purchaseDecisionObservation}
                      </Styles.ObservationText>
                    </Styles.InfoContent>
                    <Styles.InfoRow gap="16px" style={{ marginLeft: "auto" }}>
                      <UserInfo
                        Date={formatDate(
                          new Date(approvedBudget?.decisionDate ?? new Date())
                        )}
                        UserName={
                          purchasingSelectedPlan?.purchasingResponsable
                            ?.name ||
                          contractingSelectedPlan?.purchasingResponsable
                            ?.name
                        }
                        action="Aprovado"
                      />
                      <ButtonComponent
                        text="Efetivada a Compra"
                        type={ButtonType.APPROVED}
                        onClick={() => {}}
                        startIcon={<ThumbUpAltOutlinedIcon />}
                        padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                      />
                    </Styles.InfoRow>
                  </Styles.InfoRow>
                </Styles.StatusContainer>
              )
              // )
            ) : (
              <Styles.StatusContainer>
                <Styles.DialogTitle>Efetivação da compra</Styles.DialogTitle>
                <Styles.StatusText>Etapa cancelada </Styles.StatusText>
              </Styles.StatusContainer>
            )}

            {actualPhase === PurchasingPhaseENUM.ON_APPROVAL_RECEIPT || actualPhase == ContractingPhaseENUM.ON_APPROVAL_RECEIPT ? (
              <Styles.StatusContainer>
                <Styles.DialogTitle>Aprovação do Recebimento</Styles.DialogTitle>
                <Styles.InfoRow>
                  <DateCard title="Data de Entrega" date={new Date()} />
                  <DateCard title="Entregado em" date={new Date()} />
                  <Styles.IconInfoContent>
                    <FmdGoodOutlinedIcon fontSize="large" />
                    <Styles.InfoContent>
                      <Styles.InfoTitle>Fornecedor</Styles.InfoTitle>
                      <Styles.InfoText>{approvedSupplier}</Styles.InfoText>
                    </Styles.InfoContent>
                  </Styles.IconInfoContent>
                  <Styles.InfoRow gap="16px" style={{ marginLeft: "auto" }}>
                    <ButtonComponent
                      text="Reprovar Recebimento"
                      type={ButtonType.OUTLINELINK}
                      onClick={() => {
                        openReceiptRejObsModal();
                      }}
                      startIcon={<CloseIcon />}
                      padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                    />
                    <ButtonComponent
                      text="Aprovar Recebimento"
                      type={ButtonType.PRIMARY}
                      onClick={() => {
                        openReceiptApprovObsModal();
                      }}
                      startIcon={<CheckIcon />}
                      padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                    />
                  </Styles.InfoRow>
                </Styles.InfoRow>
              </Styles.StatusContainer>
            ) : (actualPhase === PurchasingPhaseENUM.QUOTATION
              || actualPhase === PurchasingPhaseENUM.PURCHASING
              || actualPhase === ContractingPhaseENUM.QUOTATION
              || actualPhase === ContractingPhaseENUM.CONTRACTING
            ) ? (
              <Styles.StatusContainer>
                <Styles.DialogTitle>
                  Aprovação do Recebimento
                </Styles.DialogTitle>
                <Styles.StatusText>Aguardando...</Styles.StatusText>
              </Styles.StatusContainer>
            ) : actualPhase === PurchasingPhaseENUM.TOTALLY_COMPLETED || actualPhase === ContractingPhaseENUM.TOTALLY_COMPLETED ? (
              <Styles.StatusContainer>
                <Styles.DialogTitle>
                  Aprovação do Recebimento
                </Styles.DialogTitle>
                <Styles.InfoRow>
                  <DateCard title="Aprovação Limite" date={new Date()} />
                  <DateCard
                    title="Compra Limite"
                    date={
                      (purchasingSelectedPlan?.purchasingDateLimit ||
                        contractingSelectedPlan?.contractingDateLimit) ??
                      new Date()
                    }
                  />
                  <Styles.IconInfoContent>
                    <FmdGoodOutlinedIcon fontSize="large" />
                    <Styles.InfoContent>
                      <Styles.InfoTitle>Fornecedor</Styles.InfoTitle>
                      <Styles.InfoText>{approvedSupplier}</Styles.InfoText>
                    </Styles.InfoContent>
                  </Styles.IconInfoContent>
                </Styles.InfoRow>
                <Styles.InfoRow>
                  <Styles.InfoContent>
                    <Styles.InfoTitle>
                      Observações do Aprovador
                    </Styles.InfoTitle>
                    <Styles.ObservationText>
                      {approvedBudget?.receiptDecisionObservation}
                    </Styles.ObservationText>
                  </Styles.InfoContent>
                  <Styles.InfoRow gap="16px" style={{ marginLeft: "auto" }}>
                    <UserInfo
                      Date={formatDate(
                        new Date(
                          purchasingSelectedPlan?.approveReceiptFinishedDate || contractingSelectedPlan?.approveReceiptFinishedDate || new Date()) // TODO: pegar data da aprovação do recebimento
                      )}
                      UserName={
                        purchasingSelectedPlan?.finishPlanResponsable
                          ?.name ||
                        contractingSelectedPlan?.finishPlanResponsable
                          ?.name
                      }
                      action="Aprovado"
                    />
                    <ButtonComponent
                      text="Recebimento Aprovado"
                      type={ButtonType.APPROVED}
                      onClick={() => {}}
                      startIcon={<ThumbUpAltOutlinedIcon />}
                      padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                    />
                  </Styles.InfoRow>
                </Styles.InfoRow>
              </Styles.StatusContainer>
            ) : (
              <Styles.StatusContainer>
                <Styles.DialogTitle>
                  Aprovação do Recebimento
                </Styles.DialogTitle>
                <Styles.StatusText>Etapa cancelada </Styles.StatusText>
              </Styles.StatusContainer>
            )}
          </Styles.StatusSection>

          {/*  Modais de aprovação/reprovação do orçamento */}
          <Modal
            open={isBudgetRejObsModalOpen}
            onClose={closeBudgetRejObsModal}
            keepMounted
          >
            <Styles.ObsModalContent>
              <Styles.ModalObsHeader>
                <Styles.ObsModalTitle>Reprovar Orçamento</Styles.ObsModalTitle>
                <CloseButton onClick={closeBudgetRejObsModal}>X</CloseButton>
              </Styles.ModalObsHeader>
              <Styles.ModalSubtitle>Observações</Styles.ModalSubtitle>
              <TextField
                required
                multiline={true}
                rows={5}
                variant="filled"
                size="medium"
                placeholder="Inserir"
                value={modalBudgetText}
                sx={modalTextFieldStyle}
                onChange={(e) => {
                  setModalBudgetText(e.target.value);
                }}
              />
              <Styles.InfoRow
                gap="16px"
                style={{ marginLeft: "auto", marginTop: "22px" }}
              >
                <ButtonComponent
                  text="Cancelar"
                  type={ButtonType.OUTLINELINK}
                  onClick={() => {
                    closeBudgetRejObsModal();
                    clearModalData();
                  }}
                  startIcon={<CloseIcon />}
                  padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                />
                <ButtonComponent
                  text="Salvar"
                  type={ButtonType.PRIMARY}
                  onClick={() => {
                    if (idSelectedBudget) {
                      reproveBudget(idSelectedBudget, modalBudgetText);
                    }
                    clearModalData();
                  }}
                  startIcon={<CheckIcon />}
                  padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                />
              </Styles.InfoRow>
            </Styles.ObsModalContent>
          </Modal>

          <Modal
            open={isBudgetApprovObsModalOpen}
            onClose={closeBudgetApprovObsModal}
            keepMounted
          >
            <Styles.ObsModalContent>
              <Styles.ModalObsHeader>
                <Styles.ObsModalTitle>Aprovar Orçamento</Styles.ObsModalTitle>
                <CloseButton onClick={closeBudgetApprovObsModal}>X</CloseButton>
              </Styles.ModalObsHeader>
              <Styles.ModalSubtitle>Observações</Styles.ModalSubtitle>
              <TextField
                required
                multiline={true}
                rows={5}
                variant="filled"
                size="medium"
                placeholder="Inserir"
                value={modalBudgetText}
                sx={modalTextFieldStyle}
                onChange={(e) => {
                  setModalBudgetText(e.target.value);
                }}
              />
              <Styles.InfoRow
                gap="16px"
                style={{ marginLeft: "auto", marginTop: "22px" }}
              >
                <ButtonComponent
                  text="Cancelar"
                  type={ButtonType.OUTLINELINK}
                  onClick={() => {
                    closeBudgetApprovObsModal();
                    clearModalData();
                  }}
                  startIcon={<CloseIcon />}
                  padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                />
                <ButtonComponent
                  text="Salvar"
                  type={ButtonType.PRIMARY}
                  onClick={() => {
                    if (idSelectedBudget) {
                      approveBudget(idSelectedBudget, modalBudgetText);
                    }
                    clearModalData();
                  }}
                  startIcon={<CheckIcon />}
                  padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                />
              </Styles.InfoRow>
            </Styles.ObsModalContent>
          </Modal>

          <Modal
            open={isRevertApprovBudgetModalOpen}
            onClose={closeRevertBudgetApprovObsModal}
            keepMounted
          >
            <Styles.ObsModalContent>
              <Styles.ModalObsHeader>
                <Styles.ObsModalTitle>Desfazer Aprovação do Orçamento</Styles.ObsModalTitle>
                <CloseButton onClick={closeRevertBudgetApprovObsModal}>X</CloseButton>
              </Styles.ModalObsHeader>
              <Styles.ModalSubtitle>Observações</Styles.ModalSubtitle>
              <TextField
                required
                multiline={true}
                rows={5}
                variant="filled"
                size="medium"
                placeholder="Inserir"
                value={modalBudgetText}
                sx={modalTextFieldStyle}
                onChange={(e) => {
                  setModalBudgetText(e.target.value);
                }}
              />
              <Styles.InfoRow
                gap="16px"
                style={{ marginLeft: "auto", marginTop: "22px" }}
              >
                <ButtonComponent
                  text="Cancelar"
                  type={ButtonType.OUTLINELINK}
                  onClick={() => {
                    closeRevertBudgetApprovObsModal()
                    clearModalData();
                  }}
                  startIcon={<CloseIcon />}
                  padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                />
                <ButtonComponent
                  text="Salvar"
                  type={ButtonType.PRIMARY}
                  onClick={() => {
                    if (approvedBudget) {
                      revertBudgetApproval(approvedBudget.id, modalBudgetText)
                    }
                  }}
                  startIcon={<CheckIcon />}
                  padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                />
              </Styles.InfoRow>
            </Styles.ObsModalContent>
          </Modal>

          {/*  Modais de aprovação/reprovação de Compra */}
          <Modal
            open={isPurchaseRejObsModalOpen}
            onClose={closePurchaseRejObsModal}
            keepMounted
          >
            <Styles.ObsModalContent>
              <Styles.ModalObsHeader>
                <Styles.ObsModalTitle>Reprovar Compra</Styles.ObsModalTitle>
                <CloseButton onClick={closePurchaseRejObsModal}>X</CloseButton>
              </Styles.ModalObsHeader>
              <Styles.ModalSubtitle>Observações</Styles.ModalSubtitle>
              <TextField
                required
                multiline={true}
                rows={5}
                variant="filled"
                size="medium"
                placeholder="Inserir"
                value={modalPurchaseText}
                sx={modalTextFieldStyle}
                onChange={(e) => {
                  setModalPurchaseText(e.target.value);
                }}
              />
              <Styles.InfoRow
                gap="16px"
                style={{ marginLeft: "auto", marginTop: "22px" }}
              >
                <ButtonComponent
                  text="Cancelar"
                  type={ButtonType.OUTLINELINK}
                  onClick={() => {
                    closePurchaseRejObsModal();
                  }}
                  startIcon={<CloseIcon />}
                  padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                />
                <ButtonComponent
                  text="Salvar"
                  type={ButtonType.PRIMARY}
                  onClick={() => {
                    if (approvedBudget?.id) {
                      reprovePurchase(approvedBudget.id, modalPurchaseText);
                    }
                    clearModalData();
                    closePurchaseRejObsModal();
                    openRejObsSavedModal();
                    // setPurchaseConfirmationStatus(OrderStatusEnum.REPROVADO);
                  }}
                  startIcon={<CheckIcon />}
                  padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                />
              </Styles.InfoRow>
            </Styles.ObsModalContent>
          </Modal>

          <Modal
            open={isPurchaseApprovObsModalOpen}
            onClose={closePurchaseApprovObsModal}
            keepMounted
          >
            <Styles.ObsModalContent>
              <Styles.ModalObsHeader>
                <Styles.ObsModalTitle>Aprovar Compra</Styles.ObsModalTitle>
                <CloseButton onClick={closePurchaseApprovObsModal}>
                  X
                </CloseButton>
              </Styles.ModalObsHeader>
              <Styles.ModalSubtitle>Observações</Styles.ModalSubtitle>
              <TextField
                required
                multiline={true}
                rows={5}
                variant="filled"
                size="medium"
                placeholder="Inserir"
                value={modalPurchaseText}
                sx={modalTextFieldStyle}
                onChange={(e) => {
                  setModalPurchaseText(e.target.value);
                }}
              />
              <Styles.InfoRow
                gap="16px"
                style={{ marginLeft: "auto", marginTop: "22px" }}
              >
                <ButtonComponent
                  text="Cancelar"
                  type={ButtonType.OUTLINELINK}
                  onClick={() => {
                    closePurchaseApprovObsModal();
                  }}
                  startIcon={<CloseIcon />}
                  padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                />
                <ButtonComponent
                  text="Salvar"
                  type={ButtonType.PRIMARY}
                  onClick={() => {
                    if (approvedBudget?.id) {
                      approvePurchase(approvedBudget.id, modalPurchaseText);
                    }
                    clearModalData();
                    closePurchaseApprovObsModal();
                    openPurchaseApprovObsSavedModal();
                    // setPurchaseConfirmationStatus(OrderStatusEnum.APROVADO);
                  }}
                  startIcon={<CheckIcon />}
                  padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                />
              </Styles.InfoRow>
            </Styles.ObsModalContent>
          </Modal>

          {/*  Modais de aprovação/reprovação do Recebimento */}
          <Modal
            open={isReceiptRejObsModalOpen}
            onClose={closeReceiptRejObsModal}
            keepMounted
          >
            <Styles.ObsModalContent>
              <Styles.ModalObsHeader>
                <Styles.ObsModalTitle>
                  Reprovar Recebimento
                </Styles.ObsModalTitle>
                <CloseButton onClick={closeReceiptRejObsModal}>X</CloseButton>
              </Styles.ModalObsHeader>
              <Styles.ModalSubtitle>Observações</Styles.ModalSubtitle>
              <TextField
                required
                multiline={true}
                rows={5}
                variant="filled"
                size="medium"
                placeholder="Inserir"
                value={modalReceiptText}
                sx={modalTextFieldStyle}
                onChange={(e) => {
                  setModalReceiptText(e.target.value);
                }}
              />
              <Styles.InfoRow
                gap="16px"
                style={{ marginLeft: "auto", marginTop: "22px" }}
              >
                <ButtonComponent
                  text="Cancelar"
                  type={ButtonType.OUTLINELINK}
                  onClick={() => {
                    closeReceiptRejObsModal();
                  }}
                  startIcon={<CloseIcon />}
                  padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                />
                <ButtonComponent
                  text="Salvar"
                  type={ButtonType.PRIMARY}
                  onClick={() => {
                    if (approvedBudget?.id) {
                      reproveReceipt(approvedBudget.id, modalReceiptText);
                    }
                    clearModalData();
                    closeReceiptRejObsModal();
                    openRejObsSavedModal();
                  }}
                  startIcon={<CheckIcon />}
                  padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                />
              </Styles.InfoRow>
            </Styles.ObsModalContent>
          </Modal>

          <Modal
            open={isReceiptApprovObsModalOpen}
            onClose={closeReceiptApprovObsModal}
            keepMounted
          >
            <Styles.ObsModalContent>
              <Styles.ModalObsHeader>
                <Styles.ObsModalTitle>Aprovar Recebimento</Styles.ObsModalTitle>
                <CloseButton onClick={closeReceiptApprovObsModal}>
                  X
                </CloseButton>
              </Styles.ModalObsHeader>
              <Styles.ModalSubtitle>Observações</Styles.ModalSubtitle>
              <TextField
                required
                multiline={true}
                rows={5}
                variant="filled"
                size="medium"
                placeholder="Inserir"
                value={modalReceiptText}
                sx={modalTextFieldStyle}
                onChange={(e) => {
                  setModalReceiptText(e.target.value);
                }}
              />
              <Styles.InfoRow
                gap="16px"
                style={{ marginLeft: "auto", marginTop: "22px" }}
              >
                <ButtonComponent
                  text="Cancelar"
                  type={ButtonType.OUTLINELINK}
                  onClick={() => {
                    closeReceiptApprovObsModal();
                  }}
                  startIcon={<CloseIcon />}
                  padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                />
                <ButtonComponent
                  text="Salvar"
                  type={ButtonType.PRIMARY}
                  onClick={() => {
                    if (approvedBudget?.id) {
                      approveReceipt(approvedBudget.id, modalReceiptText);
                    }
                    clearModalData();
                    closeReceiptApprovObsModal();
                    openReceiptApprovObsSavedModal();
                  }}
                  startIcon={<CheckIcon />}
                  padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                />
              </Styles.InfoRow>
            </Styles.ObsModalContent>
          </Modal>

          <Modal open={isModalOpen} onClose={closeModal} keepMounted>
            <ModalContent>
              <ModalHeader>
                <ModalTitle>Documentos</ModalTitle>
                <CloseButton onClick={closeModal}>X</CloseButton>
              </ModalHeader>

              <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
              >
                <ModalToggleButton value="anexar">
                  {alignment === "anexar" && (
                    <CheckIcon
                      fontSize="inherit"
                      style={{ marginRight: "8px" }}
                    />
                  )}
                  Anexar Arquivo
                </ModalToggleButton>
                <ModalToggleButton value="listar">
                  {alignment === "listar" && (
                    <CheckIcon
                      fontSize="inherit"
                      style={{ marginRight: "8px" }}
                    />
                  )}
                  Lista de Arquivos
                </ModalToggleButton>
              </ToggleButtonGroup>

              {alignment === "anexar" && (
                <ModalForm>
                  <ModalFormRow>
                    <DropDownComponent
                      values={fileCategories.map(category => category.name)}
                      key="1"
                      variant="filled"
                      sx={modalSelectStyle}
                      selectedValue={selectedCategory}
                      label={"Categoria"}
                      defaultValue={"Categoria"}
                      onSelectedValueChanged={(key: any, value: any) => {
                        handleCategoryChange(value);
                      }}
                    />
                  </ModalFormRow>

                  <ModalFormRow>
                    <DropDownComponent
                      values={(
                        fileCategories
                          .filter(category => category.name === selectedCategory)[0]
                          ?.subCategories.map(sub => sub.name) || []
                      )}
                      key="1"
                      variant="filled"
                      sx={modalSelectStyle}
                      selectedValue={selectedType}
                      label={"Tipo"}
                      defaultValue={"Tipo"}
                      onSelectedValueChanged={(key: any, value: any) => {
                        handleTypeChange(value);
                      }}
                    />
                  </ModalFormRow>

                  <ModalFormRow>
                    <TextField
                      // helperText="Ex: Número da Nota, título do arquivo, etc"
                      label="Identificação"
                      variant="filled"
                      size="medium"
                      sx={modalTextFieldStyle}
                      select={false}
                      onChange={handleIdentificationChange}
                    />
                    <FormHelperText>Selecione um arquivo PDF de até 15 MB</FormHelperText>
                  </ModalFormRow>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    accept=".pdf"
                    onChange={handleFileUpload}
                  />
                </ModalForm>
              )}

              {alignment === "listar" && (
                <ListFilesContainer>
                  {filesList
                    .slice()
                    .reverse()
                    .map((file: FileProps, idx) => (
                      <FileItem key={idx}>
                        <InsertDriveFileOutlinedIcon fontSize="small" />
                        <FileItemText>{file.fileName}</FileItemText>
                        <DeleteOutlineRoundedIcon
                          fontSize="small"
                          onClick={() => {
                            handleFilesDelete(file.id, planType);
                          }}
                        />
                      </FileItem>
                    ))}
                </ListFilesContainer>
              )}

              <ModalButtonRow>
                <ButtonComponent
                  text="Buscar Arquivo"
                  type={ButtonType.PRIMARY}
                  state={
                    selectedCategory === "" ||
                    selectedType === "" ||
                    identification === ""
                      ? ButtonState.DISABLED
                      : ButtonState.DEFAULT_ENABLED
                  }
                  onClick={() => {
                    if (alignment === "listar") {
                      setAlignment("anexar");
                    } else {
                      const fileInput = document.getElementById(
                        "fileInput"
                      ) as HTMLInputElement | null;
                      if (fileInput) {
                        fileInput.click();
                      }
                    }
                  }}
                  startIcon={<SearchIcon />}
                  padding={{
                    left: 24,
                    right: 24,
                    top: 10,
                    bottom: 10,
                  }}
                />
              </ModalButtonRow>
            </ModalContent>
          </Modal>

          <LoadingModal
            isModalOpen={isLoadingModalOpen}
            closeModal={closeLoadingModal}
            progress={progress}
          />

          <PurchasingAndContractingListUploadSuccessModal
            isModalFilesOpen={isModalFilesOpen}
            closeFilesModal={closeFilesModal}
            text="Documentos anexados com sucesso!"
          />

          <PurchasingAndContractingListUploadSuccessModal
            isModalFilesOpen={isRejObsSavedModalOpen}
            closeFilesModal={closeRejObsSavedModal}
            text="Observação salva com sucesso!"
          />

          <PurchasingAndContractingListUploadSuccessModal
            isModalFilesOpen={isRevertApprovBudgetObsSavedModalOpen}
            closeFilesModal={closeRevertApprovBudgetObsSavedModal}
            text="Aprovação do orçamento desfeita com sucesso!"
          />

          <PurchasingAndContractingListUploadSuccessModal
            isModalFilesOpen={isBudgetApprovObsSavedModalOpen}
            closeFilesModal={closeBudgetApprovObsSavedModal}
            text="Aprovação do Orçamento concluída com sucesso!"
          />

          <PurchasingAndContractingListUploadSuccessModal
            isModalFilesOpen={isPurchaseApprovObsSavedModalOpen}
            closeFilesModal={closePurchaseApprovObsSavedModal}
            text="Efetivação da compra concluída com sucesso!"
          />

          <PurchasingAndContractingListUploadSuccessModal
            isModalFilesOpen={isReceiptApprovObsSavedModalOpen}
            closeFilesModal={closeReceiptApprovObsSavedModal}
            text="Compra recebida com sucesso!"
          />
          <Footer />
        </Styles.ContentContainer>
        <Alert
          onClose={() => setAlert({ title: "", message: "", isOpened: false })}
          isOpened={alert.isOpened}
          title={alert.title}
          message={alert.message}
          buttonLabel="Sair"
        />

        <PurchasingAndContractingSuppliersModal
          toggleModal={handleOpenSuppliersModal}
          open={isSuppliersModalOpen}
          handleSubmit={(suppliers: number[]) => {
            requestNewQuoting(suppliers)
          }}
        />

      </Styles.PageContainer>
    </ReactPageBaseComponent>
  );
};

export default PurchasingContractingPlanOrderDetail;
