import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { spacing } from "@mui/system";
import { green } from "@mui/material/colors";

import {
  Box as MuiBox,
  Chip,
  Drawer as MuiDrawer,
  ListItemButton,
} from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import { SidebarItemsType } from "../../types/sidebar";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import { Client, Construction, appVersion } from "../../config/app-info";
import DropDownComponent from "../InputValues/DropDownComponent";
import { useDashboard } from "../../contexts/dashboard";
import { Colors } from "../../theme/variants";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";
import { set } from "date-fns";
import SidebarNavListItem from "./SidebarNavListItem";
import { Users, UserPlus, LogOut, BarChart2 } from "react-feather";

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 32px;
  height: 32px;
`;

const BrandChip = styled(Chip)`
  background-color: ${green[700]};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 55%;
  height: 18px;
  margin-left: 2px;
  margin-top: -16px;
  padding: 3px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)};
    padding-right: ${(props) => props.theme.spacing(1.375)};
  }
`;

const DropDownContainer = styled.div`
  display: grid;
  row-gap: 16px;
  background-color: #233044;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
`;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  background-color: ${(props) => props.theme.sidebar.background};
  width: 135px;
`;

export type SidebarProps = {
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: "permanent" | "persistent" | "temporary";
  open?: boolean;
  onClose?: () => void;
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  showFooter?: boolean;
  onDrawerToggle: () => void;
};

const Sidebar: React.FC<SidebarProps> = ({
  items,
  showFooter = true,
  onDrawerToggle,
  ...rest
}) => {
  const {
    clientId,
    setClientId,
    constructionId,
    setConstructionId,
    setConstructions,
    constructions,
    setClients,
    clients,
  } = useDashboard();

  const [isMobile, setIsMobile] = useState<boolean>();
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedConstruction, setSelectedConstruction] = useState("");

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    if (isMobile === undefined) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (clientId === 0) {
      return;
    }

    const clientName = clients.filter((client) => client.id === clientId)[0];

    if (!clientName || !clientName.name) {
      return;
    }
    setSelectedClient(clientName.name);
  }, [clientId, clients]);

  useEffect(() => {
    if (constructionId === 0) {
      return;
    }

    const constructionName = constructions.filter(
      (construction) => construction.id === constructionId
    )[0];

    if (!constructionName || !constructionName.name) {
      return;
    }

    setSelectedConstruction(constructionName.name);
  }, [constructionId, constructions]);

  const handleChangeClient = async (client: number) => {
    setClientId(client);
  };

  const handleChangeConstruction = async (construction: number) => {
    setConstructionId(construction);
  };

  return (
    // <Drawer variant="permanent" {...rest}>
    <SidebarContainer>
      {/* <Brand component={NavLink as any} to="/dashboard/">
        <BrandIcon />{" "}
        <Box ml={1}>
          GoBuilder <BrandChip label={`versão ${appVersion}`} />
        </Box>
      </Brand> */}
      {isMobile && (
        <DropDownContainer>
          <DropDownComponent
            defaultValue="Cliente"
            label="Cliente"
            key="clients"
            isWhite={true}
            onSelectedValueChanged={(key, value) =>
              handleChangeClient(
                clients.filter((client) => client.name === value)[0].id
              )
            }
            values={clients.map((client) => client.name)}
            selectedValue={selectedClient}
          />
          <DropDownComponent
            defaultValue="Obra"
            label="Obra"
            key="obra"
            isWhite={true}
            onSelectedValueChanged={(key, value) => {
              handleChangeConstruction(
                constructions.filter(
                  (construction) => construction.name === value
                )[0].id
              );
            }}
            values={constructions.map((construction) => construction.name)}
            selectedValue={selectedConstruction}
          />
        </DropDownContainer>
      )}

      <SidebarNav items={items} onDrawerToggle={onDrawerToggle} />
      <SidebarNavListItem href="/sair/" icon={LogOut} title="Sair" depth={0} />
      {/* {!!showFooter && <Footer />} */}
    </SidebarContainer>
    // </Drawer>
  );
};

export default Sidebar;
