import styled from "styled-components";
import { Colors } from "../../theme/v2/variants";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";
import typography from "../../theme/v2/typography";

export const HeaderContainer = styled.div`
  display: grid;
  max-width: 100%;
  padding: 41px 32px;
  align-items: center;
  background-color: ${Colors.primaryColorLight};

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 2fr 1fr;
    align-content: start;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-rows: max-content max-content;
  }
`;

export const AboutContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  margin-left: 14px;
`;

export const ConstructionImage = styled.img`
  background-color: ${Colors.neutralColorWhite};
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: block;
  object-fit: cover;
`;

export const ConstructionImagePlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.primaryDarkBlue};
  color: ${Colors.secondaryColorLight};
  min-width: 70px;
  min-height: 70px;
  border-radius: 50%;
  object-fit: cover;
  font-size: ${typography.h1?.fontSize};
  font-weight: ${typography.fontWeightBold};
  text-transform: uppercase;
  user-select: none;
`;

export const PageTitle = styled.div`
  font-size: 16px;
  font-family: "Noto Sans";
  font-weight: 400;
  word-wrap: break-word;
  line-height: 21.79px;
  color: ${Colors.neutralColorWhite};
`;

export const ConstructionNameTitle = styled.div`
  font-size: 32px;
  font-family: "Noto Sans";
  font-weight: 700;
  word-wrap: break-word;
  line-height: 43.58px;
  color: ${Colors.neutralColorWhite};
`;
