import styled from "styled-components";
import { Colors } from "../../theme/v2/variants";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";
import { Tab } from "@mui/material";

const CenterContainer = styled.div`
  display: grid;
  height: 85vh;
  align-content: center;
`;

const PageContainer = styled.div`
  display: grid;
  //height: calc(100vh);
  width: 100%;
  min-height: 90vh;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  background-color: ${Colors.neutralColorSurface};
  /* overflow: visible; */
  position: relative;
  align-content: start;
`;

const TitleContainer = styled.div`
  display: grid;
  max-width: 100%;
  grid-template-columns: 1fr max-content max-content;
  /* margin-top: 112px; */
  column-gap: 24px;
  align-items: center;
  /* margin-right: 32px;
  margin-left: 32px; */
`;

const Description = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: ${Colors.textColorGray};
  margin-top: 5px;
  /* padding-left: 32px; */
  max-width: 100%;
  height: fit-content;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-left: 16px;
    padding-right: 32px;
    // go to next-line if the text is too long
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
   margin-bottom: 150px;
`;

const TabContainer = styled.div`
  margin-top: 32px;
  /* margin-left: 32px; */

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

const ListPurchasingContractionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  /* margin-left: 32px; */
  /* margin-right: 32px; */
  //overflow: hidden;
  //height: 100vh;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 16px;
    margin-right: 16px;
    max-width: 100%;
    overflow-x: auto;
  }
`;

const ListContainer = styled.div`
  height: 100%;
  width: 100%;
  //overflow-y: auto;

  //&::-webkit-scrollbar {
  //  display: none;
  //}

  /* Para navegadores Firefox */
  //-ms-overflow-style: none;
  //scrollbar-width: none;
`;

const MobileMainButtonContainer = styled.div`
  width: 101px;
  place-self: end;
  margin-top: 24px;
`;

const CustomTab = styled((props) => <Tab {...props} />)(() => ({
  fontFamily: "Noto Sans",
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: "normal",
  fontWeight: 400,
  paddingLeft: 48,
  paddingRight: 48,
  color: `${Colors.LinkColor}`,
  "&.Mui-selected": {
    color: `${Colors.primaryColorDark}`,
    fontWeight: 700,
  },
}));

const EmptyListContainer = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: ${Colors.textColorBlack};
  width: 454px;
  place-self: center;
  margin-top: 146px;
  text-align: center;
`;

export {
  CenterContainer,
  PageContainer,
  TitleContainer,
  Description,
  ContentContainer,
  TabContainer,
  CustomTab,
  ListPurchasingContractionContainer,
  MobileMainButtonContainer,
  EmptyListContainer,
  ListContainer,
};
