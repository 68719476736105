import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Box, Paper, Typography } from "@mui/material";
import logo from "./../../assets/logo.svg";
import building from "./../../assets/images/building.png";
import FormLogin from "../../components/FormLogin";
import { Colors } from "../../theme/v2/variants";
import typography from "../../theme/v2/typography";

function Login() {
  return (
    <React.Fragment>
      <Wrapper>
        <Helmet title="Bem vindo" />
        <Wallpaper src={building} />
        <Container>
          <ImageContainer />
          <FormContainer>
            <Box
              padding="50px"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Brand src={logo} />
              <Typography
                align="center"
                marginTop="30px"
                fontWeight={typography.fontWeightBold}
                fontSize={typography.h5?.fontSize}
                color={Colors.primaryColor}
                gutterBottom
              >
                Bem-vindo de volta!
              </Typography>
              <FormLogin />
            </Box>
          </FormContainer>
        </Container>
      </Wrapper>
    </React.Fragment>
  );
}

export default Login;

const Brand = styled.img`
  width: auto;
  height: 50px;
  margin-bottom: 10px;
`;

const Wrapper = styled(Paper)``;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 2fr 1fr;
  overflow: hidden;
  position: relative;

  @media (max-width: 1200px) {
    grid-template-columns: 2fr 2fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 5;
  position: relative;
  z-index: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Wallpaper = styled.img`
  width: 90%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;

  @media (max-width: 1200px) {
    height: 100%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 1;
  padding: 20px;
`;
