import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import SearchIcon from "@mui/icons-material/Search";
import { api } from "../../services/api";
import { User } from "../../config/app-info";
import { useParams } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../Button/ButtonComponent";
import { FileUploadOutlined } from "@mui/icons-material";

type Props = {
  oneDate?: boolean;
  callReport: (
    dtBegin: string,
    dtEnd: string,
    filterDateType: string,
    filterCriteria: string[],
    filterResponsibleUser: string[],
    filterStatus: string[]
  ) => void;
  hasFilterCriteria?: boolean;
  hasFilterResponsibleUser?: boolean;
  hasFilterStatus?: boolean;
  setShowPdf: React.Dispatch<React.SetStateAction<boolean>>;
  actionsByCriteria: any[];
  showPdf: boolean;
};

const SearchBar: React.FC<Props> = ({
  oneDate,
  callReport,
  hasFilterCriteria,
  hasFilterResponsibleUser,
  hasFilterStatus,
  setShowPdf,
  actionsByCriteria,
  showPdf,
}) => {
  const [dtBegin, setDtBegin] = useState<Date | null>(null);
  const [dtEnd, setDtEnd] = useState<Date | null>(null);
  const [responsibleUsers, setResponsibleUsers] = useState<User[]>([]);
  const [criteria, setCriteria] = useState<User[]>([]);
  const [filterDateType, setFilterDateType] = useState("1");
  const [filterCriteria, setFilterCriteria] = useState<string[]>([]);
  const [filterResponsibleUser, setFilterResponsibleUser] = React.useState<
    string[]
  >([]);
  const [filterStatus, setFilterStatus] = React.useState<string[]>([]);
  let { id: constructionId } = useParams();

  useEffect(() => {
    console.log(constructionId);
    if (hasFilterResponsibleUser) {
      const fetchResponsibleUsers = async () => {
        const response = await api.get(
          `api/users/responsible-construction/${constructionId}`
        );
        setResponsibleUsers(response.data);
      };
      fetchResponsibleUsers();
    }

    if (hasFilterCriteria) {
      const fetchCriteria = async () => {
        const response = await api.get(`api/criteria`);
        setCriteria(response.data);
      };
      fetchCriteria();
    }
  }, [constructionId, hasFilterCriteria, hasFilterResponsibleUser]);

  const handleSubmit = async () =>
    // event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    {
      // event.preventDefault();

      const dtBeginApi = dtBegin ? dtBegin.toLocaleDateString("fr-CA") : "";
      const dtEndApi = dtEnd ? dtEnd.toLocaleDateString("fr-CA") : "";
      callReport(
        dtBeginApi,
        dtEndApi,
        filterDateType,
        filterCriteria,
        filterResponsibleUser,
        filterStatus
      );
    };

  return (
    <form noValidate>
      <Paper>
        <Box display="flex" gap="24px" mb="28px">
          {/* <Grid item xs={12} sm={2} mb={4}> */}
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="pt-br"
          >
            <DatePicker
              label={!oneDate ? "Data início" : "Data"}
              value={dtBegin}
              onChange={(newValue) => {
                setDtBegin(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  placeholder="dd/mm/aaaa"
                  variant="filled"
                  fullWidth
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          {/* </Grid> */}

          {!oneDate && (
            // <Grid item xs={12} sm={2} mb={4}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              <DatePicker
                label="Data fim"
                value={dtEnd}
                onChange={(newValue) => {
                  setDtEnd(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    placeholder="dd/mm/aaaa"
                    variant="filled"
                    fullWidth
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
            // </Grid>
          )}

          {hasFilterCriteria && (
            // <Grid item xs={12} sm={2} mb={4}>
            <FormControl fullWidth>
              <InputLabel htmlFor="role">Critério</InputLabel>
              <Select
                fullWidth
                labelId="appropriation-choosed"
                id="appropriation-choosed"
                value={filterCriteria}
                input={
                  <FilledInput
                    // label="Tag"
                    endAdornment={
                      filterCriteria.length ? (
                        <IconButton
                          onClick={() => setFilterCriteria([])}
                          sx={{ marginRight: "10px" }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ) : null
                    }
                  />
                }
                onChange={(event: SelectChangeEvent<typeof filterCriteria>) => {
                  const {
                    target: { value },
                  } = event;
                  setFilterCriteria(
                    // On autofill we get a stringified value.
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                renderValue={(selected) =>
                  criteria
                    .filter((c) => selected.indexOf(c.id.toString()) > -1)
                    .map((c) => c.name)
                    .join(", ")
                }
                label="Critério"
                multiple
              >
                {criteria.map((criteria) => {
                  return (
                    <MenuItem key={criteria.id} value={criteria.id}>
                      <Checkbox
                        checked={
                          filterCriteria.indexOf(criteria.id.toString()) > -1
                        }
                      />
                      {criteria.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            // </Grid>
          )}

          {hasFilterResponsibleUser && (
            // <Grid item xs={12} sm={2} mb={4}>
            <FormControl fullWidth>
              <InputLabel htmlFor="role">Responsável</InputLabel>
              <Select
                fullWidth
                labelId="user-choosed"
                id="user-choosed"
                value={filterResponsibleUser}
                input={
                  <FilledInput
                    // label="Tag"
                    endAdornment={
                      filterResponsibleUser.length > 0 ? (
                        <IconButton
                          onClick={() => setFilterResponsibleUser([])}
                          sx={{ marginRight: "10px" }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ) : null
                    }
                  />
                }
                onChange={(
                  event: SelectChangeEvent<typeof filterResponsibleUser>
                ) => {
                  const {
                    target: { value },
                  } = event;
                  setFilterResponsibleUser(
                    // On autofill we get a stringified value.
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                renderValue={(selected) =>
                  responsibleUsers
                    .filter((c) => selected.indexOf(c.id.toString()) > -1)
                    .map((c) => c.name)
                    .join(", ")
                }
                label="Responsável"
                multiple
              >
                {responsibleUsers.map((user) => {
                  return (
                    <MenuItem key={user.id} value={user.id}>
                      <Checkbox
                        checked={
                          filterResponsibleUser.indexOf(user.id.toString()) > -1
                        }
                      />
                      {user.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            // </Grid>
          )}

          {hasFilterStatus && (
            // <Grid item xs={12} sm={2} mb={4}>
            <FormControl fullWidth>
              <InputLabel htmlFor="role">Status</InputLabel>
              <Select
                fullWidth
                labelId="activity-status-choosed"
                id="activity-status-choosed"
                value={filterStatus}
                input={
                  <FilledInput
                    // label="Tag"
                    endAdornment={
                      filterStatus.length > 0 ? (
                        <IconButton
                          onClick={() => setFilterStatus([])}
                          sx={{ marginRight: "10px" }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ) : null
                    }
                  />
                }
                onChange={(event: SelectChangeEvent<typeof filterStatus>) => {
                  const {
                    target: { value },
                  } = event;
                  setFilterStatus(
                    // On autofill we get a stringified value.
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                renderValue={(selected) => {
                  let response = [];
                  if (selected.indexOf("1") > -1) {
                    response.push("A fazer");
                  }
                  if (selected.indexOf("2") > -1) {
                    response.push("Em andamento");
                  }
                  if (selected.indexOf("3") > -1) {
                    response.push("Concluídas");
                  }
                  if (selected.indexOf("4") > -1) {
                    response.push("Atrasadas");
                  }
                  if (selected.indexOf("5") > -1) {
                    response.push("Programadas");
                  }
                  return response.join(", ");
                }}
                label="Status"
                multiple
              >
                {!dtBegin && !dtEnd && (
                  <MenuItem key="1" value="1">
                    <Checkbox checked={filterStatus.indexOf("1") > -1} />
                    <em>A fazer</em>
                  </MenuItem>
                )}
                <MenuItem key="2" value="2">
                  <Checkbox checked={filterStatus.indexOf("2") > -1} />
                  <em>Em andamento</em>
                </MenuItem>
                <MenuItem key="3" value="3">
                  <Checkbox checked={filterStatus.indexOf("3") > -1} />
                  <em>Concluídas</em>
                </MenuItem>
                <MenuItem key="4" value="4">
                  <Checkbox checked={filterStatus.indexOf("4") > -1} />
                  <em>Atrasadas</em>
                </MenuItem>
                {dtBegin && dtEnd && (
                  <MenuItem key="5" value="5">
                    <Checkbox checked={filterStatus.indexOf("5") > -1} />
                    <em>Programadas</em>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            // </Grid>
          )}
        </Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          gap="24px"
          mb="20px"
        >
          <Typography variant="h6">
            Por favor, selecione as ações que deseja concluir na tabela abaixo:
          </Typography>
          {actionsByCriteria.length > 0 && (
            // <Button
            //   variant="contained"
            //   size="small"
            //   color="secondary"
            //   endIcon={<>}
            //   onClick={() => setShowPdf(!showPdf)}
            // >
            //   {showPdf ? "Fechar" : "Abrir"}
            // </Button>
            <ButtonComponent
              text="Exportar PDF"
              state={ButtonState.DEFAULT_ENABLED}
              type={ButtonType.OUTLINE}
              startIcon={<FileUploadOutlined />}
              onClick={() => setShowPdf(!showPdf)}
            />
          )}
          <ButtonComponent
            // type="submit"
            // variant="contained"
            text="Consultar"
            state={ButtonState.DEFAULT_ENABLED}
            type={ButtonType.TERTIARY}
            startIcon={<SearchIcon />}
            onClick={() => handleSubmit()}
          />
          {/* <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<SearchIcon />}
              onClick={(event) => handleSubmit(event)}
            >
              Consultar
            </Button> */}
        </Box>
      </Paper>
    </form>
  );
};

export default SearchBar;
