import React from "react";
import styled from "styled-components";
import TodayIcon from "@mui/icons-material/Today";
import { Colors } from "../../../theme/v2/variants";

interface LabeledItemCardProps {
  title: string;
  text: string | number;
  startIcon?: React.ReactNode;
  color?: string;
  fontColor?: string;
  fontSize?: string;
  fontWeight?: string | number;
}

const CardContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div<{ color?: string }>`
  margin-right: 8px;
  color: ${(props) => props.color || Colors.greenLight};
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const Title = styled.div<{ color?: string }>`
  font-family: "Noto Sans";
  font-size: 12px;
  color: ${(props) => props.color || Colors.greenLight};
  font-weight: 500;
  align-self: flex-start;
`;

const Text = styled.div<{
  fontColor?: string;
  fontSize?: string;
  fontWeight: string | number;
}>`
  color: ${(props) => props.fontColor || Colors.primaryColorDark};
  font-family: "Noto Sans";
  font-size: ${(props) => props.fontSize || "16px"};
  font-style: normal;
  font-weight: ${(props) => props.fontWeight};
  line-height: normal;
  white-space: nowrap;
`;

const LabeledItemCard: React.FC<LabeledItemCardProps> = ({
  title,
  text,
  startIcon,
  color,
  fontColor,
  fontSize,
  fontWeight = 500,
}) => {
  return (
    <CardContainer>
      {startIcon ? <IconWrapper color={color}>{startIcon}</IconWrapper> : null}
      <TextContainer>
        <Title color={color}>{title}</Title>
        <Text fontColor={fontColor} fontSize={fontSize} fontWeight={fontWeight}>
          {text}
        </Text>
      </TextContainer>
    </CardContainer>
  );
};

export default LabeledItemCard;
