import React from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BaseComponent from "../../../shared/base/BeaseComponent";
import TextField from "@mui/material/TextField";
import "dayjs/locale/pt-br";
import { textFieldStyle } from "../../../utils/custom-styles/textFieldCustomStyles";
import { textFieldDarkStyle } from "../../../utils/custom-styles/textFieldCustomDarkStyles";
import dayjs from "dayjs";

dayjs.locale("pt-br");
interface InputDateComponentProps {
  value: Date | undefined;
  label: string;
  format: string;
  variant?: string;
  enable?: boolean;
  onChange: (value: Date | null) => void;
  isGray?: boolean;
}

interface InputDateComponentRef {}

const InputDateComponent = React.forwardRef<
  InputDateComponentRef | undefined,
  InputDateComponentProps
>(({ value, label, format, onChange, enable, variant, isGray }, ref) => {
  return (
    <BaseComponent>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          onChange={(newValue) => onChange(newValue ?? null)}
          inputFormat={format || "DD/MM/YYYY"}
          label={label}
          renderInput={(params) => (
            <TextField
              placeholder="dd/mm/aaaa"
              size={variant ? "medium" : "small"}
              variant={variant ? "filled" : "outlined"}
              sx={variant ? (isGray ? textFieldDarkStyle : textFieldStyle) : {}}
              fullWidth
              {...params}
            />
          )}
          disabled={!enable}
          dayOfWeekFormatter={(weekday) => {
            switch (weekday) {
              case "Do":
                return "D";
              case "2ª":
                return "S";
              case "3ª":
                return "T";
              case "4ª":
                return "Q";
              case "5ª":
                return "Q";
              case "6ª":
                return "S";
              case "Sá":
                return "S";
              default:
                return " ";
            }
          }}
          // sx={{ width: "100%" }}
          // slotProps={{ textField: { variant: "standard" } }}
          // format={format}
        />
      </LocalizationProvider>
    </BaseComponent>
  );
});

export default InputDateComponent;
