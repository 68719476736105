import React from "react";
import styled from "styled-components";
import { Colors } from "../../../../theme/v2/variants";

import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";

import Badge from "./Badge";

// export enum StatusEnum {
//   APROVADO = "APROVADO",
//   REPROVADO = "REPROVADO",
//   PENDENTE = "PENDENTE",
//   EM_ANDAMENTO = "EM ANDAMENTO",
//   ENVIADO = "ENVIADO",
//   OVERDUE = "OVERDUE",
// }

// export enum ContractingPlanStatusENUM {
//   IN_PROGRESS = "IN_PROGRESS",
//   NOT_STARTED = "NOT_STARTED",
//   OVERDUE = "OVERDUE",
//   INCOMPLETE = "INCOMPLETE",
//   TOTALLY_COMPLETED = "TOTALLY_COMPLETED",
// }

export enum StatusEnum {
  NOT_STARTED = "NOT_STARTED",
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  REJECTED = "REJECTED",
  COMPLETED = "COMPLETED",
  OVERDUE = "OVERDUE",
}

interface Step {
  title: string;
  description?: string;
  // icon: React.ReactNode;
  active: boolean;
  completed: boolean;
  status?: StatusEnum;
  Date?: string;
  UserName?: string;
  action?: string;
  UserImage?: string;
}

interface TimelineProps {
  steps: Step[];
}

export const TimelineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-top: 21px;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const IconWrapper = styled.div<{ status?: StatusEnum }>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${(props) =>
      props.status === StatusEnum.PENDING
        ? 'transparent'
      : props.status === StatusEnum.IN_PROGRESS
      ? Colors.lightBlue
      : props.status === StatusEnum.OVERDUE
      ? Colors.attentionColor
      : props.status === StatusEnum.COMPLETED
      ? Colors.greenLight
      : props.status === StatusEnum.REJECTED
      ? Colors.alertRed
      : props.status === StatusEnum.NOT_STARTED
      ? Colors.silver
      : props.status === StatusEnum.PENDING
      ? Colors.lightBlue
      : ""};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  color: ${(props) =>
      props.status === StatusEnum.PENDING
        ? Colors.greenLight
      : props.status === StatusEnum.NOT_STARTED
        ? Colors.gray
      : "white"};
  ${(props) =>
    props.status === StatusEnum.PENDING
      ? `border: 2px solid ${Colors.greenLight}`
    : ""};
`;

export const Connector = styled.div<{ status?: StatusEnum }>`
  height: 4px;
  background-color: ${(props) =>
    props.status === StatusEnum.PENDING
      ? Colors.silver
    : props.status === StatusEnum.IN_PROGRESS
      ? Colors.lightBlue
    : props.status === StatusEnum.OVERDUE
      ? Colors.attentionColor:
    props.status === StatusEnum.COMPLETED
      ? Colors.greenLight
    : props.status === StatusEnum.REJECTED
      ? Colors.alertRed
    : props.status === StatusEnum.NOT_STARTED
      ? Colors.silver
    : Colors.lightBlue};
  flex-grow: 1;
  margin: 0 4px;
  border-radius: 6px;
`;

const Timeline: React.FC<TimelineProps> = ({ steps }) => {
  let isRejected = false;

  return (
    <>
      <TimelineContainer>
        {steps.map((step, index) => {
          if (step.status === StatusEnum.REJECTED) {
            isRejected = true;
          }

          return (
            <React.Fragment key={index}>
              <StepContainer>
                <IconWrapper
                  status={step.status}
                >
                  {isRejected || step.status === StatusEnum.REJECTED ? (
                    <CloseIcon />
                  ) : step.status === StatusEnum.COMPLETED ? (
                    <CheckIcon />
                  ) : step.status === StatusEnum.NOT_STARTED ? (
                    <PendingActionsIcon />
                  ) : step.status === StatusEnum.PENDING ? (
                    <PendingActionsIcon />
                  ) : step.status === StatusEnum.IN_PROGRESS ? (
                    <SendOutlinedIcon />
                  ) : step.status === StatusEnum.OVERDUE ? (
                    <PendingActionsIcon />
                  ): null}
                </IconWrapper>
              </StepContainer>
              {index < steps.length - 1 && (
                <Connector
                  status={isRejected ? StatusEnum.REJECTED : step.status}
                />
              )}
            </React.Fragment>
          );
        })}
      </TimelineContainer>
      <Badge steps={steps} />
    </>
  );
};

export default Timeline;
