import React, { useEffect, useState } from "react";
import {
  AboutContainer,
  ConstructionImage,
  ConstructionImagePlaceholder,
  ConstructionNameTitle,
  HeaderContainer,
  PageTitle,
  TitleContainer,
} from "./styles";
import { api } from "../../services/api";
import { useDashboard } from "../../contexts/dashboard";
import { useNavigate } from "react-router-dom";

const SubHeader = () => {
  const { constructionId, constructions, setConstructionId } = useDashboard();
  const [constructionImage, setConstructionImage] = useState<string>("");
  const [pageTitle, setPageTitle] = useState("");
  const navigate = useNavigate();
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedConstruction, setSelectedConstruction] = useState("");

  useEffect(() => {
    getConstructionImage(constructionId);
  }, [constructionId]);

  useEffect(() => {
    if (constructionId) {
      const constructionName = constructions.find(
        (construction) => construction.id === constructionId
      );
      setSelectedConstruction(constructionName?.name || "");
    }
  }, [constructionId]);

  const getConstructionImage = async (constructionId: number) => {
    try {
      const result = await api.get(
        `/api/constructions/image/${constructionId}`
      );
      setConstructionImage(result.data);
    } catch (error) {
      console.log(error);
      setConstructionImage("");
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes("/planos-acoes/editar")) {
      setPageTitle("Plano de Ação");
    } else if (path.includes("/mapeamento-restricoes")) {
      setPageTitle("Mapear Restrições");
    } else if (path.includes("/acompanhar-acoes")) {
      setPageTitle("Acompanhar Ações");
    } else if (path.includes("/plano-compras-contratacoes")) {
      setPageTitle("Compras e Contratações");
    } else if (path.includes("/listar")) {
      setPageTitle("Listagem");
    } else if (path.includes("/cadastrar")) {
      setPageTitle("Cadastro");
    } else {
      setPageTitle("Página padrão");
    }
  });

  function getConstructionInitialLetters(construction: string): string {
    const array = construction.split(" ");
    let firstLetter = array[0]?.charAt(0);
    let lastLetter = array.length > 1 ? array[array.length - 1]?.charAt(0) : "";

    return `${firstLetter}${lastLetter}`;
  }

  if (!selectedConstruction) {
    return null;
  }

  return (
    <HeaderContainer>
      <AboutContainer>
        {/* ALTERAR APÓS MERGE DO CONTROLLER DE IMAGENS */}
        {constructionImage ? (
          <ConstructionImage src={constructionImage} />
        ) : (
          <ConstructionImagePlaceholder>
            {getConstructionInitialLetters(selectedConstruction)}
          </ConstructionImagePlaceholder>
        )}
        <TitleContainer>
          <PageTitle>{pageTitle}</PageTitle>
          {/* <PageTitle>
            {window.location.pathname}
          </PageTitle> */}
          <ConstructionNameTitle>{selectedConstruction}</ConstructionNameTitle>
        </TitleContainer>
      </AboutContainer>
    </HeaderContainer>
  );
};

export default SubHeader;
