export {};

export const appVersion = "1.2.4";
export const VERTICAL_SERVICE_PATH = "/vertical-servicos";
export const ORDER_OF_STEPS_PATH = VERTICAL_SERVICE_PATH + "/ordem-etapas";
export const PURCHASING_CONTRACTING_PLAN_PATH =
  "/plano-compras-contratacoes";
export const PURCHASING_CONTRACTING_PLAN_FORM_PATH =
  PURCHASING_CONTRACTING_PLAN_PATH + "/form";
export const PURCHASING_CONTRACTING_PLAN_ORDER_DETAIL_PATH =
  PURCHASING_CONTRACTING_PLAN_PATH + "/detalhes-pedido";

export const userRolesEnum = {
  admin_eap: {
    id: 0,
    name: "Administrador (EAP)"
  },
  executive_advisory_eap: {
    id: 1,
    name: "Assessoria Executiva (EAP)"
  },
  executive_coordination_eap: {
    id: 2,
    name: "Coordenação Executiva (EAP)"
  },
  board_client: {
    id: 3,
    name: "Diretoria (Cliente)"
  },
  construction_manager_client: {
    id: 4,
    name: "Gestor de Obra (Cliente)"
  },
  team_client: {
    id: 5,
    name: "Equipe (Cliente)"
  },
  gobuilder_client: {
    id: 6,
    name: "Cliente GoBuilder"
  },
}

export const apTeamOrClientManagerRoleNames = [
  "admin_eap",
  "executive_advisory_eap",
  "executive_coordination_eap",
  "construction_manager_client"
]

export const eapTeamOrClientManagerRoles =
  Object.fromEntries(Object.entries(userRolesEnum)
    .filter(([key]) => apTeamOrClientManagerRoleNames.includes(key)));

export const userRoles = [
  "Administrador (EAP)",
  "Assessoria Executiva (EAP)",
  "Coordenação Executiva (EAP)",
  "Diretoria (Cliente)",
  "Gestor de Obra (Cliente)",
  "Equipe (Cliente)",
  "Cliente GoBuilder",
];

export const userTypes = {
  hired: 1,
  contractor: 2,
};

// Administrador (EAP)
const authorizedRoutesRole0 = [
  "/dashboard/",
  "/obras/cadastrar/",
  "/obras/editar/",
  "/obras/listar/",
  "/empresas/cadastrar/",
  "/empresas/editar/",
  "/empresas/listar/",
  "/restricoes/cadastrar/",
  "/restricoes/editar/",
  "/restricoes/listar/",
  "/mapeamento-restricoes/cadastrar/",
  "/mapeamento-restricoes/editar/",
  "/mapeamento-restricoes/listar/",
  "/planejamento-acoes/editar/",
  "/planejamento-acoes/listar/",
  "/planos-acoes/editar/",
  "/planos-acoes/listar/",
  "/planos-acoes/gantt/",
  "/usuarios/editar/",
  "/usuarios/cadastrar/",
  "/usuarios/listar/",
  "/sair/",
  "/acompanhar-acoes",
  "/vertical-servicos",
  "/vertical-servicos-relatorio",
  ORDER_OF_STEPS_PATH,
  PURCHASING_CONTRACTING_PLAN_PATH,
];

// Assessoria Executiva (EAP)
const authorizedRoutesRole1 = [
  "/dashboard/",
  "/obras/cadastrar/",
  "/obras/editar/",
  "/obras/listar/",
  "/empresas/listar/",
  "/empresas/editar/",
  "/restricoes/listar/",
  "/restricoes/cadastrar/",
  "/restricoes/editar/",
  "/mapeamento-restricoes/cadastrar/",
  "/mapeamento-restricoes/editar/",
  "/mapeamento-restricoes/listar/",
  "/planejamento-acoes/editar/",
  "/planejamento-acoes/listar/",
  "/planos-acoes/editar/",
  "/planos-acoes/listar/",
  "/planos-acoes/gantt/",
  "/usuarios/editar/",
  "/usuarios/cadastrar/",
  "/usuarios/listar/",
  "/sair/",
  "/acompanhar-acoes",
  "/vertical-servicos",
  "/vertical-servicos-relatorio",
  ORDER_OF_STEPS_PATH,
  PURCHASING_CONTRACTING_PLAN_PATH,
];

// Coordenação Executiva (EAP)
const authorizedRoutesRole2 = [
  "/dashboard/",
  "/empresas/cadastrar/",
  "/empresas/editar/",
  "/empresas/listar/",
  "/restricoes/editar/",
  "/mapeamento-restricoes/cadastrar/",
  "/mapeamento-restricoes/editar/",
  "/mapeamento-restricoes/listar/",
  "/planejamento-acoes/editar/",
  "/planejamento-acoes/listar/",
  "/planos-acoes/editar/",
  "/planos-acoes/listar/",
  "/planos-acoes/gantt/",
  "/usuarios/editar/",
  "/sair/",
  "/acompanhar-acoes",
  "/vertical-servicos",
  "/vertical-servicos-relatorio",
  ORDER_OF_STEPS_PATH,
  PURCHASING_CONTRACTING_PLAN_PATH,
];

// Diretoria (Cliente)
const authorizedRoutesRole3 = [
  "/dashboard/",
  "/obras/editar/",
  "/obras/listar/",
  "/planos-acoes/listar/",
  "/planos-acoes/gantt/",
  "/usuarios/editar/",
  "/sair/",
  "/acompanhar-acoes",
  "/vertical-servicos",
  "/vertical-servicos-relatorio",
  "/mapeamento-restricoes/cadastrar/",
  "/mapeamento-restricoes/editar/",
  "/mapeamento-restricoes/listar/",
  ORDER_OF_STEPS_PATH,
  PURCHASING_CONTRACTING_PLAN_PATH,
];

// Gestor de Obra (Cliente)
const authorizedRoutesRole4 = [
  "/dashboard/",
  "/obras/editar/",
  "/obras/listar/",
  "/mapeamento-restricoes/cadastrar/",
  "/mapeamento-restricoes/editar/",
  "/mapeamento-restricoes/listar/",
  "/planos-acoes/editar/",
  "/planos-acoes/listar/",
  "/planos-acoes/gantt/",
  "/usuarios/editar/",
  "/sair/",
  "/acompanhar-acoes",
  "/vertical-servicos",
  "/vertical-servicos-relatorio",
  ORDER_OF_STEPS_PATH,
  PURCHASING_CONTRACTING_PLAN_PATH,
];

// Equipe (Cliente)
const authorizedRoutesRole5 = [
  "/dashboard/",
  "/obras/editar/",
  "/obras/listar/",
  "/planos-acoes/listar/",
  "/planos-acoes/gantt/",
  "/usuarios/editar/",
  "/sair/",
  "/acompanhar-acoes",
  "/vertical-servicos",
  "/vertical-servicos-relatorio",
  "/mapeamento-restricoes/listar/",
  ORDER_OF_STEPS_PATH,
  PURCHASING_CONTRACTING_PLAN_PATH,
];

// Cliente GoBuilder
const authorizedRoutesRole6 = [
  "/dashboard/",
  "/obras/listar/",
  "/obras/editar/",
  "/mapeamento-restricoes/cadastrar/",
  "/mapeamento-restricoes/editar/",
  "/mapeamento-restricoes/listar/",
  "/planejamento-acoes/editar/",
  "/planejamento-acoes/listar/",
  "/planos-acoes/editar/",
  "/planos-acoes/listar/",
  "/planos-acoes/gantt/",
  "/usuarios/editar/",
  "/sair/",
  "/acompanhar-acoes",
  "/vertical-servicos",
  "/vertical-servicos-relatorio",
  ORDER_OF_STEPS_PATH,
  PURCHASING_CONTRACTING_PLAN_PATH,
];

export const isUserClientRole = (role: number) => {
  return role > 3 && role <= 6;
};

export const isClientTeam = (role: number) => {
  return role === 5;
};

export const isEapTeamORClientManager = (role: number) => {
  const eapTeamOrClientManagerRolesIds: number[] =
    Object.entries(eapTeamOrClientManagerRoles).map(([_, item]) => item.id)

  return eapTeamOrClientManagerRolesIds.includes(role)
};

export const authorizedRoutes = [
  authorizedRoutesRole0,
  authorizedRoutesRole1,
  authorizedRoutesRole2,
  authorizedRoutesRole3,
  authorizedRoutesRole4,
  authorizedRoutesRole5,
  authorizedRoutesRole6,
];

export type AuthContextData = {
  signed: boolean;
  user: User;
  loading: boolean;
  message: string;
  signIn(login: string, password: string): Promise<void>;
  signOut(): void;
  avatarSidebar: string;
  setAvatarSidebar(avatar: string): void;
};

export type Construction = {
  id: number;
  code?: string;
  name: string;
  dtBegin: Date;
  dtEnd: Date;
  status: boolean;
  finished: boolean;
  address: string;
  number: string;
  cep: string;
  neighborhood: string;
  complement: string;
  city: string;
  uf: string;
  interventionDescription: string;
  additionalInformation: string;
  scope: string;
  budget: string;
  userId: number;
  managerUserId: number;
  executiveAdvisorUserId: number;
  clientId: number;
  latitude: string;
  longitude: string;
};

export type Client = {
  id: number;
  name: string;
  status: boolean;
  email: string;
  cnpj: string;
  phone: string;
  nameResponsible: string;
  address: string;
  number: string;
  cep: string;
  neighborhood: string;
  complement: string;
  comments: string;
  city: string;
  uf: string;
  userId: number;
  constructionExecutedType: number;
  hireType: number;
  employeeQuantity: number;
};

export type User = {
  id: number;
  name: string;
  email: string;
  login: string;
  phone: string;
  password: string;
  password2: string;
  role: number;
  status: boolean;
  avatar: string;
  removeAvatar?: boolean;
  responsibleClientId?: number;
};

export type Restriction = {
  id: number;
  name: string;
  code?: string;
  criteriaId: string;
  status: number;
  createdAt: Date;
};

export type Criteria = {
  id: number;
  name: string;
  status: boolean;
  restrictions: Restriction[];
  mappingItems?: MappingItem[];
};

export type MappingItem = {
  index: number;
  id?: number;
  criteriaId: number;
  restrictionId: number;
  constructionId: number;
  userId: number;
  status: boolean;
  category: number | "";
  priority: number | "";
  impact: number | "";
  comments: string;
  addedByPlanning?: boolean;
  deletedByPlanning?: boolean;
};

export type ActionItem = {
  index: number;
  id?: number;
  criteria?: string;
  what: string;
  how: string;
  whenBegin: string;
  whenEnd: string;
  whenRealBegin?: string;
  whenRealEnd?: string;
  category: number | "";
  priority: number | "";
  impact: number | "";
  status: boolean;
  userId: number;
  restrictionMappingId: number;
  responsibleUsersId: number[];
  addedByPlanning?: boolean;
  deletedByPlanning?: boolean;
};

export type PlanningAction = {
  index: number;
  restrictionMappingId: number;
  criteriaId: number;
  restrictionId: number;
  constructionId: number;
  userId: number;
  status: boolean;
  category: number | "";
  priority: number | "";
  impact: number | "";
  actionItems: ActionItem[];
  addedByPlanning: boolean;
  comments: string;
};

/*export type PlanningActionItem = {
  id: number;
  what: string;
  how: string;
  whenBegin: string;
  whenEnd: string;
  whenRealBegin: string;
  whenRealEnd: string;
  category: number | "";
  priority: number | "";
  impact: number | "";
  status: boolean;
  userId: number;
  addedByPlanning: boolean;
  responsibleUsersIds: number[];
  criteriaId: number;
  constructionId: number;
  restrictionId: number;
  restrictionMappingId: number;
  restrictionCategory: number | "";
  restrictionPriority: number | "";
  restrictionImpact: number | "";
  restrictionComments: string;
};*/

export type MappingContextData = {
  mappingItems: MappingItem[];
  setMappingItems: (mappingItems: MappingItem[]) => void;
};

export type DataGantt = {
  criteriaName: string;
  restrictionName: string;
  actionName: string;
  dtBegin: Date;
  dtEnd: Date;
  responsibleUsers: string;
};

export const restrictionCategories = [
  "PRAZO",
  "CUSTO",
  "QUALIDADE",
  "SEGURANÇA",
];

export const restrictionPriorities = [
  "Muito Baixa",
  "Baixa",
  "Média",
  "Alta",
  "Muito Alta",
];

export const restrictionImpacts = [
  "Muito Baixo",
  "Baixo",
  "Médio",
  "Alto",
  //"Muito Alto",
];

export const statesOfBrazil = [
  "AC",
  "AL",
  "AM",
  "AP",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RO",
  "RS",
  "RR",
  "SC",
  "SE",
  "SP",
  "TO",
];

export const constructionExecutedTypes = [
  "Residencial de Médio / Alto Padrão",
  "Residêncial de Baixo Padrão",
  "Comerciais",
  "Condomínios / Loteamentos",
  "Edificações Verticais (até 5 pavimentos)",
  "Edificações Verticais (acima de 5 pavimentos)",
  "Industriais",
  "Infraestrutura",
  "Obras públicas",
  "Reformas",
];

export const hireTypes = [
  "Administração",
  "Construção",
  "Gerenciamento",
  "Incorporação",
  "Serviços Especializados",
  "Manutenção",
];

export const employeeQuantities = [
  "1 a 5 funcionários",
  "6 a 10 funcionários",
  "11 a 50 funcionários",
  "51 a 100 funcionários",
  "Mais de 100 funcionários",
];

export type DashboardContextData = {
  constructionId: number;
  selectedConstruction: Construction | undefined;
  clientId: number;
  dtBegin: Date | null;
  dtEnd: Date | null;
  isDashboard: boolean;
  constructions: Construction[];
  clients: Client[];
  setConstructionId: (constructionId: number) => void;
  setClientId: (clientId: number) => void;
  setDtBegin: (date: Date | null) => void;
  setDtEnd: (date: Date | null) => void;
  setIsDashboard: (flag: boolean) => void;
  setConstructions: (construction: Construction[]) => void;
  setClients: (client: Client[]) => void;
};

export type ActionContextData = {
  actions: ActionItem[];
  setActions: (actions: ActionItem[]) => void;
};

export const chartColors = [
  "#14224F",
  "#6C6E69",
  "#FFBD59",
  "#ADD8E6",
  "#4F4F4F",
  "#00FA9A",
  "#7CFC00",
  "#A0522D",
  "#114BD7",
];
