import styled from "styled-components";
import { Colors } from "../../../theme/v2/variants";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";

const Title = styled.div`
  max-width: 100%;
  color: ${Colors.primaryColorDark};
  font-size: 32px;
  font-family: "Noto Sans";
  font-weight: 700;
  word-wrap: break-word;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 16px;
    margin-left: 16px;
  }
`;

export const TitleWithMobileBigNumber = styled.div`
  max-width: 100%;
  color: ${Colors.primaryColorDark};
  font-size: 32px;
  font-family: "Noto Sans";
  font-weight: 700;
  word-wrap: break-word;
  margin-left: 32px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 24px;
    margin-left: 0px;
  }
`;

export default Title;
